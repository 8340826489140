import {
  getItem,
  setItem,
  deleteItem,
} from "../../state_management/localStorage"

const TOKEN = "token"

function getAuthToken() {
  return getItem(TOKEN)
}

function setAuthToken(token) {
  setItem(TOKEN, token)
}

function deleteAuthToken() {
  deleteItem(TOKEN)
}

export { getAuthToken, setAuthToken, deleteAuthToken }
