import React from "react"
import PropTypes from "prop-types"

import { ExportToCsv, Toolbar } from "../../../ui"

const ExportToolbarButtons = ({ handleExportToCsv }) => (
  <Toolbar.Buttons leftDivider>
    <ExportToCsv onClick={handleExportToCsv} />
  </Toolbar.Buttons>
)
ExportToolbarButtons.propTypes = {
  handleExportToCsv: PropTypes.func,
}
export default ExportToolbarButtons
