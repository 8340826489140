import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { ResourceEditPage, withResourceId } from "../../../resourcePage"

import {
  getLanguageItem,
  getResourceName,
  LANGUAGE_ITEM_QUERY,
  UPDATE_LANGUAGE_MUTATION,
  updateItemInCache,
} from "../dataSource"
import { canUpdate } from "../accessRights"
import { pluralName, singleName } from "../metaInfo"
import { languagePath, languagesPath } from "../paths"
import Language from "./Language"

function LanguageEditPage({ auth: { userRole }, id }) {
  return (
    <Query
      query={LANGUAGE_ITEM_QUERY}
      variables={{ id }}
      skip={!canUpdate(userRole)}
    >
      {({ loading, errors, data }) => {
        const language = getLanguageItem(data)

        if (!canUpdate(userRole)) {
          errors = ["Access denied."]
        } else if (!loading && !errors && !language) {
          errors = ["Not found."]
        }

        return (
          <ResourceEditPage
            errors={errors}
            getResourceName={getResourceName}
            id={id}
            loading={loading}
            pluralName={pluralName}
            resourceComponent={Language}
            resources={{ item: language }}
            resourcePath={languagePath}
            resourcesPath={languagesPath}
            singleName={singleName}
            updateMutation={UPDATE_LANGUAGE_MUTATION}
            updateItemInCache={updateItemInCache({})}
          />
        )
      }}
    </Query>
  )
}
LanguageEditPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
}
export default compose(
  withResourceId,
  withAuthenticationContext,
)(LanguageEditPage)
