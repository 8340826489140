import React from "react"

import { FlashProvider } from "../ui"

import { AuthenticationProvider } from "../state_management/Authentication"
import DataProvider, { gql } from "../state_management/DataProvider"
import { Router, Redirector } from "../state_management/Router"

import { getAuthToken, deleteAuthToken } from "./auth"
import { Header, Content } from "./layout"
import { MetaDataProvider } from "./metaData"

import cacheRedirects from "./domains/cacheRedirects"

import "./App.css"

import { FocusStyleManager } from "@blueprintjs/core"
FocusStyleManager.onlyShowFocusOnTabs()

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      role
      name
    }
  }
`

class App extends React.Component {
  render() {
    return (
      <FlashProvider>
        <DataProvider
          getAuthToken={getAuthToken}
          deleteAuthToken={deleteAuthToken}
          cacheRedirects={cacheRedirects}
        >
          <AuthenticationProvider getCurrentUserQuery={GET_CURRENT_USER}>
            <MetaDataProvider>
              <Router>
                <Redirector>
                  <div className="App ag-theme-balham">
                    <Header />
                    <Content />
                  </div>
                </Redirector>
              </Router>
            </MetaDataProvider>
          </AuthenticationProvider>
        </DataProvider>
      </FlashProvider>
    )
  }
}

export default App
