import React from "react"
import PropTypes from "prop-types"

import { Mutation, withApollo } from "react-apollo"
import ErrorHandler from "./ErrorHandler"

import { compose } from "../../utils"
import { Flash } from "../../ui"

import translateErrorMessage from "./serverErrorMessages"
import extractErrors from "./extractErrors"

const MutationWithFlash = ({ children, client, onError, ...rest }) => (
  <Flash>
    {({ flash }) => {
      function showError(message) {
        const translatedMessage = translateErrorMessage(message)
        flash.alert({
          message: translatedMessage || message,
          action: !translatedMessage && {
            text: "Reload",
            onClick: client.resetStore,
          },
          timeout: 0,
        })
      }
      function handleError(error) {
        extractErrors(error).forEach(message => showError(message))
        onError && onError(error)
      }
      function mutationWrapper(mutation) {
        return function(...params) {
          flash.clear()
          return mutation(...params)
        }
      }
      return (
        <Mutation onError={handleError} {...rest}>
          {(mutation, result) => children(mutationWrapper(mutation), result)}
        </Mutation>
      )
    }}
  </Flash>
)
MutationWithFlash.propTypes = {
  children: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  onError: PropTypes.func,
}
export default compose(
  ErrorHandler,
  withApollo,
)(MutationWithFlash)
