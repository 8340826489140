import React from "react"

class CallOnMount extends React.Component {
  componentDidMount() {
    this.props.callee()
  }

  render() {
    return this.props.children
  }
}
export default CallOnMount
