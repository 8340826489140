import {
  DATE_TIME,
  DEFAULT_ACTIONS,
  DROP_DOWN,
  ID,
  INTEGER,
  SELECT,
  TEXT,
} from "../DataType"
import {
  DateTimeCell,
  DefaultActionsCell,
  DropDownCell,
  IdCell,
  IntegerCell,
  SelectCell,
  TextCell,
} from "./cells"

const types = {
  [DATE_TIME]: {
    cell: DateTimeCell,
  },
  [DEFAULT_ACTIONS]: {
    cell: DefaultActionsCell,
    field: "defaultActions",
  },
  [DROP_DOWN]: {
    cell: DropDownCell,
  },
  [ID]: {
    cell: IdCell,
  },
  [INTEGER]: {
    cell: IntegerCell,
  },
  [SELECT]: {
    cell: SelectCell,
  },
  [TEXT]: {
    cell: TextCell,
  },
}

export default function(type) {
  return types[type || TEXT]
}
