import {
  resourcePathFor,
  resourcesPathFor,
  newResourcePathFor,
  editResourcePathFor,
} from "../../resourcePage"

export const orderPath = resourcePathFor("orders")
export const ordersPath = (permalink = ":permalink") =>
  resourcesPathFor(`orders/${permalink}`)
export const newOrderPath = (permalink = ":permalink") =>
  newResourcePathFor(`orders/${permalink}`)
export const editOrderPath = editResourcePathFor("orders")
