import React from "react"
import PropTypes from "prop-types"

import { object, ref, string } from "../../../../utils/yup"

import {
  ChangePasswordButton,
  ChangePasswordDialog,
  DisableUserButton,
  EnableUserButton,
  Toolbar,
} from "../../../../ui"

const CHANGE_PASSWORD_VALIDATION_SCHEMA = object().shape({
  currentUserPassword: string().required("Current Password is required."),
  newPassword: string()
    .required("New Password is required.")
    .min(15, "New Password must be at least 15 characters."),
  confirmation: string().sameAs(
    ref("newPassword"),
    "Confirmation is different from New Password",
  ),
})

class UserShowPageActionToolbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showChangePasswordDialog: false }

    this.changePasswordClicked = this.changePasswordClicked.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleChangePasswordDialogClose = this.handleChangePasswordDialogClose.bind(
      this,
    )
  }

  showChangePasswordDialog(value) {
    this.setState({ showChangePasswordDialog: value })
  }

  changePasswordClicked() {
    this.showChangePasswordDialog(true)
  }
  handleChangePasswordDialogClose() {
    !this.props.waiting && this.showChangePasswordDialog(false)
  }
  async handleChangePassword(params) {
    if (await this.props.handleActions.handleChangePassword(params)) {
      this.showChangePasswordDialog(false)
    }
  }

  render() {
    const {
      handleActions: { canEnableOrDisable, handleDisable, handleEnable },
      resource: { active },
      waiting,
    } = this.props
    const { showChangePasswordDialog } = this.state
    return (
      <Toolbar.Buttons leftDivider>
        {active &&
          canEnableOrDisable && (
            <DisableUserButton onClick={handleDisable} disabled={waiting} />
          )}
        {!active &&
          canEnableOrDisable && (
            <EnableUserButton onClick={handleEnable} disabled={waiting} />
          )}
        <ChangePasswordButton
          onClick={this.changePasswordClicked}
          disabled={waiting}
        />
        <ChangePasswordDialog
          show={showChangePasswordDialog}
          onClose={this.handleChangePasswordDialogClose}
          changePassword={this.handleChangePassword}
          validationSchema={CHANGE_PASSWORD_VALIDATION_SCHEMA}
          waiting={waiting}
        />
      </Toolbar.Buttons>
    )
  }
}
UserShowPageActionToolbar.propTypes = {
  handleActions: PropTypes.shape({
    canEnableOrDisable: PropTypes.bool.isRequired,
    handleChangePassword: PropTypes.func.isRequired,
    handleDisable: PropTypes.func.isRequired,
    handleEnable: PropTypes.func.isRequired,
  }).isRequired,
  resource: PropTypes.shape({
    active: PropTypes.bool,
  }),
  waiting: PropTypes.bool,
}
export default UserShowPageActionToolbar
