import React from "react"
import PropTypes from "prop-types"

import { LoginForm } from "../../ui"

import { Mutation, gql } from "../../state_management/DataProvider"

import { setAuthToken } from "./token"

const authenticateMutation = gql`
  mutation Authenticate($email: String!, $password: String!) {
    authenticate(input: { email: $email, password: $password }) {
      jwtToken
    }
  }
`

function Login({ onSuccess }) {
  function authenticated(data) {
    if (data && data.authenticate && data.authenticate.jwtToken) {
      setAuthToken(data.authenticate.jwtToken)
      onSuccess()
    }
  }

  return (
    <Mutation mutation={authenticateMutation} onCompleted={authenticated}>
      {(authenticate, { loading, called, data }) => {
        function handleLogin({ email, password }) {
          return authenticate({ variables: { email, password } })
        }

        return (
          <LoginForm
            waiting={loading}
            failed={
              called &&
              !loading &&
              !(data && data.authenticate && data.authenticate.jwtToken)
            }
            login={handleLogin}
          />
        )
      }}
    </Mutation>
  )
}

Login.propTypes = {
  onSuccess: PropTypes.func,
}
export default Login
