import React from "react"
import PropTypes from "prop-types"

import { compose, find, formatDateISO, parseDateISO } from "../../../../utils"
import { Column, DataType } from "../../../../ui"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"
import { getItem, setItem } from "../../../../state_management/localStorage"

import { withMetaData } from "../../../metaData"

import { ResourceIndexPage } from "../../../resourcePage"

import withPermalink from "../../../routing/withPermalink"

import {
  AGGREGATED_STOCK_ITEM_LIST_QUERY,
  getAggregatedStockItemList,
  STOCK_ITEM_LIST_QUERY,
  getStockItemList,
} from "../dataSource"
import { pluralName } from "../metaInfo"

import NotForPublicSaleColumn from "../../product/pages/NotForPublicSaleColumn"
import { canCreate } from "../../order/accessRights"

import StockIndexToolbar from "./StockIndexToolbar"
import stockOrderMenuItems from "../stockOrderMenuItems"

const aggregateKeyName = "stock:aggregate"
const dateKeyName = "stock:date"

const parseStoredDate = str =>
  str === "current" ? new Date() : parseDateISO(str)
const formatDateForStorage = date => {
  return date.toDateString() === new Date().toDateString()
    ? "current"
    : formatDateISO(date)
}
class StockIndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: parseStoredDate(getItem(dateKeyName)) || new Date(),
      aggregate: getItem(aggregateKeyName) === "true",
    }
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleAggregateChange = this.handleAggregateChange.bind(this)
  }
  handleDateChange({ target: { value } }) {
    const newValue = value || new Date()
    this.setState({ date: newValue })
    setItem(dateKeyName, formatDateForStorage(newValue))
  }
  handleAggregateChange({ target: { value } }) {
    this.setState({ aggregate: value || false })
    setItem(aggregateKeyName, value || "false")
  }

  render() {
    const {
      auth: { userRole },
      metaData: { stocks, orderTypes },
      permalink,
    } = this.props
    const { aggregate, date } = this.state
    const stock = find(stocks, { permalink })
    const { id: stockId, goodsState, location, contractor, name } = stock || {}

    return (
      <Query
        query={
          aggregate ? AGGREGATED_STOCK_ITEM_LIST_QUERY : STOCK_ITEM_LIST_QUERY
        }
        variables={{ stockId, date }}
        fetchPolicy="no-cache"
        skip={!stock}
      >
        {({ loading, errors, data }) => {
          return (
            <ResourceIndexPage
              canCreate={false}
              errors={stock ? errors : ["Not Found"]}
              loading={loading}
              pluralName={pluralName(name)}
              resources={{
                items: aggregate
                  ? getAggregatedStockItemList(data)
                  : getStockItemList(data),
              }}
              toolbar={
                <StockIndexToolbar
                  aggregate={aggregate}
                  canCreate={canCreate(userRole)}
                  date={date}
                  onAggregateChange={this.handleAggregateChange}
                  onDateChange={this.handleDateChange}
                  orderTypes={stockOrderMenuItems({ stockId, orderTypes })}
                />
              }
              uniqueName={`stock-${permalink}`}
            >
              <Column
                field="productTypeName"
                title="Product Type"
                width={150}
                type={DataType.DROP_DOWN}
              />
              <NotForPublicSaleColumn
                field="productNotForPublicSale"
                title="Sale?"
                width={72}
              />
              <Column field="productCode" title="Product Number" width={150} />
              <Column field="productName" title="Product" width={300} />
              <Column
                field="languageName"
                title="Language"
                width={150}
                type={DataType.DROP_DOWN}
              />
              {location && (
                <Column
                  field="locationName"
                  hide={aggregate}
                  title="Location"
                  type={DataType.DROP_DOWN}
                  width={130}
                />
              )}
              {goodsState && (
                <Column
                  field="goodsStateName"
                  title="Goods State"
                  type={DataType.DROP_DOWN}
                  width={130}
                />
              )}
              {contractor && (
                <Column field="contractorName" title={contractor} width={300} />
              )}
              <Column field="quantity" type={DataType.INTEGER} />
            </ResourceIndexPage>
          )
        }}
      </Query>
    )
  }
}
StockIndexPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string,
  }),
  metaData: PropTypes.shape({
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        reason: PropTypes.bool,
      }),
    ),
    stocks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
  permalink: PropTypes.string.isRequired,
}
export default compose(
  withAuthenticationContext,
  withPermalink,
  withMetaData,
)(StockIndexPage)
