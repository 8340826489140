import React from "react"
import PropTypes from "prop-types"

import { IconNames } from "../../ui"

import { withMetaData } from "../metaData"

import { stockPath } from "../domains/stock/paths"

import MenuItems from "./MenuItems"

function StockMenu({ metaDataLoading, metaDataErrors, metaData: { stocks } }) {
  return (
    <MenuItems
      icon={metaDataErrors && IconNames.WARNING_SIGN}
      loading={metaDataLoading}
      items={stocks}
      path={stockPath}
      title="Stock"
    />
  )
}
StockMenu.propTypes = {
  metaDataLoading: PropTypes.bool,
  metaDataErrors: PropTypes.arrayOf(PropTypes.string),
  metaData: PropTypes.shape({
    stocks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        permalink: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
}
export default withMetaData(StockMenu)
