import { ApolloLink } from "apollo-link"

const authMiddleware = ({ getAuthToken }) =>
  new ApolloLink((operation, forward) => {
    const token = getAuthToken()

    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
    }

    return forward(operation)
  })

export default authMiddleware
