import { cacheUpdate, gql } from "../../../state_management/DataProvider"

import { singleName } from "./metaInfo"

import { reject } from "../../../utils"

export const fields = `
      id
      stockId
      stockName
      name
      remark
      createdAt
      createdBy
      updatedAt
      updatedBy
`
const inputParams = "$stockId: BigInt!, $name: String!, $remark: String"
const inputFields = "stockId: $stockId, name: $name, remark: $remark"

export const LOCATION_LIST_QUERY = gql`
  query Locations {
    allLocationsList { ${fields} }
  }
`
export const LOCATION_ITEM_QUERY = gql`
  query Location($id: BigInt!) {
    locationById(id: $id) { ${fields} }
  }
`

export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocation(${inputParams}) {
    createLocation(input: { ${inputFields} }) {
      location { ${fields} }
    }
  }
`
export const UPDATE_LOCATION_MUTATION = gql`
  mutation UpdateLocation($id: BigInt!, ${inputParams}) {
    updateLocation(input: { id: $id, ${inputFields} }) {
      location { ${fields} }
    }
  }
`
export const DELETE_LOCATION_MUTATION = gql`
  mutation DeleteLocation($id: BigInt!) {
    deleteLocation(input: { id: $id }) {
      location {
        id
        name
      }
    }
  }
`

export const getLocationList = data => (data || {}).allLocationsList || []
export const getLocationItem = data => (data || {}).locationById
export const getCreatedLocationItem = data => data.createLocation.location
export const getDeletedLocationId = data => data.deleteLocation.location.id

export const insertItemIntoCache = cacheUpdate(
  LOCATION_LIST_QUERY,
  (cache, data) => ({
    allLocationsList: getLocationList(cache).concat([
      getCreatedLocationItem(data),
    ]),
  }),
)
export const deleteItemFromCache = cacheUpdate(
  LOCATION_LIST_QUERY,
  (cache, data) => ({
    allLocationsList: reject(getLocationList(cache), {
      id: getDeletedLocationId(data),
    }),
  }),
)

export const getResourceName = resource =>
  (resource && resource.name) || `New ${singleName}`

export const cacheRedirects = {
  locationById: (_, args, { getCacheKey }) =>
    getCacheKey({ __typename: "Location", id: args.id }),
}
