import React from "react"

import { Consumer } from "./Context"

function withAuthenticationContext(WrappedComponent) {
  function WithAuthenticationContext({ children, ...rest }) {
    return (
      <Consumer>
        {authContext => (
          <WrappedComponent auth={authContext} {...rest}>
            {children}
          </WrappedComponent>
        )}
      </Consumer>
    )
  }
  WithAuthenticationContext.displayName = `WithAuthenticationContext(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`
  return WithAuthenticationContext
}
export default withAuthenticationContext
