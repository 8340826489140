import React from "react"
import PropTypes from "prop-types"

import { Alignment, CheckedColumn, Column, DataType } from "../../../../ui"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import {
  CreatedAtColumn,
  CreatedByColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  IdColumn,
  ResourceIndexPage,
} from "../../../resourcePage"

import { canCreate } from "../accessRights"
import { getUserList, USER_LIST_QUERY } from "../dataSource"
import { userPath, newUserPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"

import UserRoleCell from "./UserRoleCell"

const activeColumnFilter = ({ value }) =>
  ({ false: "Inactive", true: "Active" }[value])

function UserIndexPage({ auth: { userRole } }) {
  return (
    <Query query={USER_LIST_QUERY}>
      {({ loading, errors, data }) => {
        return (
          <ResourceIndexPage
            canCreate={canCreate(userRole)}
            uniqueName="user"
            errors={errors}
            loading={loading}
            newResourcePath={newUserPath}
            pluralName={pluralName}
            resources={{ items: getUserList(data) }}
            resourcePath={userPath}
            singleName={singleName}
          >
            <CheckedColumn
              field="active"
              filterParams={{ values: ["Active", "Inactive"] }}
              keyCreator={activeColumnFilter}
            />
            <Column
              field="role"
              width={80}
              align={Alignment.CENTER}
              renderer={UserRoleCell}
              type={DataType.DROP_DOWN}
            />
            <Column field="name" width={200} />
            <Column
              field="failedLoginAttempts"
              width={100}
              type={DataType.INTEGER}
            />
            <Column
              field="lastFailedLoginAttemptAt"
              width={150}
              type={DataType.DATE_TIME}
            />
            <Column field="email" width={300} />
            <Column field="phone" width={200} />
            <Column field="remark" width={500} />
            <UpdatedAtColumn />
            <UpdatedByColumn />
            <CreatedAtColumn />
            <CreatedByColumn />
            <IdColumn />
          </ResourceIndexPage>
        )
      }}
    </Query>
  )
}
UserIndexPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string,
  }),
}
export default withAuthenticationContext(UserIndexPage)
