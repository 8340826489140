import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { ResourceShowPage, withResourceId } from "../../../resourcePage"

import {
  deleteItemFromCache,
  DELETE_PRODUCT_MUTATION,
  getProductItem,
  getResourceName,
  PRODUCT_ITEM_QUERY,
} from "../dataSource"
import { canCreate, canDelete, canUpdate } from "../accessRights"
import { editProductPath, newProductPath, productsPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"
import Product from "./Product"

const ProductShowPage = ({ auth: { userRole }, id }) => (
  <Query query={PRODUCT_ITEM_QUERY} variables={{ id }}>
    {({ loading, errors, data }) => {
      const product = getProductItem(data)

      if (!loading && !errors && !product) {
        errors = ["Not found."]
      }
      return (
        <ResourceShowPage
          canCreate={canCreate(userRole)}
          canDelete={canDelete(userRole)}
          canUpdate={canUpdate(userRole)}
          deleteItemFromCache={deleteItemFromCache}
          deleteMutation={DELETE_PRODUCT_MUTATION}
          editResourcePath={editProductPath}
          errors={errors}
          getResourceName={getResourceName}
          id={id}
          loading={loading}
          newResourcePath={newProductPath}
          pluralName={pluralName}
          resourceComponent={Product}
          resources={{ item: product }}
          resourcesPath={productsPath}
          singleName={singleName}
        />
      )
    }}
  </Query>
)
ProductShowPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
}
export default compose(
  withResourceId,
  withAuthenticationContext,
)(ProductShowPage)
