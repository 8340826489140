import React from "react"
import PropTypes from "prop-types"

import { Switch, Intent } from "@blueprintjs/core"

function SwitchInput({
  className,
  disabled,
  editing,
  innerLabel,
  innerLabelChecked,
  label,
  large,
  name,
  onBlur,
  onChange,
  style,
  valid,
  value,
}) {
  function handleChange({ target: { checked } }) {
    onChange({ target: { name, value: checked } })
  }

  return (
    <Switch
      checked={value}
      className={className}
      disabled={!editing || disabled}
      innerLabel={innerLabel}
      innerLabelChecked={innerLabelChecked}
      intent={valid ? Intent.NONE : Intent.DANGER}
      label={label}
      large={large}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      style={style}
    />
  )
}
SwitchInput.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.object,
  valid: PropTypes.bool,
  value: PropTypes.bool,
}
export default SwitchInput
