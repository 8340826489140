import React from "react"
import PropTypes from "prop-types"

import { SaveButton, CancelButton, Toolbar, withForm } from "../../../ui"

const FormToolbarButtons = ({ form: { isSubmitting }, handleCancel }) => (
  <Toolbar.Buttons leftDivider>
    <SaveButton type="submit" waiting={isSubmitting} />
    <CancelButton onClick={handleCancel} disabled={isSubmitting} />
  </Toolbar.Buttons>
)
FormToolbarButtons.propTypes = {
  form: PropTypes.shape({
    isSubmitting: PropTypes.bool,
  }),
  handleCancel: PropTypes.func,
}
export default withForm(FormToolbarButtons)
