import { gql } from "../../../state_management/DataProvider"

export const fields = `
  id,
  orderId,
  orderName
  productTypeName,
  productCode,
  productName,
  languageName,
  sourceGoodsStateName,
  sourceLocationName,
  sourceContractorName,
  targetGoodsStateName,
  targetLocationName,
  targetContractorName,
  date,
  quantity,
  reason,
  remark,
  createdAt,
  createdBy,
  updatedAt,
  updatedBy
`

export const GOODS_MOVEMENT_LIST_QUERY = gql`
  query GoodsMovements($orderTypeId: BigInt!) {
    allMovementsList(condition: {orderTypeId: $orderTypeId}) { ${fields} }
  }
`
export const getGoodsMovementList = data => (data || {}).allMovementsList || []
