import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { concat } from "apollo-link"

import authMiddleware from "./authMiddleware"
import httpLink from "./httpLink"

const apolloClient = ({ cacheRedirects, getAuthToken, deleteAuthToken }) =>
  new ApolloClient({
    cache: new InMemoryCache({ cacheRedirects: { Query: cacheRedirects } }),
    link: concat(
      authMiddleware({ getAuthToken }),
      httpLink({ deleteAuthToken }),
    ),
  })

export default apolloClient
