import React from "react"
import PropTypes from "prop-types"

import { fromPairs, keys } from "../../utils"

function withRenderer(WrappedComponent, dependencyFields) {
  class WithRenderer extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        value: this.constructor.getValue(props),
        dependencies: this.constructor.getDependencyValues({
          dependencyFields,
          data: props.data,
        }),
      }
    }

    static getValue({ value, valueFormatted }) {
      return valueFormatted || value
    }

    static getDependencyValues({ dependencyFields, data }) {
      return fromPairs(
        (dependencyFields || []).map(field => [field, data[field]]),
      )
    }

    refresh(params) {
      const value = this.constructor.getValue(params)
      if (value !== this.state.value) this.setState({ value })

      const dependencies = this.constructor.getDependencyValues({
        dependencyFields,
        data: params.data,
      })
      const dependenciesChanged = !keys(dependencies).reduce(
        (unchanged, field) =>
          unchanged && dependencies[field] === this.state.dependencies[field],
        true,
      )
      if (dependenciesChanged) this.setState({ dependencies })

      return true
    }

    render() {
      const { dependencies, value } = this.state
      return <WrappedComponent value={value} dependencies={dependencies} />
    }
  }
  WithRenderer.propTypes = {
    value: PropTypes.any.isRequired,
    valueFormatted: PropTypes.any,
    data: PropTypes.object,
  }
  WithRenderer.displayName = `WithRenderer(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`
  return WithRenderer
}
export default withRenderer
