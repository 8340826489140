import React from "react"

import { withRouter } from "react-router-dom"

function withPermalink(WrappedComponent) {
  function WithPermalink({
    match: {
      params: { permalink },
    },
    children,
    ...rest
  }) {
    return (
      <WrappedComponent permalink={permalink} {...rest}>
        {children}
      </WrappedComponent>
    )
  }
  WithPermalink.displayName = `WithPermalink(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`
  return withRouter(WithPermalink)
}
export default withPermalink
