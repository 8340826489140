import React from "react"
import PropTypes from "prop-types"

import { withAuthenticationContext } from "../../../../state_management/Authentication"

import { ResourceNewPage } from "../../../resourcePage"

import {
  CREATE_GOODS_STATE_MUTATION,
  getCreatedGoodsStateItem,
  getResourceName,
  insertItemIntoCache,
} from "../dataSource"
import { canCreate } from "../accessRights"
import { goodsStatePath, goodsStatesPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"
import GoodsState from "./GoodsState"

function GoodsStateNewPage({ auth: { userRole } }) {
  return (
    <ResourceNewPage
      createMutation={CREATE_GOODS_STATE_MUTATION}
      errors={canCreate(userRole) ? undefined : ["Access denied."]}
      getResourceFromCreateMutation={getCreatedGoodsStateItem}
      getResourceName={getResourceName}
      insertItemIntoCache={insertItemIntoCache}
      pluralName={pluralName}
      resourceComponent={GoodsState}
      resourcePath={goodsStatePath}
      resourcesPath={goodsStatesPath}
      singleName={singleName}
    />
  )
}
GoodsStateNewPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
}
export default withAuthenticationContext(GoodsStateNewPage)
