import Alignment from "../Alignment"
import Checked from "../Checked"
import Column from "./Column"
import withRenderer from "../DataGrid/withRenderer"
import { DROP_DOWN } from "../DataType"

const CheckedCell = withRenderer(Checked)

class CheckedColumn extends Column {}
CheckedColumn.defaultProps = {
  align: Alignment.CENTER,
  renderer: CheckedCell,
  width: 40,
  type: DROP_DOWN,
}

export default CheckedColumn
