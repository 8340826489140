import React from "react"
import PropTypes from "prop-types"

import { values } from "../../utils"
import Alignment from "../Alignment"

class Column extends React.Component {}
Column.propTypes = {
  field: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ),
  type: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  renderer: PropTypes.func,
  align: PropTypes.oneOf(values(Alignment)),
}
export default Column
