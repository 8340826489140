import React from "react"

import { graphql, withApollo } from "react-apollo"
import isUnauthorized from "./isUnathorized"
import extractErrors from "./extractErrors"

function withErrorHandler(WrappedComponent) {
  function WithErrorHandler({ children, client, data, ...rest }) {
    data && isUnauthorized(data.error) && client.resetStore()

    return (
      <WrappedComponent
        errors={data && extractErrors(data.error)}
        data={data}
        {...rest}
      >
        {children}
      </WrappedComponent>
    )
  }
  WithErrorHandler.displayName = `WithErrorHandler(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`
  return WithErrorHandler
}

const graphqlWithErrorHandler = (...params) => Component =>
  graphql(...params)(withApollo(withErrorHandler(Component)))
export default graphqlWithErrorHandler
