import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { ResourceShowPage, withResourceId } from "../../../resourcePage"

import {
  deleteItemFromCache,
  DELETE_PRODUCT_OWNER_MUTATION,
  getProductOwnerItem,
  getResourceName,
  PRODUCT_OWNER_ITEM_QUERY,
} from "../dataSource"
import { canCreate, canDelete, canUpdate } from "../accessRights"
import {
  editProductOwnerPath,
  newProductOwnerPath,
  productOwnersPath,
} from "../paths"
import { pluralName, singleName } from "../metaInfo"
import ProductOwner from "./ProductOwner"

const ProductOwnerShowPage = ({ auth: { userRole }, id }) => (
  <Query query={PRODUCT_OWNER_ITEM_QUERY} variables={{ id }}>
    {({ loading, errors, data }) => {
      const productOwner = getProductOwnerItem(data)

      if (!loading && !errors && !productOwner) {
        errors = ["Not found."]
      }
      return (
        <ResourceShowPage
          canCreate={canCreate(userRole)}
          canDelete={canDelete(userRole)}
          canUpdate={canUpdate(userRole)}
          deleteItemFromCache={deleteItemFromCache}
          deleteMutation={DELETE_PRODUCT_OWNER_MUTATION}
          editResourcePath={editProductOwnerPath}
          errors={errors}
          getResourceName={getResourceName}
          id={id}
          loading={loading}
          newResourcePath={newProductOwnerPath}
          pluralName={pluralName}
          resourceComponent={ProductOwner}
          resources={{ item: productOwner }}
          resourcesPath={productOwnersPath}
          singleName={singleName}
        />
      )
    }}
  </Query>
)
ProductOwnerShowPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
}
export default compose(
  withResourceId,
  withAuthenticationContext,
)(ProductOwnerShowPage)
