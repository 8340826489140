import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { ResourceShowPage, withResourceId } from "../../../resourcePage"

import {
  deleteItemFromCache,
  DELETE_CONTRACTOR_MUTATION,
  getContractorItem,
  getResourceName,
  CONTRACTOR_ITEM_QUERY,
} from "../dataSource"
import { canCreate, canDelete, canUpdate } from "../accessRights"
import {
  editContractorPath,
  newContractorPath,
  contractorsPath,
} from "../paths"
import { pluralName, singleName } from "../metaInfo"
import Contractor from "./Contractor"

function ContractorShowPage({ auth: { userRole }, id }) {
  return (
    <Query query={CONTRACTOR_ITEM_QUERY} variables={{ id }}>
      {({ loading, errors, data }) => {
        const contractor = getContractorItem(data)

        if (!loading && !errors && !contractor) {
          errors = ["Not found."]
        }
        return (
          <ResourceShowPage
            canCreate={canCreate(userRole)}
            canDelete={canDelete(userRole)}
            canUpdate={canUpdate(userRole)}
            deleteItemFromCache={deleteItemFromCache}
            deleteMutation={DELETE_CONTRACTOR_MUTATION}
            editResourcePath={editContractorPath}
            errors={errors}
            getResourceName={getResourceName}
            id={id}
            loading={loading}
            newResourcePath={newContractorPath}
            pluralName={pluralName}
            resourceComponent={Contractor}
            resources={{ item: contractor }}
            resourcesPath={contractorsPath}
            singleName={singleName}
          />
        )
      }}
    </Query>
  )
}
ContractorShowPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
}
export default compose(
  withResourceId,
  withAuthenticationContext,
)(ContractorShowPage)
