import React from "react"
import PropTypes from "prop-types"

import { Switch, Redirect, Route } from "../../state_management/Router"

import { deleteAuthToken } from "../auth"
import { withMetaData } from "../metaData"

import { rootPath, logoutPath } from "./paths"

import {
  ContractorEditPage,
  ContractorNewPage,
  ContractorShowPage,
  ContractorIndexPage,
} from "../domains/contractor/pages"
import {
  editContractorPath,
  newContractorPath,
  contractorPath,
  contractorsPath,
} from "../domains/contractor/paths"

import { GoodsMovementIndexPage } from "../domains/goodsMovement/pages"
import { goodsMovementsPath } from "../domains/goodsMovement/paths"

import {
  GoodsStateEditPage,
  GoodsStateNewPage,
  GoodsStateShowPage,
  GoodsStateIndexPage,
} from "../domains/goodsState/pages"
import {
  editGoodsStatePath,
  newGoodsStatePath,
  goodsStatePath,
  goodsStatesPath,
} from "../domains/goodsState/paths"

import {
  LanguageEditPage,
  LanguageNewPage,
  LanguageShowPage,
  LanguageIndexPage,
} from "../domains/language/pages"
import {
  editLanguagePath,
  newLanguagePath,
  languagePath,
  languagesPath,
} from "../domains/language/paths"

import {
  LocationEditPage,
  LocationNewPage,
  LocationShowPage,
  LocationIndexPage,
} from "../domains/location/pages"
import {
  editLocationPath,
  newLocationPath,
  locationPath,
  locationsPath,
} from "../domains/location/paths"

import {
  OrderEditPage,
  OrderNewPage,
  OrderShowPage,
  OrderIndexPage,
} from "../domains/order/pages"
import {
  editOrderPath,
  newOrderPath,
  orderPath,
  ordersPath,
} from "../domains/order/paths"

import {
  ProductEditPage,
  ProductNewPage,
  ProductShowPage,
  ProductIndexPage,
} from "../domains/product/pages"
import {
  editProductPath,
  newProductPath,
  productPath,
  productsPath,
} from "../domains/product/paths"

import {
  ProductOwnerEditPage,
  ProductOwnerNewPage,
  ProductOwnerShowPage,
  ProductOwnerIndexPage,
} from "../domains/productOwner/pages"
import {
  editProductOwnerPath,
  newProductOwnerPath,
  productOwnerPath,
  productOwnersPath,
} from "../domains/productOwner/paths"

import {
  ProductTypeEditPage,
  ProductTypeNewPage,
  ProductTypeShowPage,
  ProductTypeIndexPage,
} from "../domains/productType/pages"
import {
  editProductTypePath,
  newProductTypePath,
  productTypePath,
  productTypesPath,
} from "../domains/productType/paths"

import { StockIndexPage } from "../domains/stock/pages"
import { stockPath } from "../domains/stock/paths"

import { StockMovementIndexPage } from "../domains/stockMovement/pages"
import { stockMovementsPath } from "../domains/stockMovement/paths"

import {
  UserEditPage,
  UserNewPage,
  UserShowPage,
  UserIndexPage,
} from "../domains/user/pages"
import {
  editUserPath,
  newUserPath,
  userPath,
  usersPath,
} from "../domains/user/paths"

function AppRouter({ metaData: { stocks }, onLogout }) {
  return (
    <Switch>
      <Route path={logoutPath()} render={logout(onLogout)} />
      <Route exact path={rootPath()} render={root(stocks[0].permalink)} />

      <Route path={editContractorPath()} component={ContractorEditPage} />
      <Route path={newContractorPath()} component={ContractorNewPage} />
      <Route path={contractorPath()} component={ContractorShowPage} />
      <Route path={contractorsPath()} component={ContractorIndexPage} />

      <Route path={goodsMovementsPath()()} component={GoodsMovementIndexPage} />

      <Route path={editGoodsStatePath()} component={GoodsStateEditPage} />
      <Route path={newGoodsStatePath()} component={GoodsStateNewPage} />
      <Route path={goodsStatePath()} component={GoodsStateShowPage} />
      <Route path={goodsStatesPath()} component={GoodsStateIndexPage} />

      <Route path={editLanguagePath()} component={LanguageEditPage} />
      <Route path={newLanguagePath()} component={LanguageNewPage} />
      <Route path={languagePath()} component={LanguageShowPage} />
      <Route path={languagesPath()} component={LanguageIndexPage} />

      <Route path={editLocationPath()} component={LocationEditPage} />
      <Route path={newLocationPath()} component={LocationNewPage} />
      <Route path={locationPath()} component={LocationShowPage} />
      <Route path={locationsPath()} component={LocationIndexPage} />

      <Route path={editOrderPath()} component={OrderEditPage} />
      <Route path={newOrderPath()()} component={OrderNewPage} />
      <Route path={orderPath()} component={OrderShowPage} />
      <Route path={ordersPath()()} component={OrderIndexPage} />

      <Route path={editProductPath()} component={ProductEditPage} />
      <Route path={newProductPath()} component={ProductNewPage} />
      <Route path={productPath()} component={ProductShowPage} />
      <Route path={productsPath()} component={ProductIndexPage} />

      <Route path={editProductOwnerPath()} component={ProductOwnerEditPage} />
      <Route path={newProductOwnerPath()} component={ProductOwnerNewPage} />
      <Route path={productOwnerPath()} component={ProductOwnerShowPage} />
      <Route path={productOwnersPath()} component={ProductOwnerIndexPage} />

      <Route path={editProductTypePath()} component={ProductTypeEditPage} />
      <Route path={newProductTypePath()} component={ProductTypeNewPage} />
      <Route path={productTypePath()} component={ProductTypeShowPage} />
      <Route path={productTypesPath()} component={ProductTypeIndexPage} />

      <Route path={stockPath()()} component={StockIndexPage} />

      <Route path={stockMovementsPath()()} component={StockMovementIndexPage} />

      <Route path={editUserPath()} component={UserEditPage} />
      <Route path={newUserPath()} component={UserNewPage} />
      <Route path={userPath()} component={UserShowPage} />
      <Route path={usersPath()} component={UserIndexPage} />
    </Switch>
  )
}

function logout(onLogout) {
  function Logout() {
    deleteAuthToken()
    onLogout && onLogout()
    return <Redirect push to="/" />
  }
  return Logout
}

function root(permalink) {
  function Root() {
    return <Redirect to={stockPath(permalink)()} />
  }
  return Root
}

AppRouter.propTypes = {
  metaData: PropTypes.shape({
    stocks: PropTypes.arrayOf(
      PropTypes.shape({
        permalink: PropTypes.string,
      }),
    ),
  }),
  onLogout: PropTypes.func,
}
export default withMetaData(AppRouter)
