import React from "react"
import PropTypes from "prop-types"

import { HTMLTable } from "@blueprintjs/core"

import { fromPairs, startCase } from "../../utils"

import Form from "../Form"
import Column, { ActionsColumn } from "../Column"
import { DEFAULT_ACTIONS } from "../DataType"

import Row from "./Row"
import types from "./types"

import "./DataTable.css"

function DataTable({
  cancelEditingRow,
  children,
  data,
  deleteRow,
  editable,
  editingRowId,
  newRowId,
  newRowInitialValues,
  rowName,
  rowValuesUpdater,
  saveNewRow,
  saveRow,
  startEditingRow,
  validationSchema,
  waitingNewRow,
  waitingRowId,
}) {
  function handleSave(values) {
    saveRow(editingRowId, values)
  }

  const columns = React.Children.toArray(children)
    .map(({ props: { component, field, title, type, ...rest } }) => {
      const { cell, field: defaultField } = types(type)
      return {
        ...rest,
        cell: component || cell,
        field: field || defaultField,
        title: title || startCase(field),
        type,
      }
    })
    .filter(({ type }) => editable || type !== DEFAULT_ACTIONS)

  return (
    <HTMLTable bordered className="data-table" striped>
      <thead>
        <tr>
          {columns.map(({ field, type, title, width }) => {
            return <th key={field}>{title}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {editable && (
          <Form
            key={`new${newRowId}`}
            editing={true}
            initialValues={
              newRowInitialValues ||
              fromPairs(
                columns
                  .filter(({ editable }) => editable)
                  .map(({ field, keyField }) => [keyField || field, ""]),
              )
            }
            onSubmit={saveNewRow}
            renderFormTag={false}
            updateValues={rowValuesUpdater}
            validationSchema={validationSchema}
          >
            {({ resetForm }) => (
              <Row
                columns={columns}
                data={{}}
                editable={true}
                editing={true}
                onCancel={resetForm}
                onSave={saveRow}
                rowValuesUpdater={rowValuesUpdater}
                validationSchema={validationSchema}
                waiting={waitingNewRow}
              />
            )}
          </Form>
        )}
        {data.map(row => {
          const rowNode = (
            <Row
              columns={columns}
              data={row}
              editable={editable && (!editingRowId || row.id === editingRowId)}
              editing={editable && row.id === editingRowId}
              key={row.id}
              onCancel={cancelEditingRow}
              onDelete={deleteRow}
              onEdit={startEditingRow}
              rowName={rowName}
              waiting={editable && row.id === waitingRowId}
            />
          )

          if (editable && editingRowId === row.id) {
            const initialValues = fromPairs(
              columns
                .filter(({ editable }) => editable)
                .map(({ field, keyField }) => [
                  keyField || field,
                  row[keyField || field] || "",
                ]),
            )

            return (
              <Form
                key={row.id}
                editing={editable && !!editingRowId}
                initialValues={initialValues}
                onSubmit={handleSave}
                renderFormTag={false}
                updateValues={rowValuesUpdater}
                validationSchema={validationSchema}
              >
                {rowNode}
              </Form>
            )
          }
          return rowNode
        })}
      </tbody>
    </HTMLTable>
  )
}

DataTable.propTypes = {
  cancelEditingRow: PropTypes.func,
  children: props => {
    const children = React.Children.toArray(props.children)
    for (let i = 0; i < children.length; i++) {
      const childType = children[i].type
      if (
        childType !== Column &&
        !(childType.prototype instanceof Column) &&
        childType !== ActionsColumn &&
        !(childType.prototype instanceof ActionsColumn)
      ) {
        return new Error(
          "DataTable only accepts children of type Column or ActionColumn",
        )
      }
    }
  },
  data: PropTypes.arrayOf(PropTypes.object),
  deleteRow: PropTypes.func,
  editable: PropTypes.bool,
  editingRowId: PropTypes.string,
  newRowId: PropTypes.string,
  newRowInitialValues: PropTypes.object,
  rowName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowValuesUpdater: PropTypes.func,
  saveNewRow: PropTypes.func,
  saveRow: PropTypes.func,
  startEditingRow: PropTypes.func,
  validationSchema: PropTypes.object,
  waitingNewRow: PropTypes.bool,
  waitingRowId: PropTypes.string,
}
export default DataTable
