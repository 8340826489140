import React from "react"
import PropTypes from "prop-types"

import { Icon, InputGroup, Intent } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

import "./Input.css"

function TextInput({
  disabled,
  editing,
  large,
  name,
  onBlur,
  onChange,
  style,
  type,
  valid,
  value,
  width,
}) {
  return (
    <InputGroup
      disabled={disabled}
      intent={valid ? Intent.NONE : Intent.DANGER}
      large={large}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      readOnly={!editing}
      rightElement={
        !valid && (
          <Icon
            icon={IconNames.WARNING_SIGN}
            size={Icon.SIZE_LARGE}
            intent={Intent.DANGER}
            style={{ margin: large ? 12 : 7 }}
          />
        )
      }
      style={width ? { width, ...style } : style}
      type={type}
      value={value}
    />
  )
}
TextInput.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.object,
  type: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
export default TextInput
