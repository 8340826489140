import React from "react"
import PropTypes from "prop-types"

import { withAuthenticationContext } from "../../../../state_management/Authentication"

import { ResourceNewPage } from "../../../resourcePage"

import {
  CREATE_USER_MUTATION,
  getCreatedUserItem,
  getResourceName,
  insertItemIntoCache,
} from "../dataSource"
import { canCreate } from "../accessRights"
import { userPath, usersPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"
import User from "./User"

const UserNewPage = ({ auth: { userRole } }) => (
  <ResourceNewPage
    createMutation={CREATE_USER_MUTATION}
    errors={canCreate(userRole) ? undefined : ["Access denied."]}
    getResourceFromCreateMutation={getCreatedUserItem}
    getResourceName={getResourceName}
    initialValues={User.newUserInitialValues}
    insertItemIntoCache={insertItemIntoCache}
    pluralName={pluralName}
    resourceComponent={User}
    resourcePath={userPath}
    resourcesPath={usersPath}
    singleName={singleName}
    validationSchema={User.NEW_USER_VALIDATION_SCHEMA}
  />
)
UserNewPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
}
export default withAuthenticationContext(UserNewPage)
