import {
  resourcePathFor,
  resourcesPathFor,
  newResourcePathFor,
  editResourcePathFor,
} from "../../resourcePage"

export const productTypePath = resourcePathFor("product-types")
export const productTypesPath = resourcesPathFor("product-types")
export const newProductTypePath = newResourcePathFor("product-types")
export const editProductTypePath = editResourcePathFor("product-types")
