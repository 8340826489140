import React from "react"
import PropTypes from "prop-types"

import { DateInput } from "@blueprintjs/datetime"
import { Icon, Intent } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

import { formatDate, parseDate } from "../../utils"

import TextInput from "./TextInput"
import "./Input.css"

function MyDateInput({
  className,
  disabled,
  editing,
  isOpen,
  large,
  name,
  onBlur,
  onChange,
  style,
  valid,
  value,
  width,
}) {
  function handleChange(selectedDate) {
    return onChange({ target: { name, value: selectedDate } })
  }
  if (editing) {
    return (
      <DateInput
        canClearSelection={false}
        className={className}
        disabled={disabled}
        formatDate={formatDate}
        inputProps={{
          intent: valid ? Intent.NONE : Intent.DANGER,
          large,
          name,
          onBlur,
          style: width ? { width, ...style } : style,
        }}
        onChange={handleChange}
        parseDate={parseDate}
        placeholder="dd.mm.yyyy"
        readOnly={!editing}
        rightElement={
          !valid && (
            <Icon
              icon={IconNames.WARNING_SIGN}
              size={Icon.SIZE_LARGE}
              intent={Intent.DANGER}
              style={{ margin: large ? 12 : 7 }}
            />
          )
        }
        showActionsBar={true}
        value={value}
      />
    )
  } else {
    return (
      <TextInput
        disabled={disabled}
        large={large}
        name={name}
        style={style}
        valid={valid}
        value={formatDate(value)}
        width={width}
      />
    )
  }
}
MyDateInput.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  isOpen: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.object,
  valid: PropTypes.bool,
  value: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
export default MyDateInput
