import React from "react"
import PropTypes from "prop-types"

import {
  BackButton,
  DeleteButton,
  EditButton,
  Intent,
  NewButton,
  Toolbar,
} from "../../../ui"
import DeleteConfirmation from "../../../ui/DeleteConfirmation"

class ItemToolbarButtons extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showDeleteConfirmation: false }

    this.deleteClicked = this.deleteClicked.bind(this)
    this.closeConfirmationClicked = this.closeConfirmationClicked.bind(this)
    this.confirmDeleteClicked = this.confirmDeleteClicked.bind(this)
  }

  showDeleteConfirmation(value) {
    this.setState({ showDeleteConfirmation: value })
  }

  deleteClicked() {
    this.showDeleteConfirmation(true)
  }
  closeConfirmationClicked() {
    this.showDeleteConfirmation(false)
  }
  confirmDeleteClicked() {
    this.showDeleteConfirmation(false)
    this.props.handleDelete()
  }

  render() {
    const {
      actionToolbar,
      additionalEditButton: AdditionalEditButton,
      canCreate,
      canUpdate,
      canDelete,
      waiting,
      deleteConfirmationMessage,
      handleAdditionalEdit,
      handleBack,
      handleDelete,
      handleEdit,
      handleNew,
    } = this.props
    return (
      <React.Fragment>
        {(handleBack || canUpdate || canDelete) && (
          <Toolbar.Buttons leftDivider>
            {handleBack && (
              <BackButton onClick={handleBack} disabled={waiting} />
            )}
            {canUpdate && (
              <React.Fragment>
                <EditButton onClick={handleEdit} disabled={waiting} />
                {AdditionalEditButton && (
                  <AdditionalEditButton
                    onClick={handleAdditionalEdit}
                    disabled={waiting}
                  />
                )}
              </React.Fragment>
            )}
            {canDelete && (
              <DeleteConfirmation
                item={deleteConfirmationMessage}
                handleDelete={handleDelete}
              >
                {({ handleDelete }) => (
                  <DeleteButton onClick={handleDelete} waiting={waiting} />
                )}
              </DeleteConfirmation>
            )}
          </Toolbar.Buttons>
        )}
        {actionToolbar}
        {canCreate && (
          <Toolbar.Buttons leftDivider>
            <NewButton
              onClick={handleNew}
              disabled={waiting}
              intent={Intent.NONE}
            />
          </Toolbar.Buttons>
        )}
      </React.Fragment>
    )
  }
}
ItemToolbarButtons.propTypes = {
  actionToolbar: PropTypes.node,
  additionalEditButton: PropTypes.func,
  canCreate: PropTypes.bool,
  canUpdate: PropTypes.bool,
  canDelete: PropTypes.bool,
  deleteConfirmationMessage: PropTypes.string.isRequired,
  handleAdditionalEdit: PropTypes.func,
  handleBack: PropTypes.func,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleNew: PropTypes.func.isRequired,
  waiting: PropTypes.bool,
}
export default ItemToolbarButtons
