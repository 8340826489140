import React from "react"
import PropTypes from "prop-types"

function CardColumn({ children, flexWidth }) {
  return (
    <div className="card-column" flex={flexWidth}>
      {children}
    </div>
  )
}
CardColumn.propTypes = {
  children: PropTypes.node,
  flexWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
export default CardColumn
