import React from "react"
import PropTypes from "prop-types"

import { withRedirect } from "../../state_management/Router"

import { ResourceItemsPage } from "./items"

function ResourceIndexPage({
  canCreate,
  children,
  errors,
  loading,
  newResourcePath,
  pluralName,
  resources,
  resourcePath,
  resourceIdField,
  router: { redirect, state },
  singleName,
  toolbar,
  uniqueName,
}) {
  function redirectToNew() {
    redirect({ to: newResourcePath(), withReferrer: true })
  }
  function rowDoubleClicked(data) {
    resourcePath &&
      redirect({ to: resourcePath(data[resourceIdField]), withReferrer: true })
  }
  function redirectToBack() {
    redirect({ backTo: true })
  }

  return (
    <ResourceItemsPage
      canCreate={canCreate}
      errors={errors}
      handleBack={state && state.referrer && redirectToBack}
      handleNew={redirectToNew}
      handleOpen={rowDoubleClicked}
      loading={loading}
      pluralName={pluralName}
      singleName={singleName}
      resources={resources && resources.items}
      toolbar={toolbar}
      uniqueName={uniqueName}
    >
      {children}
    </ResourceItemsPage>
  )
}
ResourceIndexPage.defaultProps = {
  resourceIdField: "id",
}
ResourceIndexPage.propTypes = {
  canCreate: PropTypes.bool,
  children: PropTypes.node.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  newResourcePath: PropTypes.func,
  pluralName: PropTypes.string.isRequired,
  resourcePath: PropTypes.func,
  resourceIdField: PropTypes.string.isRequired,
  resources: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
  }),
  router: PropTypes.shape({
    redirect: PropTypes.func.isRequired,
  }).isRequired,
  singleName: PropTypes.string,
  toolbar: PropTypes.node,
  uniqueName: PropTypes.string.isRequired,
}
export default withRedirect(ResourceIndexPage)
