import React from "react"
import PropTypes from "prop-types"

import { Classes, Dialog as BpDialog } from "@blueprintjs/core"

const DialogBody = ({ children }) => (
  <div className={Classes.DIALOG_BODY}>{children}</div>
)
DialogBody.propTypes = {
  children: PropTypes.node,
}

const DialogFooterActions = ({ children }) => (
  <div className={Classes.DIALOG_FOOTER_ACTIONS}>{children}</div>
)
DialogFooterActions.propTypes = {
  children: PropTypes.node,
}

const DialogFooter = ({ children }) => (
  <div className={Classes.DIALOG_FOOTER}>{children}</div>
)
DialogFooter.propTypes = {
  children: PropTypes.node,
}
DialogFooter.Actions = DialogFooterActions

const Dialog = ({ children, closable, ...props }) => (
  <BpDialog
    {...props}
    canEscapeKeyClose={closable}
    canOutsideClickClose={closable}
    isCloseButtonShown={closable}
  >
    {children}
  </BpDialog>
)
Dialog.propTypes = {
  children: PropTypes.node,
  closable: PropTypes.bool,
}
Dialog.Body = DialogBody
Dialog.Footer = DialogFooter
export default Dialog
