import React from "react"
import PropTypes from "prop-types"

import Error from "./Error"
import Loading from "./Loading"
import Page from "./Page"
import Toolbar from "./Toolbar"

const ResourcePage = ({
  children,
  errors,
  loading,
  navigation,
  toolbar,
  customToolbar,
  lastToolbar,
}) => {
  return (
    <Page>
      {!errors &&
        !loading && (
          <Toolbar navigation={navigation}>
            {toolbar}
            {customToolbar}
            {lastToolbar}
          </Toolbar>
        )}
      <Page.Content>
        {errors ? <Error errors={errors} /> : loading ? <Loading /> : children}
      </Page.Content>
    </Page>
  )
}
ResourcePage.propTypes = {
  children: PropTypes.node,
  customToolbar: PropTypes.node,
  errors: PropTypes.array,
  lastToolbar: PropTypes.node,
  loading: PropTypes.bool,
  navigation: PropTypes.array,
  toolbar: PropTypes.node,
}
export default ResourcePage
