import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

import { AgGridReact } from "ag-grid-react"

import Alignment from "../Alignment"
import Column from "../Column"
import {
  DATE,
  DATE_TIME,
  DROP_DOWN,
  ID,
  INTEGER,
  SELECT,
  TEXT,
} from "../DataType"

import { find, formatDate, formatDateTime, formatNumber } from "../../utils"
import { SetFilter } from "./setFilter/setFilter"

import "./DataGrid.css"

const COLUMN_STATE = "columns"
const SORTING_STATE = "sorting"
const FILTER_STATE = "filters"

const dateTimeFormatter = ({ value }) => formatDateTime(value)
const dateTimeGetter = ({ colDef: { field }, data }) => {
  const value = field.split(".").reduce((obj, key) => obj && obj[key], data)
  return value ? new Date(value) : null
}
const dateFormatter = ({ value }) => formatDate(value)
const integerFormatter = ({ value }) => formatNumber(value)

const alignmentClassName = alignment => {
  if (alignment === Alignment.LEFT) return "align-left"
  if (alignment === Alignment.RIGHT) return "align-right"
  return "align-center"
}

const DataGrid = ({
  children,
  uniqueName,
  data,
  saveState,
  loadState,
  domElement,
  onRowDoubleClick,
  onReady,
}) => {
  function stateKey(key) {
    return `ag-grid:state:${uniqueName}:${key}`
  }
  function loadTableState(key) {
    return uniqueName && loadState && loadState(stateKey(key))
  }
  function saveTableState(key, value) {
    uniqueName && saveState && saveState(stateKey(key), value)
  }
  function onColumnChanged({ columnApi }) {
    saveTableState(COLUMN_STATE, JSON.stringify(columnApi.getColumnState()))
  }
  function onSortChanged({ api }) {
    saveTableState(SORTING_STATE, JSON.stringify(api.getSortModel()))
  }
  function onFilterChanged({ api }) {
    saveTableState(FILTER_STATE, JSON.stringify(api.getFilterModel()))
  }
  function onGridReady({ api, columnApi }) {
    function exportToCsv() {
      api.exportDataAsCsv()
    }
    onReady && onReady({ exportToCsv })
    const columnsState = loadTableState(COLUMN_STATE)
    columnsState &&
      columnApi.setColumnState(
        JSON.parse(columnsState).map(({ hide, ...rest }) => ({
          hide: (find(colDefs, { field: rest.colId }) || {}).hide,
          ...rest,
        })),
      )

    const sortState = loadTableState(SORTING_STATE)
    sortState && api.setSortModel(JSON.parse(sortState))

    const filterState = loadTableState(FILTER_STATE)
    filterState && api.setFilterModel(JSON.parse(filterState))
  }

  const colDefs = React.Children.toArray(children).map(
    ({ props: { align, filter, renderer, title, type, ...rest } }) => ({
      ...(align ? { cellClass: alignmentClassName(align) } : {}),
      cellRendererFramework: renderer,
      headerName: title,
      type: type || TEXT,
      ...rest,
    }),
  )

  const grid = (
    <AgGridReact
      enableColResize
      enableFilter
      enableSorting
      suppressCellSelection={true}
      onGridReady={onGridReady}
      onColumnVisible={onColumnChanged}
      onColumnPinned={onColumnChanged}
      onColumnResized={onColumnChanged}
      onColumnMoved={onColumnChanged}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
      onRowDoubleClicked={onRowDoubleClick}
      animateRows={false}
      domLayout={domElement ? "normal" : "autoHeight"}
      reactNext={true}
      rowData={data}
      columnDefs={colDefs}
      columnTypes={{
        [DATE_TIME]: {
          valueFormatter: dateTimeFormatter,
          valueGetter: dateTimeGetter,
          filterValueGetter: dateTimeGetter,
          filter: "agDateColumnFilter",
          width: 140,
        },
        [DATE]: {
          valueFormatter: dateFormatter,
          valueGetter: dateTimeGetter,
          filterValueGetter: dateTimeGetter,
          filter: "agDateColumnFilter",
          width: 140,
        },
        [DROP_DOWN]: { filter: SetFilter },
        [ID]: {
          cellClass: "ag-numeric-cell",
          headerClass: "ag-numeric-header",
          filter: "agNumberColumnFilter",
          width: 140,
        },
        [INTEGER]: {
          cellClass: "ag-numeric-cell",
          headerClass: "ag-numeric-header",
          filter: "agNumberColumnFilter",
          valueFormatter: integerFormatter,
          width: 140,
        },
        [SELECT]: { filter: "agTextColumnFilter" },
        [TEXT]: { filter: "agTextColumnFilter" },
      }}
    />
  )
  if (domElement) {
    return ReactDOM.createPortal(grid, domElement)
  } else {
    return grid
  }
}
DataGrid.propTypes = {
  children: props => {
    const children = React.Children.toArray(props.children)
    for (let i = 0; i < children.length; i++) {
      const childType = children[i].type
      if (childType !== Column && !(childType.prototype instanceof Column)) {
        return new Error("DataGrid only accepts children of type Column")
      }
    }
  },
  uniqueName: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  saveState: PropTypes.func,
  loadState: PropTypes.func,
  domElement: PropTypes.object,
  onReady: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
}
export default DataGrid
