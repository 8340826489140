import { cacheUpdate, gql } from "../../../state_management/DataProvider"

import { singleName } from "./metaInfo"

import { reject } from "../../../utils"

export const fields = `
      id
      productTypeId
      productTypeName
      code
      name
      nameWithLanguage
      isbn
      languageId
      languageCode
      languageName
      productOwnersIds
      productOwnersNames
      notForPublicSale
      remark
      createdAt
      createdBy
      updatedAt
      updatedBy
`
const inputParams =
  "$productTypeId: BigInt!, $code: String, $name: String!, $isbn: String, $languageId: BigInt, $productOwnersIds: [BigInt!]!, $notForPublicSale: Boolean, $remark: String"
const inputFields =
  "productTypeId: $productTypeId, code: $code, name: $name, isbn: $isbn, languageId: $languageId, productOwnersIds: $productOwnersIds, notForPublicSale: $notForPublicSale, remark: $remark"

export const PRODUCT_LIST_QUERY = gql`
  query Products {
    allProductsList { ${fields} }
  }
`
export const PRODUCT_ITEM_QUERY = gql`
  query Product($id: BigInt!) {
    productById(id: $id) { ${fields} }
  }
`

export const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateProduct(${inputParams}) {
    createProduct(input: { ${inputFields} }) {
      product { ${fields} }
    }
  }
`
export const UPDATE_PRODUCT_MUTATION = gql`
  mutation UpdateProduct($id: BigInt!, ${inputParams}) {
    updateProduct(input: { id: $id, ${inputFields} }) {
      product { ${fields} }
    }
  }
`
export const DELETE_PRODUCT_MUTATION = gql`
  mutation DeleteProduct($id: BigInt!) {
    deleteProduct(input: { id: $id }) {
      product {
        id
        name
      }
    }
  }
`

export const getProductList = data => (data || {}).allProductsList || []
export const getProductItem = data => (data || {}).productById
export const getCreatedProductItem = data => data.createProduct.product
export const getDeletedProductId = data => data.deleteProduct.product.id

export const insertItemIntoCache = cacheUpdate(
  PRODUCT_LIST_QUERY,
  (cache, data) => ({
    allProductsList: getProductList(cache).concat([
      getCreatedProductItem(data),
    ]),
  }),
)
export const deleteItemFromCache = cacheUpdate(
  PRODUCT_LIST_QUERY,
  (cache, data) => ({
    allProductsList: reject(getProductList(cache), {
      id: getDeletedProductId(data),
    }),
  }),
)

export const getResourceName = resource =>
  (resource && resource.name) || `New ${singleName}`

export const cacheRedirects = {
  productById: (_, args, { getCacheKey }) =>
    getCacheKey({ __typename: "Product", id: args.id }),
}
