import React from "react"
import PropTypes from "prop-types"

import { ButtonGroup, Navbar } from "@blueprintjs/core"

const Buttons = ({ children, leftDivider, rightDivider }) => (
  <Navbar.Group>
    {leftDivider && <Navbar.Divider />}
    <ButtonGroup minimal>{children}</ButtonGroup>
    {rightDivider && <Navbar.Divider />}
  </Navbar.Group>
)
Buttons.propTypes = {
  children: PropTypes.node,
  leftDivider: PropTypes.bool,
  rightDivider: PropTypes.bool,
}
export default Buttons
