import React from "react"

const { Provider, Consumer } = React.createContext({
  status: {},
  metaData: {
    orderTypes: [],
  },
})

export { Provider, Consumer }
