import React from "react"
import PropTypes from "prop-types"

import { object, string } from "../../../../utils/yup"

import { Card, TextInput } from "../../../../ui"

import { RemarkField } from "../../../resourcePage"

const ProductOwner = () => (
  <Card>
    <Card.Field component={TextInput} name="name" width={300} />
    <RemarkField />
  </Card>
)

ProductOwner.VALIDATION_SCHEMA = object().shape({
  name: string().required(),
  remark: string(),
})
ProductOwner.initialValues = ({ name, remark }) => ({
  name: name || "",
  remark: remark || "",
})
ProductOwner.propTypes = {
  newPage: PropTypes.bool,
  editPage: PropTypes.bool,
}
export default ProductOwner
