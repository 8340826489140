import React from "react"
import PropTypes from "prop-types"

import { concat, find, reject } from "../../../utils"

import { Button, InputGroup } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { MultiSelect } from "@blueprintjs/select"
import Spinner from "../../Spinner"
import NoItemsFound from "./NoItemsFound"

import createItemRenderer from "./createItemRenderer"

import "../Input.css"
import "./SelectInput.css"

function MultiSelectInput({
  disabled,
  editing,
  itemKey,
  items,
  itemValue,
  large,
  loading,
  keyName,
  onBlur,
  onChange,
  textValue,
  value,
}) {
  function onItemSelect({ id }) {
    if (~value.indexOf(id)) {
      changeValue(reject(value, vid => vid === id))
    } else {
      changeValue(concat(value, id))
    }
  }
  function tagRenderer({ [itemValue]: value } = {}) {
    return value
  }
  function isSelected(item) {
    return ~value.indexOf(item[itemKey])
  }
  function deselect(_, index) {
    changeValue(reject(value, (_, i) => index === i))
  }
  function deselectAll() {
    changeValue([])
  }
  function changeValue(value) {
    onChange({ target: { name: keyName, value } })
  }
  const itemRenderer = createItemRenderer({ name: "MultiSelect", isSelected })
  const clearButton =
    value.length > 0 ? (
      <Button icon={IconNames.CROSS} minimal={true} onClick={deselectAll} />
    ) : (
      undefined
    )

  if (loading)
    return <Spinner size={large ? Spinner.SIZE_LARGE : Spinner.SIZE_STANDARD} />
  if (editing) {
    return (
      <MultiSelect
        disabled={disabled}
        itemPredicate={itemRenderer.filter}
        itemRenderer={itemRenderer}
        items={items}
        large={large}
        noResults={<NoItemsFound />}
        onBlur={onBlur}
        onItemSelect={onItemSelect}
        popoverProps={{ minimal: true }}
        selectedItems={value.map(id => find(items, { [itemKey]: id }))}
        tagInputProps={{
          onRemove: deselect,
          rightElement: clearButton,
        }}
        tagRenderer={tagRenderer}
      />
    )
  } else {
    return (
      <InputGroup readOnly={true} large={large} value={textValue.join(", ")} />
    )
  }
}
MultiSelectInput.defaultProps = {
  itemKey: "id",
  itemValue: "name",
}
MultiSelectInput.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  itemKey: PropTypes.string,
  itemRenderer: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  itemValue: PropTypes.string,
  keyName: PropTypes.string,
  large: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  round: PropTypes.bool,
  tag: PropTypes.func,
  textValue: PropTypes.arrayOf(PropTypes.string),
  valid: PropTypes.bool,
}
export default MultiSelectInput
