import React from "react"
import PropTypes from "prop-types"

import { compose, find } from "../../../../utils"

import { EditMovementsButton, LockMovementsButton } from "../../../../ui"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Queries, Mutation } from "../../../../state_management/DataProvider"
import { withRouter } from "../../../../state_management/Router"

import { ResourceShowPage, withResourceId } from "../../../resourcePage"
import { withMetaData } from "../../../metaData"

import {
  CONTRACTOR_LIST_QUERY,
  getContractorList,
} from "../../contractor/dataSource"
import {
  GOODS_STATE_LIST_QUERY,
  getGoodsStateList,
} from "../../goodsState/dataSource"
import { LOCATION_LIST_QUERY, getLocationList } from "../../location/dataSource"
import { PRODUCT_LIST_QUERY, getProductList } from "../../product/dataSource"
import {
  PRODUCT_TYPE_LIST_QUERY,
  getProductTypeList,
} from "../../productType/dataSource"
import {
  MOVEMENT_LIST_QUERY,
  CREATE_MOVEMENT_MUTATION,
  DELETE_MOVEMENT_MUTATION,
  UPDATE_MOVEMENT_MUTATION,
  getMovementList,
  getCreatedMovementItem,
  insertItemIntoCache as insertMovementItemIntoCache,
  deleteItemFromCache as deleteMovementItemFromCache,
} from "../movement/dataSource"

import {
  deleteItemFromCache,
  DELETE_ORDER_MUTATION,
  getOrderItem,
  getResourceName,
  ORDER_ITEM_QUERY,
} from "../dataSource"
import { canCreate, canDelete, canUpdate } from "../accessRights"
import { editOrderPath, newOrderPath, ordersPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"
import Order from "./Order"

class OrderShowPage extends React.Component {
  constructor(props) {
    super(props)
    // Defined in OrderNewPage
    this.state = {
      movementsEditable:
        new URLSearchParams(props.location.search).get("editMovements") === "1",
      editingMovementId: null,
      deletingMovementId: null,
      newMovementId: "",
    }
    this.changeMovementsEditableState = this.changeMovementsEditableState.bind(
      this,
    )
    this.handleStartEditingMovement = this.handleStartEditingMovement.bind(this)
    this.handleStopEditingMovement = this.handleStopEditingMovement.bind(this)
    this.handleMovementCreated = this.handleMovementCreated.bind(this)
    this.handleMovementDeleted = this.handleMovementDeleted.bind(this)
  }
  changeMovementsEditableState() {
    const { editingMovementId, deletingMovementId } = this.state
    if (editingMovementId || deletingMovementId) return

    this.setState(state => ({
      movementsEditable: !state.movementsEditable,
    }))
  }
  handleStartEditingMovement({ id }) {
    const {
      movementsEditable,
      editingMovementId,
      deletingMovementId,
    } = this.state
    if (!movementsEditable || editingMovementId || deletingMovementId) return

    this.setState({ editingMovementId: id })
  }
  handleStopEditingMovement() {
    this.setState({ editingMovementId: null })
  }
  handleMovementDeleted() {
    this.setState({ deletingMovementId: null })
  }
  handleMovementCreated(data) {
    this.setState({ newMovementId: getCreatedMovementItem(data).id })
  }

  render() {
    const {
      auth: { userRole },
      id,
      metaData: { orderTypes },
    } = this.props
    const {
      movementsEditable,
      editingMovementId,
      deletingMovementId,
      newMovementId,
    } = this.state

    // prettier-ignore
    const queries = [
      { resourceName: "item",         query: ORDER_ITEM_QUERY,        getResource: getOrderItem,    variables: { id } },
      { resourceName: "movements",    query: MOVEMENT_LIST_QUERY,     getResource: getMovementList, variables: { orderId: id }, fetchPolicy: 'cache-and-network' },
      { resourceName: "contractors",  query: CONTRACTOR_LIST_QUERY,   getResource: getContractorList },
      { resourceName: "goodsStates",  query: GOODS_STATE_LIST_QUERY,  getResource: getGoodsStateList },
      { resourceName: "locations",    query: LOCATION_LIST_QUERY,     getResource: getLocationList },
      { resourceName: "products",     query: PRODUCT_LIST_QUERY,      getResource: getProductList },
      { resourceName: "productTypes", query: PRODUCT_TYPE_LIST_QUERY, getResource: getProductTypeList },
    ]

    return (
      <Queries queries={queries}>
        {({ loading, errors, resources }) => {
          const {
            contractors,
            goodsStates,
            item,
            locations,
            movements,
            products,
            productTypes,
          } = resources

          const orderType = find(orderTypes, { id: item.orderTypeId })
          const {
            name,
            permalink,
            sourceContractor,
            targetContractor,
            reason,
          } = orderType || {}

          if (!loading && !errors && (!item.id || !orderType)) {
            errors = ["Not found."]
          }

          return (
            <Mutation
              mutation={DELETE_MOVEMENT_MUTATION}
              onCompleted={this.handleMovementDeleted}
              onError={this.handleMovementDeleted}
              update={deleteMovementItemFromCache({ orderId: id })}
            >
              {deleteMovement => {
                const self = this
                function handleDeleteMovement({ id }) {
                  if (self.state.deletingMovementId) return
                  self.setState({ deletingMovementId: id })
                  deleteMovement({ variables: { id } })
                }

                return (
                  <Mutation
                    mutation={UPDATE_MOVEMENT_MUTATION}
                    onCompleted={this.handleStopEditingMovement}
                  >
                    {(updateMovement, { loading: updatingMovement }) => {
                      function handleSaveMovement(id, values) {
                        updateMovement({
                          variables: { id, ...values },
                        })
                      }

                      return (
                        <Mutation
                          mutation={CREATE_MOVEMENT_MUTATION}
                          onCompleted={this.handleMovementCreated}
                          update={insertMovementItemIntoCache({ orderId: id })}
                        >
                          {(createMovement, { loading: creatingMovement }) => {
                            function handleSaveNewMovement(values) {
                              createMovement({
                                variables: {
                                  ...values,
                                  orderId: id,
                                },
                              })
                            }

                            return (
                              <ResourceShowPage
                                additionalEditButton={
                                  movementsEditable
                                    ? LockMovementsButton
                                    : EditMovementsButton
                                }
                                canCreate={canCreate(userRole)}
                                canDelete={canDelete(userRole)}
                                canUpdate={canUpdate(userRole)}
                                deleteItemFromCache={deleteItemFromCache}
                                deleteMutation={DELETE_ORDER_MUTATION}
                                editResourcePath={editOrderPath}
                                errors={errors}
                                getResourceName={getResourceName}
                                handleAdditionalEdit={
                                  this.changeMovementsEditableState
                                }
                                id={id}
                                indexPageVariables={{
                                  orderTypeId: item.orderTypeId,
                                }}
                                initialValues={Order.initialValues({
                                  sourceContractor,
                                  targetContractor,
                                  reason,
                                })}
                                loading={loading}
                                newResourcePath={newOrderPath(permalink)}
                                pluralName={pluralName(name)}
                                resourceComponent={Order}
                                resources={{
                                  contractors,
                                  deleteMovement: handleDeleteMovement,
                                  editingMovementId,
                                  goodsStates,
                                  item,
                                  locations,
                                  movements,
                                  movementsEditable,
                                  newMovementId,
                                  orderType,
                                  products,
                                  productTypes,
                                  saveMovement: handleSaveMovement,
                                  saveNewMovement: handleSaveNewMovement,
                                  startEditingMovement: this
                                    .handleStartEditingMovement,
                                  stopEditingMovement: this
                                    .handleStopEditingMovement,
                                  waitingMovementId:
                                    deletingMovementId ||
                                    (updatingMovement && editingMovementId) ||
                                    undefined,
                                  waitingNewMovement: creatingMovement,
                                }}
                                resourcesPath={ordersPath(permalink)}
                                singleName={singleName(name)}
                                validationSchema={Order.validationSchema({
                                  sourceContractor,
                                  targetContractor,
                                  reason,
                                })}
                              />
                            )
                          }}
                        </Mutation>
                      )
                    }}
                  </Mutation>
                )
              }}
            </Mutation>
          )
        }}
      </Queries>
    )
  }
}
OrderShowPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  metaData: PropTypes.shape({
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        permalink: PropTypes.string,
        reason: PropTypes.bool,
      }),
    ),
  }),
  id: PropTypes.number,
}
export default compose(
  withResourceId,
  withAuthenticationContext,
  withMetaData,
  withRouter,
)(OrderShowPage)
