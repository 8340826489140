import React, { useState } from "react"
import PropTypes from "prop-types"

import { AutoSizer, DataGrid, Empty, ResourcePage } from "../../../ui"

import ExportToolbarButtons from "./ExportToolbarButtons"
import IndexToolbarButtons from "./IndexToolbarButtons"
import { loadState, saveState } from "./stateStorage"

// Have to render ag-grid outside the position: absolute element
// otherwise ag-grid wrongly calculates the position of filters' dialogs
export const RESOURCES_PAGE_DATA_GRID_DOM_ELEMENT_ID = "data-grid"
export const RESOURCES_PAGE_DATA_GRID_WRAPPER_DOM_ELEMENT_ID =
  "data-grid-wrapper"

const dataGridDomElement = document.getElementById(
  RESOURCES_PAGE_DATA_GRID_DOM_ELEMENT_ID,
)
const dataGridWrapperDomElement = document.getElementById(
  RESOURCES_PAGE_DATA_GRID_WRAPPER_DOM_ELEMENT_ID,
)

function ResourceItemsPage({
  canCreate,
  children,
  errors,
  handleBack,
  handleNew,
  handleOpen,
  loading,
  pluralName,
  singleName,
  resources,
  toolbar,
  uniqueName,
}) {
  const [exportFuncs, setExportFuncs] = useState({})

  function onOpen({ data }) {
    return handleOpen(data)
  }
  function dataGridReady({ exportToCsv }) {
    setExportFuncs({ exportToCsv })
  }
  function onExportToCsv() {
    exportFuncs.exportToCsv && exportFuncs.exportToCsv()
  }

  return (
    <ResourcePage
      navigation={[{ text: pluralName }]}
      errors={errors}
      loading={loading}
      toolbar={
        <IndexToolbarButtons
          canCreate={canCreate}
          handleBack={handleBack}
          handleNew={handleNew}
          itemName={singleName}
        />
      }
      customToolbar={toolbar}
      lastToolbar={<ExportToolbarButtons handleExportToCsv={onExportToCsv} />}
    >
      {!resources || resources.length === 0 ? (
        <Empty
          description={`Create a New ${singleName}`}
          onCreateClick={canCreate && handleNew}
        />
      ) : (
        <AutoSizer disableWidth>
          {({ height }) => {
            dataGridWrapperDomElement.style.height = height + "px"
            return (
              <DataGrid
                uniqueName={uniqueName}
                data={resources}
                loadState={loadState}
                saveState={saveState}
                onReady={dataGridReady}
                onRowDoubleClick={onOpen}
                domElement={dataGridDomElement}
              >
                {children}
              </DataGrid>
            )
          }}
        </AutoSizer>
      )}
    </ResourcePage>
  )
}
ResourceItemsPage.propTypes = {
  canCreate: PropTypes.bool,
  children: PropTypes.node,
  errors: PropTypes.array,
  handleBack: PropTypes.func,
  handleNew: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  pluralName: PropTypes.string.isRequired,
  singleName: PropTypes.string,
  resources: PropTypes.arrayOf(PropTypes.object),
  toolbar: PropTypes.node,
  uniqueName: PropTypes.string,
}
export default ResourceItemsPage
