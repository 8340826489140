import React from "react"
import PropTypes from "prop-types"

import { HTMLSelect } from "@blueprintjs/core"

import "./DropDownCell.css"

function DropDownCell({
  editing,
  name,
  onBlur,
  onChange,
  options,
  textValue,
  value,
  waiting,
}) {
  if (editing) {
    return (
      <HTMLSelect
        disabled={waiting}
        fill
        // intent={valid ? Intent.NONE : Intent.DANGER}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        value={value}
      />
    )
  } else {
    return textValue || null
  }
}
DropDownCell.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ),
  textValue: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  waiting: PropTypes.bool,
}
export default DropDownCell
