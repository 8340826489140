import { Role } from "../../auth"

export const canCreate = () => true
export const canUpdate = (role, user) =>
  user ? role === Role.ADMIN || user.role !== Role.ADMIN : false
export const canDelete = (role, user, currentUserId) =>
  user
    ? currentUserId !== user.id &&
      (role === Role.ADMIN || user.role !== Role.ADMIN)
    : false
export const canDisable = (role, user, currentUserId) =>
  user
    ? currentUserId !== user.id &&
      (role === Role.ADMIN || user.role !== Role.ADMIN)
    : false
