import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { ResourceEditPage, withResourceId } from "../../../resourcePage"
import { withMetaData } from "../../../metaData"

import {
  getContractorItem,
  getResourceName,
  CONTRACTOR_ITEM_QUERY,
  UPDATE_CONTRACTOR_MUTATION,
  updateItemInCache,
} from "../dataSource"
import { canUpdate } from "../accessRights"
import { pluralName, singleName } from "../metaInfo"
import { contractorPath, contractorsPath } from "../paths"
import Contractor from "./Contractor"

function ContractorEditPage({
  auth: { userRole },
  id,
  metaData: { orderTypes },
}) {
  const updateAllItemsInCacheFuncs = orderTypes.map(({ id }) =>
    updateItemInCache({ orderTypeId: id }),
  )
  function updateAllItemsInCache(cache, data) {
    updateAllItemsInCacheFuncs.forEach(f => f(cache, data))
  }

  return (
    <Query
      query={CONTRACTOR_ITEM_QUERY}
      variables={{ id }}
      skip={!canUpdate(userRole)}
    >
      {({ loading, errors, data }) => {
        const contractor = getContractorItem(data)

        if (!canUpdate(userRole)) {
          errors = ["Access denied."]
        } else if (!loading && !errors && !contractor) {
          errors = ["Not found."]
        }

        return (
          <ResourceEditPage
            errors={errors}
            id={id}
            getResourceName={getResourceName}
            loading={loading}
            pluralName={pluralName}
            resourceComponent={Contractor}
            resources={{ item: contractor }}
            resourcePath={contractorPath}
            resourcesPath={contractorsPath}
            singleName={singleName}
            updateMutation={UPDATE_CONTRACTOR_MUTATION}
            updateItemInCache={updateAllItemsInCache}
          />
        )
      }}
    </Query>
  )
}
ContractorEditPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
  metaData: PropTypes.shape({
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }),
}
export default compose(
  withMetaData,
  withResourceId,
  withAuthenticationContext,
)(ContractorEditPage)
