import React from "react"
import PropTypes from "prop-types"

import {
  Button,
  Classes,
  Menu,
  Popover,
  PopoverInteractionKind,
  Position,
} from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

const MenuItem = ({
  children,
  icon,
  intent,
  loading,
  title,
  showCaretDown,
}) => {
  const caretDown = showCaretDown ? { rightIcon: IconNames.CARET_DOWN } : {}
  return (
    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      position={Position.BOTTOM_LEFT}
      minimal
      hoverOpenDelay={10}
      hoverCloseDelay={10}
      autoFocus={false}
      enforceFocus={false}
    >
      <Button
        className={Classes.MINIMAL}
        icon={icon === false ? undefined : icon}
        intent={intent}
        loading={loading}
        {...caretDown}
      >
        {title}
      </Button>
      <Menu>{children}</Menu>
    </Popover>
  )
}
MenuItem.defaultProps = {
  showCaretDown: false,
}
MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.node.isRequired,
  showCaretDown: PropTypes.bool,
}

export default MenuItem
