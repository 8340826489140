import React from "react"
import PropTypes from "prop-types"

import { Menu, MenuItem } from "@blueprintjs/core"
import NoItemsFound from "./NoItemsFound"

function ItemListRenderer({ filteredItems, itemsParentRef, renderItem }) {
  return (
    <Menu ulRef={itemsParentRef}>
      {filteredItems.length > 100 ? (
        <MenuItem
          disabled={true}
          text={`Too many items found... Specify more precise query.`}
        />
      ) : filteredItems.length === 0 ? (
        <NoItemsFound />
      ) : (
        filteredItems.map(renderItem)
      )}
    </Menu>
  )
}
ItemListRenderer.propTypes = {
  filteredItems: PropTypes.arrayOf(PropTypes.object),
  itemsParentRef: PropTypes.object,
  renderItem: PropTypes.func,
}
export default ItemListRenderer
