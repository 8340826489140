import { cacheUpdate, gql } from "../../../state_management/DataProvider"

import { singleName } from "./metaInfo"

import { reject } from "../../../utils"

export const fields = `
  id 
  orderTypeId 
  name 
  externalName
  date 
  sourceContractorId 
  sourceContractorName 
  targetContractorId 
  targetContractorName 
  reason 
  remark 
  createdAt 
  createdBy 
  updatedAt 
  updatedBy
`
const inputParams = `
  $name: String
  $externalName: String
  $date: Date 
  $sourceContractorId: BigInt 
  $targetContractorId: BigInt 
  $reason: String 
  $remark: String
`
const inputFields = `
  name: $name 
  externalName: $externalName
  date: $date 
  sourceContractorId: $sourceContractorId 
  targetContractorId: $targetContractorId 
  reason: $reason 
  remark: $remark
`

export const ORDER_LIST_QUERY = gql`
  query Orders($orderTypeId: BigInt!) {
    allOrdersList(condition: {orderTypeId: $orderTypeId}) { ${fields} }
  }
`
export const ORDER_ITEM_QUERY = gql`
  query Order($id: BigInt!) {
    orderById(id: $id) { ${fields} }
 }
`

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($orderTypeId: BigInt, ${inputParams}) {
    createOrder(input: { orderTypeId: $orderTypeId, ${inputFields} }) {
      order { ${fields} }
    }
  }
`
export const UPDATE_ORDER_MUTATION = gql`
  mutation UpdateOrder($id: BigInt!, ${inputParams}) {
    updateOrder(input: { id: $id, ${inputFields} }) {
      order { ${fields} }
    }
  }
`
export const DELETE_ORDER_MUTATION = gql`
  mutation DeleteOrder($id: BigInt!) {
    deleteOrder(input: { id: $id }) {
      order {
        id
        name
      }
    }
  }
`
export const GENERATE_ORDER_NAME = gql`
  mutation GenerateOrderName($orderTypeId: BigInt!) {
    generateOrderName(input: { orderTypeId: $orderTypeId }) {
      string
    }
  }
`
export const getOrderList = data => (data || {}).allOrdersList || []
export const getOrderItem = data => (data || {}).orderById || {}
export const getCreatedOrderItem = data => data.createOrder.order
export const getDeletedOrderId = data => data.deleteOrder.order.id
export const getGeneratedOrderName = data =>
  (data && data.generateOrderName && data.generateOrderName.string) || ""
export const insertItemIntoCache = cacheUpdate(
  ORDER_LIST_QUERY,
  (cache, data) => ({
    allOrdersList: getOrderList(cache).concat([getCreatedOrderItem(data)]),
  }),
)
export const deleteItemFromCache = cacheUpdate(
  ORDER_LIST_QUERY,
  (cache, data) => {
    return {
      allOrdersList: reject(getOrderList(cache), {
        id: getDeletedOrderId(data),
      }),
    }
  },
)

export const getResourceName = resource =>
  (resource && resource.name) || `New ${singleName()}`

export const cacheRedirects = {
  orderById: (_, args, { getCacheKey }) =>
    getCacheKey({ __typename: "Order", id: args.id }),
}
