import React from "react"
import PropTypes from "prop-types"

import NotForPublicSale from "../../product/pages/NotForPublicSale"

function NotForPublicSaleCell({ value }) {
  return <NotForPublicSale value={value} />
}
NotForPublicSaleCell.propTypes = {
  value: PropTypes.bool,
}
export default NotForPublicSaleCell
