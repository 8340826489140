import React from "react"
import PropTypes from "prop-types"

import { Classes, Colors } from "@blueprintjs/core"

import { object, string } from "../utils/yup"

import { LoginButton } from "./Buttons"
import { TextInput } from "./inputs"
import Form from "./Form"
import Dialog from "./Dialog"

const VALIDATION_SCHEMA = object().shape({
  email: string()
    .email()
    .required(),
  password: string().required(),
})

function LoginForm({ failed, login, waiting }) {
  return (
    <Dialog
      closable={false}
      isOpen={true}
      title="The Wisdom Treasury Stock System"
    >
      <Form
        initialValues={{ email: "", password: "" }}
        onSubmit={login}
        validationSchema={VALIDATION_SCHEMA}
      >
        <Dialog.Body>
          <Form.GroupField
            component={TextInput}
            disabled={waiting}
            name="email"
            width="100%"
          />
          <Form.GroupField
            component={TextInput}
            disabled={waiting}
            name="password"
            width="100%"
            type="password"
          />
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Footer.Actions>
            {failed && (
              <p
                className={Classes.BUTTON_TEXT}
                style={{
                  alignSelf: "center",
                  color: Colors.RED1,
                  flexGrow: 1,
                  margin: 0,
                }}
              >
                Incorrect username or password.
              </p>
            )}
            <LoginButton type="submit" disabled={waiting} loading={waiting} />
          </Dialog.Footer.Actions>
        </Dialog.Footer>
      </Form>
    </Dialog>
  )
}
LoginForm.propTypes = {
  failed: PropTypes.bool,
  login: PropTypes.func,
  waiting: PropTypes.bool,
}

export default LoginForm
