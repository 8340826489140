import {
  resourcePathFor,
  resourcesPathFor,
  newResourcePathFor,
  editResourcePathFor,
} from "../../resourcePage"

export const productPath = resourcePathFor("products")
export const productsPath = resourcesPathFor("products")
export const newProductPath = newResourcePathFor("products")
export const editProductPath = editResourcePathFor("products")
