import {
  resourcePathFor,
  resourcesPathFor,
  newResourcePathFor,
  editResourcePathFor,
} from "../../resourcePage"

export const contractorPath = resourcePathFor("contractors")
export const contractorsPath = resourcesPathFor("contractors")
export const newContractorPath = newResourcePathFor("contractors")
export const editContractorPath = editResourcePathFor("contractors")
