import React from "react"
import PropTypes from "prop-types"

import { Column, DataType } from "../../../../ui"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import {
  CreatedAtColumn,
  CreatedByColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  IdColumn,
  ResourceIndexPage,
} from "../../../resourcePage"

import { canCreate } from "../accessRights"
import { getProductList, PRODUCT_LIST_QUERY } from "../dataSource"
import { productPath, newProductPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"

import NotForPublicSaleColumn from "./NotForPublicSaleColumn"

function ProductIndexPage({ auth: { userRole } }) {
  return (
    <Query query={PRODUCT_LIST_QUERY}>
      {({ loading, errors, data }) => {
        return (
          <ResourceIndexPage
            canCreate={canCreate(userRole)}
            uniqueName="product"
            errors={errors}
            loading={loading}
            newResourcePath={newProductPath}
            pluralName={pluralName}
            resources={{ items: getProductList(data) }}
            resourcePath={productPath}
            singleName={singleName}
          >
            <NotForPublicSaleColumn field="notForPublicSale" />
            <Column
              field="productTypeName"
              title="Type"
              width={100}
              type={DataType.DROP_DOWN}
            />
            <Column field="code" title="Number" width={150} />
            <Column field="name" width={300} />
            <Column field="isbn" title="ISBN" width={130} />
            <Column
              field="languageCode"
              title="Language"
              width={100}
              type={DataType.DROP_DOWN}
            />
            <Column
              field="productOwnersNames"
              title="Product Owners"
              type={DataType.DROP_DOWN}
            />
            <Column field="remark" width={500} />
            <UpdatedAtColumn />
            <UpdatedByColumn />
            <CreatedAtColumn />
            <CreatedByColumn />
            <IdColumn />
          </ResourceIndexPage>
        )
      }}
    </Query>
  )
}
ProductIndexPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string,
  }),
}
export default withAuthenticationContext(ProductIndexPage)
