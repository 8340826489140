import React from "react"
import PropTypes from "prop-types"

import { Checkbox, Intent } from "@blueprintjs/core"

function CheckboxInput({
  disabled,
  editing,
  large,
  name,
  onBlur,
  onChange,
  style,
  valid,
  value,
}) {
  function handleChange({ target: { checked } }) {
    onChange({ target: { name, value: checked } })
  }

  return (
    <Checkbox
      checked={value}
      disabled={!editing || disabled}
      intent={valid ? Intent.NONE : Intent.DANGER}
      large={large}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      style={style}
    />
  )
}
CheckboxInput.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.object,
  valid: PropTypes.bool,
  value: PropTypes.bool,
}
export default CheckboxInput
