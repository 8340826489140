import React from "react"

import { CardField, TextAreaInput } from "../../../ui"

function RemarkField() {
  return (
    <CardField
      component={TextAreaInput}
      name="remark"
      omitIfEmpty
      fullWidth
      rows={5}
    />
  )
}
export { RemarkField }
