import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../utils"

import { Error, Loading } from "../../ui"

import { withAuthenticationContext } from "../../state_management/Authentication"
import { withMetaData } from "../metaData"

import { Login } from "../auth"
import AppRouter from "../routing/AppRouter"

function Content({
  auth: {
    errors,
    loading,
    userRole,
    refresh,
    client: { resetStore },
  },
  metaDataErrors,
  metaDataLoading,
}) {
  if (errors || metaDataErrors)
    return <Error title="Application Error" errors={errors || metaDataErrors} />
  if (loading || metaDataLoading) return <Loading />
  if (!userRole) return <Login onSuccess={refresh} />
  return <AppRouter onLogout={() => resetStore()} />
}
Content.propTypes = {
  auth: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.string),
    loading: PropTypes.bool,
    userRole: PropTypes.string,
    refresh: PropTypes.func,
    client: PropTypes.shape({
      resetStore: PropTypes.func,
    }),
  }),
  metaDataLoading: PropTypes.bool,
  metaDataErrors: PropTypes.arrayOf(PropTypes.string),
}
export default compose(
  withAuthenticationContext,
  withMetaData,
)(Content)
