import { cacheUpdate, gql } from "../../../state_management/DataProvider"

import { singleName } from "./metaInfo"

import { reject } from "../../../utils"

export const fields = `
      id
      active
      role
      name
      email
      phone
      remark
      failedLoginAttempts
      lastFailedLoginAttemptAt
      createdAt
      createdBy
      updatedAt
      updatedBy
      `

const inputParams =
  "$role: Role, $name: String!, $email: String!, $phone: String, $remark: String"
const inputFields =
  "role: $role, name: $name, email: $email, phone: $phone, remark: $remark"

export const USER_LIST_QUERY = gql`
  query Users {
    allUsersList { ${fields} }
  }
`
export const USER_ITEM_QUERY = gql`
  query User($id: BigInt!) {
    userById(id: $id) { ${fields} }
  }
`

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($active: Boolean, ${inputParams}, $password: String) {
    createUser(input: {active: $active, ${inputFields}, password: $password}) {
      user { ${fields} }
    }
  }
`
export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: BigInt!, ${inputParams}) {
    updateUser(input: { id: $id, ${inputFields} }) {
      user { ${fields} }
    }
  }
`
export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: BigInt!) {
    deleteUser(input: { id: $id }) {
      user {
        id
        name
      }
    }
  }
`
export const ENABLE_DISABLE_USER_MUTATION = gql`
  mutation EnableDisableUser($id: BigInt!, $enable: Boolean) {
    enableUser(input: { id: $id, enable: $enable }) {
      user { ${fields} }
    }
  }
`
export const CHANGE_USER_PASSWORD_MUTATION = gql`
  mutation ChangeUserPassword(
    $currentUserPassword: String!
    $id: BigInt!
    $newPassword: String!
  ) {
    changePassword(
      input: {
        currentUserPassword: $currentUserPassword
        id: $id
        newPassword: $newPassword
      }
    ) {
      boolean
    }
  }
`
export const getUserList = data => (data || {}).allUsersList || []
export const getUserItem = data => (data || {}).userById

export const getCreatedUserItem = data => data.createUser.user
export const getDeletedUserId = data => data.deleteUser.user.id

export const insertItemIntoCache = cacheUpdate(
  USER_LIST_QUERY,
  (cache, data) => ({
    allUsersList: getUserList(cache).concat([getCreatedUserItem(data)]),
  }),
)
export const deleteItemFromCache = cacheUpdate(
  USER_LIST_QUERY,
  (cache, data) => ({
    allUsersList: reject(getUserList(cache), {
      id: getDeletedUserId(data),
    }),
  }),
)

export const getResourceName = resource =>
  (resource && resource.name) || `New ${singleName}`

export const cacheRedirects = {
  userById: (_, args, { getCacheKey }) =>
    getCacheKey({ __typename: "User", id: args.id }),
}
