import React from "react"
import PropTypes from "prop-types"

import { withRenderer } from "../../../../ui"
import RoleTag from "../ui/RoleTag"

function UserRoleCell({ value, dependencies: { active } }) {
  return <RoleTag active={active} role={value} />
}
UserRoleCell.propTypes = {
  dependencies: PropTypes.shape({
    active: PropTypes.bool,
  }),
  value: PropTypes.string,
}
export default withRenderer(UserRoleCell, ["active"])
