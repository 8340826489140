import React from "react"
import PropTypes from "prop-types"

import { boolean, object, ref, string } from "../../../../utils/yup"

import { fromPairs, startCase, values } from "../../../../utils"
import { Card, SwitchInput, DropDownTagInput, TextInput } from "../../../../ui"

import { Role } from "../../../auth"

import roleTagColors from "../ui/roleTagColors"

import { RemarkField } from "../../../resourcePage"

const roles = fromPairs(
  [false, true].map(active => [
    active,
    values(Role).map(role => ({
      label: startCase(role),
      value: role,
      ...roleTagColors({ active, role }),
    })),
  ]),
)

function User({ newPage, resources: { item } }) {
  return (
    <Card>
      {newPage && <Card.Field component={SwitchInput} name="active" />}
      <Card.Field
        component={DropDownTagInput}
        name="role"
        options={roles[(item && item.active) || false]}
        round
        tagStyle={{ marginTop: 5 }}
        width={100}
      />
      <Card.Field component={TextInput} name="name" width={500} />
      <Card.Field component={TextInput} name="email" width={500} type="email" />
      <Card.Field component={TextInput} name="phone" width={500} />
      {newPage && (
        <React.Fragment>
          <Card.Field
            component={TextInput}
            name="password"
            width={300}
            type="password"
          />
          <Card.Field
            component={TextInput}
            name="passwordConfirmation"
            width={300}
            type="password"
            label="Confirmation:"
          />
        </React.Fragment>
      )}
      <RemarkField />
    </Card>
  )
}
User.NEW_USER_VALIDATION_SCHEMA = object().shape({
  active: boolean().required(),
  role: string().oneOf(values(Role)),
  name: string().required(),
  email: string()
    .email()
    .required(),
  password: string()
    .required()
    .min(15),
  passwordConfirmation: string().sameAs(
    ref("password"),
    "confirmation is different from password",
  ),
  phone: string(),
  remark: string(),
})
User.VALIDATION_SCHEMA = object().shape({
  role: string().oneOf(values(Role)),
  name: string().required(),
  email: string()
    .email()
    .required(),
  phone: string(),
  remark: string(),
})
User.newUserInitialValues = () => ({
  active: true,
  role: Role.READER,
  name: "",
  email: "",
  password: "",
  passwordConfirmation: "",
  phone: "",
  remark: "",
})
User.initialValues = ({ email, name, phone, remark, role }) => ({
  role,
  name,
  email,
  phone: phone || "",
  remark: remark || "",
})
User.propTypes = {
  newPage: PropTypes.bool,
  editPage: PropTypes.bool,
  resources: PropTypes.object,
}
export default User
