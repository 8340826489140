import {
  lowerCase,
  padStart,
  startCase as buggyStartCase,
  trim,
} from "lodash/string"
import { keys, mapValues, omit, pick, values } from "lodash/object"
import { compact, concat, fromPairs } from "lodash/array"
import { filter, find, reject, sortBy } from "lodash/collection"
import { flowRight } from "lodash/util"

function startCase(value) {
  return buggyStartCase(lowerCase(value))
}

export {
  compact,
  concat,
  flowRight,
  filter,
  find,
  fromPairs,
  keys,
  lowerCase,
  mapValues,
  omit,
  padStart,
  pick,
  reject,
  sortBy,
  startCase,
  trim,
  values,
}
