import React from "react"
import PropTypes from "prop-types"

import { Menu } from "@blueprintjs/core"
import MenuItem from "./MenuItem"

const AppMenu = ({ children, ...rest }) => <Menu {...rest}>{children}</Menu>

AppMenu.Item = MenuItem
AppMenu.Divider = Menu.Divider
AppMenu.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppMenu
