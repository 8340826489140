import { gql } from "../../../state_management/DataProvider"

const fields = `
  productTypeId,
  productTypeName,
  productId,
  productCode,
  productName,
  languageId,
  languageName,
  productNotForPublicSale,
  goodsStateId,
  goodsStateName,
  contractorId,
  contractorName,
  quantity,
`

export const AGGREGATED_STOCK_ITEM_LIST_QUERY = gql`
  query AggregatedStockItems($stockId: BigInt!, $date: Date!) {
    aggregatedStockOnDateList(stockId: $stockId, date: $date) { ${fields} }
  }
`
export const getAggregatedStockItemList = data =>
  (data || {}).aggregatedStockOnDateList || []

export const STOCK_ITEM_LIST_QUERY = gql`
  query StockItems($stockId: BigInt!, $date: Date!) {
    stockOnDateList(stockId: $stockId, date: $date) { ${fields} locationId, locationName  }
  }
`
export const getStockItemList = data => (data || {}).stockOnDateList || []
