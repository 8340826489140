import { cacheUpdate, gql } from "../../../state_management/DataProvider"

import { singleName } from "./metaInfo"

import { reject } from "../../../utils"

import { PRODUCT_LIST_QUERY } from "../product/dataSource"

export const fields =
  "id, name, codeIsMandatory, remark, createdAt, createdBy, updatedAt, updatedBy"

const inputParams = "$name: String, $codeIsMandatory: Boolean, $remark: String"
const inputFields =
  "name: $name, codeIsMandatory: $codeIsMandatory, remark: $remark"

export const PRODUCT_TYPE_LIST_QUERY = gql`
  query ProductTypes {
    allProductTypesList { ${fields} }
  }
`
export const PRODUCT_TYPE_ITEM_QUERY = gql`
  query ProductType($id: BigInt!) {
    productTypeById(id: $id) { ${fields} }
  }
`
export const CREATE_PRODUCT_TYPE_MUTATION = gql`
  mutation CreateProductType(${inputParams}) {
    createProductType(input: { ${inputFields} }) {
      productType { ${fields} }
    }
  }
`
export const UPDATE_PRODUCT_TYPE_MUTATION = gql`
  mutation UpdateProductType($id: BigInt!, ${inputParams}) {
    updateProductType(input: { id: $id, ${inputFields} }) {
      productType { ${fields} }
    }
  }
`
export const DELETE_PRODUCT_TYPE_MUTATION = gql`
  mutation DeleteProductType($id: BigInt!) {
    deleteProductType(input: { id: $id }) {
      productType {
        id
        name
      }
    }
  }
`
export const getProductTypeList = data => (data || {}).allProductTypesList || []
export const getProductTypeItem = data => (data || {}).productTypeById

export const getCreatedProductTypeItem = data =>
  data.createProductType.productType
export const getDeletedProductTypeId = data =>
  data.deleteProductType.productType.id

export const insertItemIntoCache = cacheUpdate(
  PRODUCT_TYPE_LIST_QUERY,
  (cache, data) => ({
    allProductTypesList: getProductTypeList(cache).concat([
      getCreatedProductTypeItem(data),
    ]),
  }),
)
export const updateItemInCache = cacheUpdate(
  PRODUCT_LIST_QUERY,
  (cache, { updateProductType: { productType } }) => ({
    allProductsList: cache.allProductsList.map(product =>
      product.productTypeId === productType.id
        ? { ...product, productTypeName: productType.name }
        : product,
    ),
  }),
)
export const deleteItemFromCache = cacheUpdate(
  PRODUCT_TYPE_LIST_QUERY,
  (cache, data) => ({
    allProductTypesList: reject(getProductTypeList(cache), {
      id: getDeletedProductTypeId(data),
    }),
  }),
)

export const getResourceName = resource =>
  (resource && resource.name) || `New ${singleName}`

export const cacheRedirects = {
  productTypeById: (_, args, { getCacheKey }) =>
    getCacheKey({ __typename: "ProductType", id: args.id }),
}
