import React from "react"
import PropTypes from "prop-types"

import { getIn } from "../../utils"

import labelFromName from "./labelFromName"
import withForm from "./withForm"

function FormField({
  component: Component,
  disabled,
  form: {
    editing,
    errors,
    isSubmitting,
    onChange,
    onBlur,
    touched,
    values,
    ...restForm
  },
  fullWidthComponent,
  large,
  name,
  omitIfEmpty,
  width,
  ...rest
}) {
  const valid = !(getIn(touched, name) && getIn(errors, name))
  const value = getIn(values, name)

  return (
    ((value || editing || !omitIfEmpty) && (
      <Component
        disabled={disabled || isSubmitting}
        editing={editing}
        large={large}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        valid={valid}
        value={getIn(values, name)}
        width={width || (fullWidthComponent && "100%") || undefined}
        {...rest}
        form={{
          editing,
          errors,
          isSubmitting,
          onChange,
          onBlur,
          touched,
          values,
          ...restForm,
        }}
      />
    )) ||
    null
  )
}
FormField.propTypes = {
  component: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  form: PropTypes.shape({
    editing: PropTypes.bool,
    errors: PropTypes.objectOf(PropTypes.string),
    isSubmitting: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.bool),
    values: PropTypes.object,
  }),
  large: PropTypes.bool,
  name: PropTypes.string,
  omitOfEmpty: PropTypes.bool,
}
export { labelFromName }
export default withForm(FormField)
