export default function({ orderTypes, stockId }) {
  const items = []
  for (let i = 0; i < orderTypes.length; i++) {
    if (
      orderTypes[i].sourceStockId === stockId ||
      orderTypes[i].targetStockId === stockId
    ) {
      items.push({ ...orderTypes[i] })
    } else if (orderTypes[i].followedBySeparator && items.length) {
      items[items.length - 1].followedBySeparator = true
    }
  }
  items[items.length - 1].followedBySeparator = false

  return items
}
