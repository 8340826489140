const DATE = "date"
const DATE_TIME = "dateTime"
const DEFAULT_ACTIONS = "defaultActions"
const DROP_DOWN = "dropDown"
const ID = "id"
const INTEGER = "integer"
const SELECT = "select"
const TEXT = "text"

export {
  DATE,
  DATE_TIME,
  DEFAULT_ACTIONS,
  DROP_DOWN,
  ID,
  INTEGER,
  SELECT,
  TEXT,
}
