import React from "react"
import PropTypes from "prop-types"

import { CancelButton, ChangePasswordButton } from "./Buttons"
import Dialog from "./Dialog"
import Form from "./Form"
import { TextInput } from "./inputs"

function ChangePasswordDialog({
  changePassword,
  show,
  onClose,
  validationSchema,
  waiting,
}) {
  function handleChangePassword({
    currentUserPassword,
    newPassword,
    confirmation,
  }) {
    if (newPassword === confirmation) {
      changePassword({ currentUserPassword, newPassword })
    }
  }

  return (
    <Dialog closable isOpen={show} onClose={onClose} title="Change Password">
      <Form
        initialValues={{
          currentUserPassword: "",
          newPassword: "",
          confirmation: "",
        }}
        onSubmit={handleChangePassword}
        validationSchema={validationSchema}
      >
        <Dialog.Body>
          <Form.GroupField
            component={TextInput}
            disabled={waiting}
            label="Your Current Password:"
            name="currentUserPassword"
            width="100%"
            type="password"
          />
          <Form.GroupField
            component={TextInput}
            disabled={waiting}
            name="newPassword"
            width="100%"
            type="password"
          />
          <Form.GroupField
            component={TextInput}
            disabled={waiting}
            name="confirmation"
            width="100%"
            type="password"
          />
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Footer.Actions>
            <ChangePasswordButton
              type="submit"
              disabled={waiting}
              loading={waiting}
            />
            <CancelButton
              disabled={waiting}
              loading={waiting}
              onClick={onClose}
            />
          </Dialog.Footer.Actions>
        </Dialog.Footer>
      </Form>
    </Dialog>
  )
}
ChangePasswordDialog.propTypes = {
  changePassword: PropTypes.func,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  validationSchema: PropTypes.object,
  waiting: PropTypes.bool,
}
export default ChangePasswordDialog
