import {
  resourcePathFor,
  resourcesPathFor,
  newResourcePathFor,
  editResourcePathFor,
} from "../../resourcePage"

export const languagePath = resourcePathFor("languages")
export const languagesPath = resourcesPathFor("languages")
export const newLanguagePath = newResourcePathFor("languages")
export const editLanguagePath = editResourcePathFor("languages")
