import { padStart } from "./lodash"

const formatDateISO = date =>
  date &&
  [
    date.getFullYear(),
    padStart(date.getMonth() + 1, 2, "0"),
    padStart(date.getDate(), 2, "0"),
  ].join("-")

const formatDate = date =>
  date &&
  [
    padStart(date.getDate(), 2, "0"),
    padStart(date.getMonth() + 1, 2, "0"),
    date.getFullYear(),
  ].join(".")

const formatTime = time =>
  time &&
  [
    padStart(time.getHours(), 2, "0"),
    padStart(time.getMinutes(), 2, "0"),
    padStart(time.getSeconds(), 2, "0"),
  ].join(":")

const formatDateTime = dateTime =>
  dateTime ? `${formatDate(dateTime)} ${formatTime(dateTime)}` : null

const formatNumber = int => (int ? (+int).toLocaleString() : null)

export { formatDate, formatDateISO, formatDateTime, formatNumber, formatTime }
