import React from "react"

import { Menu, Toolbar } from "../../ui"

import { contractorsPath } from "../domains/contractor/paths"
import { goodsStatesPath } from "../domains/goodsState/paths"
import { languagesPath } from "../domains/language/paths"
import { locationsPath } from "../domains/location/paths"
import { productsPath } from "../domains/product/paths"
import { productOwnersPath } from "../domains/productOwner/paths"
import { productTypesPath } from "../domains/productType/paths"
import { usersPath } from "../domains/user/paths"

const DictionariesMenu = () =>
  // prettier-ignore
  <Toolbar.MenuItem title="Dictionaries">
    <Menu.Item title="Contractors" href={contractorsPath()} />
    <Menu.Divider />
    <Menu.Item title="Products" href={productsPath()} />
    <Menu.Item title="Product Owners" href={productOwnersPath()} />
    <Menu.Divider />
    <Menu.Item title="Product Types" href={productTypesPath()} />
    <Menu.Item title="Languages" href={languagesPath()} />
    <Menu.Item title="Goods States" href={goodsStatesPath()} />
    <Menu.Divider />
    <Menu.Item title="Locations" href={locationsPath()} />
    <Menu.Divider />
    <Menu.Item title="Users" href={usersPath()} />
  </Toolbar.MenuItem>

export default DictionariesMenu
