import React from "react"
import PropTypes from "prop-types"

import CardColumn from "./CardColumn"
import CardField from "./CardField"
import CardTable from "./CardTable"

import "./Card.css"

const Card = ({ children }) => <div className="item-card">{children}</div>
Card.propTypes = {
  children: PropTypes.node,
}
Card.Column = CardColumn
Card.Field = CardField
Card.Table = CardTable
export default Card
