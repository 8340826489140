import React from "react"
import PropTypes from "prop-types"

import { object, string } from "../../../../utils/yup"

import { Card, TextInput } from "../../../../ui"

import { RemarkField } from "../../../resourcePage"

function Contractor() {
  return (
    <Card>
      <Card.Field component={TextInput} name="name" width={300} />
      <Card.Field component={TextInput} name="phone" width={500} />
      <Card.Field component={TextInput} name="email" type="email" width={500} />
      <RemarkField />
    </Card>
  )
}
Contractor.VALIDATION_SCHEMA = object().shape({
  name: string().required(),
  phone: string(),
  email: string(),
  remark: string(),
})
Contractor.initialValues = ({ name, phone, email, remark }) => ({
  name: name || "",
  phone: phone || "",
  email: email || "",
  remark: remark || "",
})
Contractor.propTypes = {
  newPage: PropTypes.bool,
  editPage: PropTypes.bool,
}
export default Contractor
