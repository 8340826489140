import cacheUpdate from "./cacheUpdate"
import DataProvider from "./DataProvider"
import extractErrors from "./extractErrors"
import gql from "graphql-tag"
import graphql from "./graphqlWithErrorHandler"
import Mutation from "./Mutation"
import OptionalQuery from "./OptionalQuery"
import Query from "./Query"
import Queries from "./Queries"

export {
  cacheUpdate,
  extractErrors,
  graphql,
  gql,
  Mutation,
  OptionalQuery,
  Query,
  Queries,
}
export default DataProvider
