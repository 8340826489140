// prettier-ignore
export default message =>
  ({
    // Contractor
    'update or delete on table "contractor" violates foreign key constraint "stock_item_contractor_id_fkey" on table "stock_item"':
      "Can't delete because there are Orders with this Contractor.",
    'duplicate key value violates unique constraint "contractor_name_key"':
      "This Name is already in use.",
    // Goods State
    'update or delete on table "goods_state" violates foreign key constraint "stock_item_goods_state_id_fkey" on table "stock_item"':
      "Can't delete because there are Goods Movements with this Goods State.",
    'duplicate key value violates unique constraint "goods_state_name_key"':
      "This Name is already in use.",
    // Language
    'update or delete on table "language" violates foreign key constraint "product_language_id_fkey" on table "product"':
      "Can't delete because there are Products with this Language.",
    'duplicate key value violates unique constraint "language_code_key"':
      "This Code is already in use.",
    'duplicate key value violates unique constraint "language_name_key"':
      "This Name is already in use.",
    // Location
    'duplicate key value violates unique constraint "location_stock_id_name_key"':
      "This Name is already in use for this Stock.",
    // Movement
    'duplicate key value violates unique constraint "movement_order_id_source_stock_item_id_target_stock_item_id_key"':
      "This product movement is already exists in this Order.",
    // Order
    'duplicate key value violates unique constraint "order_name_key"':
      "This No. is already in use.",
    // Product
    'update or delete on table "product" violates foreign key constraint "stock_item_product_id_fkey" on table "stock_item"':
      "Can't delete because there are Goods Movements with this Product.",
    'duplicate key value violates unique constraint "product_code_key"':
      "This Code is already in use.",
    'duplicate key value violates unique constraint "product_product_type_id_name_key"':
      "This combination of Product Type and Name is already in use.",
    'duplicate key value violates unique constraint "product_product_type_id_name_language_id_key"':
      "This combination of Product Type, Name and Language is already in use.",
    // Product Owner
    'update or delete on table "product_owner" violates foreign key constraint "product_product_owner_product_owner_id_fkey" on table "product_product_owner"':
      "Can't delete because there are Products with this Product Owner.",
    'duplicate key value violates unique constraint "product_owner_name_key"':
      "This Name is already in use.",
    // Product Type
    'update or delete on table "product_type" violates foreign key constraint "product_product_type_id_fkey" on table "product"':
      "Can't delete because there are Products with this Product Type.",
    'duplicate key value violates unique constraint "product_type_name_key"':
      "This Name is already in use.",
    // User
    'duplicate key value violates unique constraint "user_name_key"':
      "This Name is already in use.",
    'duplicate key value violates unique constraint "user_email_key"':
      "This Email is already in use.",
  }[message])
