import React from "react"
import PropTypes from "prop-types"

import Query from "./Query"

function Queries({ children, loading, errors, queries, resources, ...rest }) {
  if (queries.length) {
    const { getResource, resourceName, ...queryAttrs } = queries[
      queries.length - 1
    ]
    const otherQueries = queries.slice(0, -1)
    return (
      <Query {...rest} {...queryAttrs}>
        {({ loading: currentLoading, errors: currentErrors, data }) => (
          <Queries
            {...rest}
            loading={loading || currentLoading}
            errors={errors.concat(currentErrors || [])}
            queries={otherQueries}
            resources={{ ...resources, [resourceName]: getResource(data) }}
          >
            {children}
          </Queries>
        )}
      </Query>
    )
  } else {
    const params = { loading, resources }
    if (errors.length) params.errors = errors

    return children(params)
  }
}
Queries.defaultProps = {
  errors: [],
  resources: {},
}
Queries.propTypes = {
  children: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  queries: PropTypes.arrayOf(
    PropTypes.shape({
      getResource: PropTypes.func,
      query: PropTypes.object,
    }),
  ),
  resources: PropTypes.object,
}
export default Queries
