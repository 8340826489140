import React from "react"
import PropTypes from "prop-types"

import { Tag } from "@blueprintjs/core"

const ColoredTag = ({ backColor, children, textColor, style, ...rest }) => (
  <Tag
    style={{ ...style, backgroundColor: backColor, color: textColor }}
    {...rest}
  >
    {children}
  </Tag>
)
ColoredTag.propTypes = {
  backColor: PropTypes.string,
  textColor: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
}
export default ColoredTag
