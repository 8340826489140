import React from "react"
import PropTypes from "prop-types"

import { compose, find } from "../../../../utils"
import { CallOnMount } from "../../../../ui"
import { Query, Mutation } from "../../../../state_management/DataProvider"
import { withAuthenticationContext } from "../../../../state_management/Authentication"

import { ResourceNewPage } from "../../../resourcePage"
import { withMetaData } from "../../../metaData"

import withPermalink from "../../../routing/withPermalink"

import {
  CONTRACTOR_LIST_QUERY,
  getContractorList,
} from "../../contractor/dataSource"
import {
  CREATE_ORDER_MUTATION,
  GENERATE_ORDER_NAME,
  getCreatedOrderItem,
  getGeneratedOrderName,
  getResourceName,
  insertItemIntoCache,
} from "../dataSource"
import { canCreate } from "../accessRights"
import { orderPath as generalOrderPath, ordersPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"
import Order from "./Order"

function orderPath(id) {
  // Used in OrderShowPage
  return `${generalOrderPath(id)}?editMovements=1`
}

function OrderNewPage({
  auth: { userRole },
  metaData: { orderTypes },
  permalink,
}) {
  const orderType = find(orderTypes, { permalink })
  const { id: orderTypeId, name, sourceContractor, targetContractor, reason } =
    orderType || {}

  return (
    <Mutation mutation={GENERATE_ORDER_NAME} variables={{ orderTypeId }}>
      {(
        generateOrderName,
        { loading: generatingOrderName, data: generatedOrderNameData },
      ) => {
        const defaultName = getGeneratedOrderName(generatedOrderNameData)
        return (
          <CallOnMount callee={generateOrderName}>
            <Query query={CONTRACTOR_LIST_QUERY}>
              {({ loading, errors, data }) => {
                const contractors = getContractorList(data)

                return (
                  <ResourceNewPage
                    createMutation={CREATE_ORDER_MUTATION}
                    errors={
                      errors || !orderType
                        ? ["Not found"]
                        : canCreate(userRole)
                          ? undefined
                          : ["Access denied."]
                    }
                    getResourceFromCreateMutation={getCreatedOrderItem}
                    getResourceName={getResourceName}
                    indexPageVariables={{ orderTypeId }}
                    initialValues={Order.initialValues({
                      defaultName,
                      orderTypeId,
                      sourceContractor,
                      targetContractor,
                      reason,
                    })}
                    insertItemIntoCache={insertItemIntoCache}
                    loading={loading || generatingOrderName}
                    pluralName={pluralName(name)}
                    redirectToCreatedResource={true}
                    resourceComponent={Order}
                    resourcePath={orderPath}
                    resources={{ contractors, orderType }}
                    resourcesPath={ordersPath(permalink)}
                    singleName={singleName(name)}
                    validationSchema={Order.validationSchema({
                      sourceContractor,
                      targetContractor,
                      reason,
                    })}
                  />
                )
              }}
            </Query>
          </CallOnMount>
        )
      }}
    </Mutation>
  )
}
OrderNewPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  metaData: PropTypes.shape({
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        reason: PropTypes.bool,
      }),
    ),
  }),
  permalink: PropTypes.string.isRequired,
}
export default compose(
  withAuthenticationContext,
  withPermalink,
  withMetaData,
)(OrderNewPage)
