import React from "react"
import PropTypes from "prop-types"

import { NavLink } from "../../state_management/Router"
import { Classes } from "@blueprintjs/core"

const MenuItem = ({ title, href }) => (
  <li>
    <NavLink
      to={href}
      className={`${Classes.MENU_ITEM} ${Classes.POPOVER_DISMISS}`}
    >
      {title}
    </NavLink>
  </li>
)

MenuItem.propTypes = {
  title: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

export default MenuItem
