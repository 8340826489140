import React from "react"

import { withRouter } from "react-router-dom"
import { withFlash } from "../../ui"

function withRedirect(WrappedComponent) {
  function WithRedirect({ flash, history, location, children, ...rest }) {
    function redirect({ to, backTo, withReferrer, state, flash: flashMsg }) {
      if (backTo) {
        history.push((location.state && location.state.referrer) || backTo)
      } else {
        history.push(
          to,
          withReferrer ? { referrer: location, ...state } : state,
        )
      }
      flashMsg &&
        flashMsg.success &&
        flash.success({ timeout: 1500, ...flashMsg.success })
      flashMsg && flashMsg.warning && flash.warning(flashMsg.warning)
      flashMsg && flashMsg.alert && flash.alert(flashMsg.alert)
    }
    return (
      <WrappedComponent router={{ redirect, state: location.state }} {...rest}>
        {children}
      </WrappedComponent>
    )
  }
  WithRedirect.displayName = `WithRedirect(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`
  return withRouter(withFlash(WithRedirect))
}
export default withRedirect
