import React from "react"
import PropTypes from "prop-types"

import { Provider } from "./Context"
import { Query } from "react-apollo"
import isUnauthorized from "../DataProvider/isUnathorized"
import extractErrors from "../DataProvider/extractErrors"

const AuthenticationProvider = ({ children, getCurrentUserQuery }) => (
  <Query query={getCurrentUserQuery}>
    {({ loading, error, data, refetch, client }) => {
      if (isUnauthorized(error)) {
        refetch()
      }

      return (
        <Provider
          value={{
            errors: (error && extractErrors(error)) || undefined,
            loading,
            refresh: refetch,
            client,
            userId: data && data.getCurrentUser && data.getCurrentUser.id,
            userRole: data && data.getCurrentUser && data.getCurrentUser.role,
            userName: data && data.getCurrentUser && data.getCurrentUser.name,
          }}
        >
          {children}
        </Provider>
      )
    }}
  </Query>
)

AuthenticationProvider.propTypes = {
  children: PropTypes.node,
  getCurrentUserQuery: PropTypes.any,
}

export default AuthenticationProvider
