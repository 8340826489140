import React from "react"
import PropTypes from "prop-types"

import { ErrorIcon, Form, ResourcePage, Spinner } from "../../../ui"

import ResourceFingreprint from "../resource/ResourceFingerprint"
import FormToolbarButtons from "./FormToolbarButtons"
import ItemToolbarButtons from "./ItemToolbarButtons"

import "./ResourceItemPage.css"

function ResourceItemPage({
  actionToolbar: ActionToolbar,
  additionalEditButton,
  canCreate,
  canDelete,
  canUpdate,
  children,
  editing,
  errors,
  getResourceName,
  handleActions,
  handleAdditionalEdit,
  handleBack,
  handleCancel,
  handleDelete,
  handleEdit,
  handleNew,
  handleSave,
  initialValues,
  loading,
  pluralName,
  resource,
  resourcesPath,
  singleName,
  toolbar,
  validationSchema,
  waiting,
}) {
  return (
    <Form
      key={`${singleName}:${
        resource ? `${resource.id}:${resource.createdAt}` : "new"
      }:${loading}`}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSave}
      onReset={handleCancel}
      editing={editing}
    >
      <ResourcePage
        navigation={[
          { text: pluralName, href: resourcesPath() },
          errors
            ? { icon: <ErrorIcon size={16} /> }
            : loading
            ? { icon: <Spinner size={16} /> }
            : { text: getResourceName(resource) },
        ]}
        errors={errors}
        loading={loading}
        toolbar={
          <React.Fragment>
            {editing && <FormToolbarButtons handleCancel={handleCancel} />}
            {!editing && (
              <ItemToolbarButtons
                actionToolbar={
                  ActionToolbar && (
                    <ActionToolbar
                      handleActions={handleActions}
                      resource={resource}
                      waiting={waiting}
                    />
                  )
                }
                additionalEditButton={additionalEditButton}
                canCreate={canCreate}
                canUpdate={canUpdate}
                canDelete={canDelete}
                deleteConfirmationMessage={`${singleName} ${getResourceName(
                  resource,
                )}`}
                handleAdditionalEdit={handleAdditionalEdit}
                handleBack={handleBack}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleNew={handleNew}
                waiting={waiting}
              />
            )}
            {toolbar}
            {resource && <ResourceFingreprint resource={resource} />}
          </React.Fragment>
        }
      >
        {children}
      </ResourcePage>
    </Form>
  )
}
ResourceItemPage.defaultProps = {
  editing: false,
}
ResourceItemPage.propTypes = {
  actionToolbar: PropTypes.oneOfType([
    PropTypes.oneOf([false]),
    PropTypes.func,
  ]),
  additionalEditButton: PropTypes.func,
  canDelete: PropTypes.bool,
  canCreate: PropTypes.bool,
  canUpdate: PropTypes.bool,
  children: PropTypes.node.isRequired,
  editing: PropTypes.bool,
  errors: PropTypes.array,
  getResourceName: PropTypes.func.isRequired,
  handleActions: PropTypes.object,
  handleAdditionalEdit: PropTypes.func,
  handleBack: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleNew: PropTypes.func,
  handleSave: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  pluralName: PropTypes.string.isRequired,
  resourceNameField: PropTypes.string,
  resourcesPath: PropTypes.func.isRequired,
  resource: PropTypes.object,
  singleName: PropTypes.string.isRequired,
  toolbar: PropTypes.node,
  validationSchema: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  waiting: PropTypes.bool,
}
export default ResourceItemPage
