import React from "react"
import PropTypes from "prop-types"

import "./Fingerprint.css"

const Fingerprint = ({ createdAt, createdBy, updatedAt, updatedBy }) => (
  <table className="Fingerprint">
    <tbody>
      <tr>
        <td>Created At:</td>
        <th>{createdAt}</th>
        <td>By:</td>
        <th>{createdBy}</th>
      </tr>
      <tr>
        <td>Updated At:</td>
        <th>{updatedAt}</th>
        <td>By:</td>
        <th>{updatedBy}</th>
      </tr>
    </tbody>
  </table>
)

Fingerprint.propTypes = {
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
}
export default Fingerprint
