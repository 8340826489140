import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { ResourceShowPage, withResourceId } from "../../../resourcePage"

import {
  deleteItemFromCache,
  DELETE_GOODS_STATE_MUTATION,
  getGoodsStateItem,
  getResourceName,
  GOODS_STATE_ITEM_QUERY,
} from "../dataSource"
import { canCreate, canDelete, canUpdate } from "../accessRights"
import {
  editGoodsStatePath,
  newGoodsStatePath,
  goodsStatesPath,
} from "../paths"
import { pluralName, singleName } from "../metaInfo"
import GoodsState from "./GoodsState"

function GoodsStateShowPage({ auth: { userRole }, id }) {
  return (
    <Query query={GOODS_STATE_ITEM_QUERY} variables={{ id }}>
      {({ loading, errors, data }) => {
        const goodsState = getGoodsStateItem(data)

        if (!loading && !errors && !goodsState) {
          errors = ["Not found."]
        }
        return (
          <ResourceShowPage
            canCreate={canCreate(userRole)}
            canDelete={canDelete(userRole)}
            canUpdate={canUpdate(userRole)}
            deleteItemFromCache={deleteItemFromCache}
            deleteMutation={DELETE_GOODS_STATE_MUTATION}
            editResourcePath={editGoodsStatePath}
            errors={errors}
            getResourceName={getResourceName}
            id={id}
            loading={loading}
            newResourcePath={newGoodsStatePath}
            pluralName={pluralName}
            resourceComponent={GoodsState}
            resources={{ item: goodsState }}
            resourcesPath={goodsStatesPath}
            singleName={singleName}
          />
        )
      }}
    </Query>
  )
}
GoodsStateShowPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
}
export default compose(
  withResourceId,
  withAuthenticationContext,
)(GoodsStateShowPage)
