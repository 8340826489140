import { cacheUpdate, gql } from "../../../state_management/DataProvider"

import { singleName } from "./metaInfo"

import { reject } from "../../../utils"

import { PRODUCT_LIST_QUERY } from "../product/dataSource"

export const fields =
  "id, name, remark, createdAt, createdBy, updatedAt, updatedBy"

const inputParams = "$name: String, $remark: String"
const inputFields = "name: $name, remark: $remark"

export const PRODUCT_OWNER_LIST_QUERY = gql`
  query ProductOwners {
    allProductOwnersList(orderBy: NAME_ASC) { ${fields} }
  }
`
export const PRODUCT_OWNER_ITEM_QUERY = gql`
  query ProductOwner($id: BigInt!) {
    productOwnerById(id: $id) { ${fields} }
  }
`
export const CREATE_PRODUCT_OWNER_MUTATION = gql`
  mutation CreateProductOwner(${inputParams}) {
    createProductOwner(input: { ${inputFields} }) {
      productOwner { ${fields} }
    }
  }
`
export const UPDATE_PRODUCT_OWNER_MUTATION = gql`
  mutation UpdateProductOwner($id: BigInt!, ${inputParams}) {
    updateProductOwner(input: { id: $id, ${inputFields} }) {
      productOwner { ${fields} }
    }
  }
`
export const DELETE_PRODUCT_OWNER_MUTATION = gql`
  mutation DeleteProductOwner($id: BigInt!) {
    deleteProductOwner(input: { id: $id }) {
      productOwner {
        id
        name
      }
    }
  }
`
export const getProductOwnerList = data =>
  (data || {}).allProductOwnersList || []
export const getProductOwnerItem = data => (data || {}).productOwnerById

export const getCreatedProductOwnerItem = data =>
  data.createProductOwner.productOwner
export const getDeletedProductOwnerId = data =>
  data.deleteProductOwner.productOwner.id

export const insertItemIntoCache = cacheUpdate(
  PRODUCT_OWNER_LIST_QUERY,
  (cache, data) => ({
    allProductOwnersList: getProductOwnerList(cache).concat([
      getCreatedProductOwnerItem(data),
    ]),
  }),
)
export const updateItemInCache = cacheUpdate(
  PRODUCT_LIST_QUERY,
  (cache, { updateProductOwner: { productOwner } }) => ({
    allProductsList: cache.allProductsList.map(product => {
      const index = product.productOwnersIds.indexOf(productOwner.id)
      return ~index
        ? {
            ...product,
            productOwnersNames: product.productOwnersNames.map(
              (name, idx) => (idx === index ? productOwner.name : name),
            ),
          }
        : product
    }),
  }),
)
export const deleteItemFromCache = cacheUpdate(
  PRODUCT_OWNER_LIST_QUERY,
  (cache, data) => ({
    allProductOwnersList: reject(getProductOwnerList(cache), {
      id: getDeletedProductOwnerId(data),
    }),
  }),
)

export const getResourceName = resource =>
  (resource && resource.name) || `New ${singleName}`

export const cacheRedirects = {
  productOwnerById: (_, args, { getCacheKey }) =>
    getCacheKey({ __typename: "ProductOwner", id: args.id }),
}
