import React from "react"
import PropTypes from "prop-types"

import {
  DateInput,
  IconNames,
  Intent,
  SwitchInput,
  Toolbar,
} from "../../../../ui"

import { newOrderPath } from "../../order/paths"
import { MenuItems } from "../../../menu"

import "./StockIndexToolbar.css"

function StockIndexToolbar({
  aggregate,
  canCreate,
  date,
  onAggregateChange,
  onDateChange,
  orderTypes,
}) {
  return (
    <React.Fragment>
      <Toolbar.Group>
        <DateInput
          canClearSelection={false}
          className="stock-date"
          editing={true}
          onChange={onDateChange}
          valid={true}
          value={date}
        />
        <Toolbar.Buttons leftDivider></Toolbar.Buttons>
        <SwitchInput
          className="stock-aggregate"
          editing={true}
          innerLabel="Off"
          innerLabelChecked="On"
          label="Aggregate Location"
          onChange={onAggregateChange}
          valid={true}
          value={aggregate}
        />
      </Toolbar.Group>
      {canCreate && (
        <Toolbar.Buttons leftDivider>
          <MenuItems
            icon={IconNames.PLUS}
            intent={Intent.PRIMARY}
            items={orderTypes}
            path={newOrderPath}
            showCaretDown
            title="Create New Order"
          />
        </Toolbar.Buttons>
      )}
    </React.Fragment>
  )
}
StockIndexToolbar.propTypes = {
  aggregate: PropTypes.bool,
  canCreate: PropTypes.bool,
  date: PropTypes.object,
  onAggregateChange: PropTypes.func,
  onDateChange: PropTypes.func,
  orderTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      permalink: PropTypes.string,
      followedBySeparator: PropTypes.bool,
    }),
  ),
}
export default StockIndexToolbar
