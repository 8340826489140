import { cacheUpdate, gql } from "../../../../state_management/DataProvider"

import { reject } from "../../../../utils"

export const fields = `
  id,
  orderId,
  productTypeId,
  productTypeName,
  productId,
  productCode,
  productName: productNameWithLanguage,
  productNotForPublicSale,
  sourceGoodsStateId,
  sourceGoodsStateName,
  sourceLocationId,
  sourceLocationName,
  targetGoodsStateId,
  targetGoodsStateName,
  targetLocationId,
  targetLocationName,
  quantity,
  sourceStockRemainQuantity,
  targetStockRemainQuantity,
  createdAt,
  createdBy,
  updatedAt,
  updatedBy
`
const inputParams = `
  $productId: BigInt
  $sourceGoodsStateId: BigInt
  $sourceLocationId: BigInt
  $targetGoodsStateId: BigInt 
  $targetLocationId: BigInt
  $quantity: BigInt
`
const inputFields = `
  productId: $productId 
  sourceGoodsStateId: $sourceGoodsStateId 
  sourceLocationId: $sourceLocationId
  targetGoodsStateId: $targetGoodsStateId 
  targetLocationId: $targetLocationId
  quantity: $quantity 
`

export const MOVEMENT_LIST_QUERY = gql`
  query movements($orderId: BigInt!) {
    allMovementsList(condition: {orderId: $orderId}, orderBy: ID_DESC) { ${fields} }
  }
`
export const CREATE_MOVEMENT_MUTATION = gql`
  mutation CreateMovement($orderId: BigInt!, ${inputParams}) {
    createMovement(input: {orderId: $orderId, ${inputFields}}) {
      movement { ${fields} }
    }
  }
`
export const UPDATE_MOVEMENT_MUTATION = gql`
  mutation UpdateMovement($id: BigInt!, ${inputParams}) {
    updateMovement(input: {id: $id, ${inputFields}}) {
      movement { ${fields} }
    }
  }
`
export const DELETE_MOVEMENT_MUTATION = gql`
  mutation DeleteMovement($id: BigInt!) {
    deleteMovement(input: { id: $id }) {
      movement {
        id
      }
    }
  }
`
export const STOCK_QUANTITY_ITEM_QUERY = gql`
  query StockQuantity(
    $stockId: BigInt!
    $productId: BigInt!
    $goodsStateId: BigInt
    $locationId: BigInt
    $contractorId: BigInt
    $date: Date!
  ) {
    stockQuantityOnDate(
      stockId: $stockId
      productId: $productId
      goodsStateId: $goodsStateId
      locationId: $locationId
      contractorId: $contractorId
      date: $date
    )
  }
`

export const getMovementList = data => (data || {}).allMovementsList || []
export const getCreatedMovementItem = data => data.createMovement.movement
export const getDeletedMovementId = data => data.deleteMovement.movement.id
export const getStockQuantityItem = data => (data || {}).stockQuantityOnDate

export const insertItemIntoCache = cacheUpdate(
  MOVEMENT_LIST_QUERY,
  (cache, data) => ({
    allMovementsList: [getCreatedMovementItem(data), ...getMovementList(cache)],
  }),
)
export const deleteItemFromCache = cacheUpdate(
  MOVEMENT_LIST_QUERY,
  (cache, data) => {
    return {
      allMovementsList: reject(getMovementList(cache), {
        id: getDeletedMovementId(data),
      }),
    }
  },
)
