import Alignment from "../../../../ui/Alignment"
import Column from "../../../../ui/Column/Column"
import withRenderer from "../../../../ui/DataGrid/withRenderer"
import NotForPublicSale from "./NotForPublicSale"
import { DROP_DOWN } from "../../../../ui/DataType"

const NotForPublicSaleCell = withRenderer(NotForPublicSale)

class NotForPublicSaleColumn extends Column {}
NotForPublicSaleColumn.defaultProps = {
  align: Alignment.CENTER,
  renderer: NotForPublicSaleCell,
  width: 40,
  type: DROP_DOWN,
  keyCreator: ({ value }) =>
    ({ false: "For Sale", true: "Not for Sale" }[value]),
  filterParams: {
    values: ["Not for Sale", "For Sale"],
  },
}

export default NotForPublicSaleColumn
