import React from "react"
import PropTypes from "prop-types"

import { getIn } from "../../utils"

import Intent from "../Intent"
import FormGroup from "./FormGroup"
import labelFromName from "./labelFromName"
import withForm from "./withForm"

import "./FormGroupField.css"

function FormGroupField({
  component: Component,
  disabled,
  form: {
    editing,
    errors,
    isSubmitting,
    onChange,
    onBlur,
    touched,
    values,
    ...restForm
  },
  fullWidthComponent,
  inline,
  label,
  large,
  name,
  omitIfEmpty,
  validationError,
  width,
  ...rest
}) {
  const valid = !(getIn(touched, name) && getIn(errors, name))
  const value = getIn(values, name)

  return (
    ((value || editing || !omitIfEmpty) && (
      <FormGroup
        contentClassName={fullWidthComponent ? "full-width" : undefined}
        disabled={disabled || isSubmitting}
        helperText={getIn(touched, name) && getIn(errors, name)}
        inline={inline}
        intent={valid ? Intent.NONE : Intent.DANGER}
        label={label || labelFromName(name)}
        large={large}
      >
        <Component
          disabled={disabled || isSubmitting}
          editing={editing}
          large={large}
          keyName={name}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          valid={valid}
          value={getIn(values, name)}
          width={width || (fullWidthComponent && "100%") || undefined}
          {...rest}
          form={{
            editing,
            errors,
            isSubmitting,
            onChange,
            onBlur,
            touched,
            values,
            ...restForm,
          }}
        />
      </FormGroup>
    )) ||
    null
  )
}
FormGroupField.propTypes = {
  component: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  form: PropTypes.shape({
    editing: PropTypes.bool,
    errors: PropTypes.objectOf(PropTypes.string),
    isSubmitting: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.bool),
    values: PropTypes.object,
  }),
  inline: PropTypes.bool,
  label: PropTypes.string,
  large: PropTypes.bool,
  name: PropTypes.string,
  omitOfEmpty: PropTypes.bool,
  validationError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}
export { labelFromName }
export default withForm(FormGroupField)
