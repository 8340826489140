import React from "react"
import PropTypes from "prop-types"

import { formatDateTime } from "../../../utils"

import { Alignment, Fingerprint, Toolbar } from "../../../ui"

function ResourceFingerprint({
  resource: { createdAt, createdBy, updatedAt, updatedBy },
}) {
  return (
    <Toolbar.Group align={Alignment.RIGHT}>
      <Fingerprint
        createdAt={formatDateTime(new Date(createdAt))}
        createdBy={createdBy}
        updatedAt={formatDateTime(new Date(updatedAt))}
        updatedBy={updatedBy}
      />
    </Toolbar.Group>
  )
}
ResourceFingerprint.propTypes = {
  resource: PropTypes.shape({
    createdAt: PropTypes.string,
    userByCreatedBy: PropTypes.shape({ name: PropTypes.string }),
    updatedAt: PropTypes.string,
    userByUpdatedBy: PropTypes.shape({ name: PropTypes.string }),
  }),
}
export default ResourceFingerprint
