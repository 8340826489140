import React from "react"
import PropTypes from "prop-types"

import "./PageContent.css"

const PageContent = ({ children }) => (
  <div className="PageContent">{children}</div>
)
PageContent.propTypes = {
  children: PropTypes.node,
}
export default PageContent
