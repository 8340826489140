import React from "react"
import PropTypes from "prop-types"

import { date, object, string } from "../../../../utils/yup"

import { Card, DateInput, SelectInput, TextInput } from "../../../../ui"

import { RemarkField } from "../../../resourcePage"

import Movements from "../movement/Movements"

const ContractorItemRenderer = SelectInput.createItemRenderer({
  name: "Contractor",
})

function Order({
  newPage,
  resources: {
    contractors,
    deleteMovement,
    editingMovementId,
    goodsStates,
    item: {
      id,
      date,
      sourceContractorId,
      sourceContractorName,
      targetContractorId,
      targetContractorName,
    },
    locations,
    movements,
    movementsEditable,
    newMovementId,
    orderType,
    products,
    productTypes,
    saveMovement,
    saveNewMovement,
    startEditingMovement,
    stopEditingMovement,
    waitingMovementId,
    waitingNewMovement,
  },
}) {
  const { reason, sourceContractor, targetContractor } = orderType
  return (
    <Card>
      <Card.Table>
        <Card.Column flexWidth="1">
          <Card.Field
            component={TextInput}
            name="name"
            label="No.:"
            width={300}
          />
          <Card.Field
            component={TextInput}
            name="externalName"
            label="Ex.No.:"
            width={300}
          />
          <Card.Field component={DateInput} name="date" width={100} />
        </Card.Column>
        {(sourceContractor || targetContractor) && (
          <Card.Column flexWidth="1">
            {sourceContractor && (
              <Card.Field
                component={SelectInput}
                itemRenderer={ContractorItemRenderer}
                items={contractors}
                label={`${sourceContractor}:`}
                name="sourceContractorId"
                textValue={sourceContractorName}
                width={300}
              />
            )}
            {targetContractor && (
              <Card.Field
                component={SelectInput}
                itemRenderer={ContractorItemRenderer}
                items={contractors}
                label={`${targetContractor}:`}
                name="targetContractorId"
                textValue={targetContractorName}
                width={300}
              />
            )}
          </Card.Column>
        )}
      </Card.Table>
      {reason && (
        <Card.Field
          component={TextInput}
          name="reason"
          width="100%"
          fullWidthComponent
        />
      )}
      {!newPage && (movementsEditable || !!movements.length) && (
        <Movements
          date={new Date(date)}
          deleteMovement={deleteMovement}
          editable={movementsEditable}
          editingId={editingMovementId}
          goodsStates={goodsStates}
          locations={locations}
          movements={movements}
          newMovementId={newMovementId}
          orderId={id}
          orderType={orderType}
          products={products}
          productTypes={productTypes}
          saveMovement={saveMovement}
          saveNewMovement={saveNewMovement}
          sourceContractorId={sourceContractorId}
          startEditing={startEditingMovement}
          stopEditing={stopEditingMovement}
          targetContractorId={targetContractorId}
          waitingId={waitingMovementId}
          waitingNew={waitingNewMovement}
        />
      )}
      <RemarkField />
    </Card>
  )
}
Order.validationSchema = ({
  sourceContractor,
  targetContractor,
  reason: reasonPresent,
}) => {
  return object().shape({
    name: string().required(),
    externalName: string(),
    date: date().required(),
    ...(sourceContractor
      ? {
          sourceContractorId: string().required(
            `${sourceContractor} is a required field`,
          ),
        }
      : {}),
    ...(targetContractor
      ? {
          targetContractorId: string().required(
            `${targetContractor} is a required field`,
          ),
        }
      : {}),
    ...(reasonPresent ? { reason: string() } : {}),
    remark: string(),
  })
}
Order.initialValues = ({
  orderTypeId,
  defaultName,
  sourceContractor,
  targetContractor,
  reason: reasonPresent,
}) => ({
  name,
  externalName,
  date,
  sourceContractorId,
  targetContractorId,
  reason,
  remark,
}) => {
  return {
    ...(orderTypeId ? { orderTypeId } : {}),
    name: defaultName || name || "",
    externalName: externalName || "",
    date: date ? new Date(date) : new Date(),
    ...(sourceContractor
      ? { sourceContractorId: sourceContractorId || "" }
      : {}),
    ...(targetContractor
      ? { targetContractorId: targetContractorId || "" }
      : {}),
    ...(reasonPresent ? { reason: reason || "" } : {}),
    remark: remark || "",
  }
}
Order.propTypes = {
  newPage: PropTypes.bool,
  resources: PropTypes.shape({
    contractors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.strins,
      }),
    ),
    editingMovementId: PropTypes.string,
    deleteMovement: PropTypes.func,
    goodsStates: PropTypes.array,
    item: PropTypes.shape({
      id: PropTypes.string,
      sourceContractorName: PropTypes.string,
      targetContractorName: PropTypes.string,
    }),
    locations: PropTypes.array,
    movements: PropTypes.array,
    movementsEditable: PropTypes.bool,
    newMovementId: PropTypes.string,
    orderType: PropTypes.shape({
      reason: PropTypes.bool,
    }),
    products: PropTypes.array,
    productTypes: PropTypes.array,
    saveMovement: PropTypes.func,
    saveNewMovement: PropTypes.func,
    startEditingMovement: PropTypes.func,
    stopEditingMovement: PropTypes.func,
    waitingMovementId: PropTypes.string,
    waitingNewMovement: PropTypes.bool,
  }),
}
export default Order
