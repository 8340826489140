import React from "react"
import PropTypes from "prop-types"

import { Alert, Intent } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

const DeleteConfirmationDialog = ({ item, show, onConfirm, onCancel }) => (
  <Alert
    confirmButtonText="Delete"
    cancelButtonText="Cancel"
    canEscapeKeyCancel={true}
    canOutsideClickCancel={true}
    icon={IconNames.TRASH}
    intent={Intent.DANGER}
    isOpen={show}
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <p>Delete {item}?</p>
  </Alert>
)
DeleteConfirmationDialog.propTypes = {
  item: PropTypes.string,
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}
export default DeleteConfirmationDialog
