import React from "react"
import PropTypes from "prop-types"

import { withAuthenticationContext } from "../../../../state_management/Authentication"

import { Queries } from "../../../../state_management/DataProvider"

import { ResourceNewPage } from "../../../resourcePage"

import {
  PRODUCT_TYPE_LIST_QUERY,
  getProductTypeList,
} from "../../productType/dataSource"
import {
  PRODUCT_OWNER_LIST_QUERY,
  getProductOwnerList,
} from "../../productOwner/dataSource"
import { LANGUAGE_LIST_QUERY, getLanguageList } from "../../language/dataSource"
import {
  CREATE_PRODUCT_MUTATION,
  getCreatedProductItem,
  getResourceName,
  insertItemIntoCache,
} from "../dataSource"
import { canCreate } from "../accessRights"
import { productPath, productsPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"
import Product from "./Product"

function ProductNewPage({ auth: { userRole } }) {
  // prettier-ignore
  const queries = [
    { resourceName: "productTypes",  query: PRODUCT_TYPE_LIST_QUERY,  getResource: getProductTypeList },
    { resourceName: "productOwners", query: PRODUCT_OWNER_LIST_QUERY, getResource: getProductOwnerList },
    { resourceName: "languages",     query: LANGUAGE_LIST_QUERY,      getResource: getLanguageList },
  ]
  return (
    <Queries queries={queries} skip={!canCreate(userRole)}>
      {({
        loading,
        errors,
        resources: { languages, productOwners, productTypes },
      }) => {
        return (
          <ResourceNewPage
            createMutation={CREATE_PRODUCT_MUTATION}
            errors={canCreate(userRole) ? errors : ["Access denied."]}
            getResourceFromCreateMutation={getCreatedProductItem}
            getResourceName={getResourceName}
            insertItemIntoCache={insertItemIntoCache}
            loading={loading}
            pluralName={pluralName}
            resourceComponent={Product}
            resourcePath={productPath}
            resources={{ languages, productOwners, productTypes }}
            resourcesPath={productsPath}
            singleName={singleName}
            validationSchema={Product.validationSchema(productTypes)}
          />
        )
      }}
    </Queries>
  )
}
ProductNewPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
}
export default withAuthenticationContext(ProductNewPage)
