function getItem(key) {
  return localStorage.getItem(key)
}

function setItem(key, value) {
  localStorage.setItem(key, value)
}

function deleteItem(key) {
  localStorage.removeItem(key)
}

export { getItem, setItem, deleteItem }
