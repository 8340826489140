import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"
import { withFlash } from "../../../../ui"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query, Mutation } from "../../../../state_management/DataProvider"
import { ResourceShowPage, withResourceId } from "../../../resourcePage"

import {
  CHANGE_USER_PASSWORD_MUTATION,
  deleteItemFromCache,
  DELETE_USER_MUTATION,
  ENABLE_DISABLE_USER_MUTATION,
  getUserItem,
  getResourceName,
  USER_ITEM_QUERY,
} from "../dataSource"
import { canCreate, canDelete, canUpdate, canDisable } from "../accessRights"
import { editUserPath, newUserPath, usersPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"
import User from "./User"

import ActionToolbar from "./UserShowPageActionToolbar"

function UserShowPage({ auth: { userId, userRole }, id, flash: { success } }) {
  return (
    <Mutation mutation={CHANGE_USER_PASSWORD_MUTATION}>
      {(changeUserPassword, { loading: changingPassword }) => {
        async function handleChangePassword({
          currentUserPassword,
          newPassword,
        }) {
          const result = await changeUserPassword({
            variables: { currentUserPassword, id, newPassword },
          })
          result &&
            success({ message: "Password has been successfully changed." })
          return result
        }

        return (
          <Mutation mutation={ENABLE_DISABLE_USER_MUTATION}>
            {(enableDisableUser, { loading: enablingUser }) => {
              function handleEnableDisable(enable) {
                enableDisableUser({ variables: { id, enable } })
              }

              return (
                <Query query={USER_ITEM_QUERY} variables={{ id }}>
                  {({ loading, errors, data }) => {
                    const user = getUserItem(data)

                    if (!loading && !errors && !user) {
                      errors = ["Not found."]
                    }
                    return (
                      <ResourceShowPage
                        actionStatus={{ enablingUser, changingPassword }}
                        actionToolbar={
                          canUpdate(userRole, user) && ActionToolbar
                        }
                        canCreate={canCreate(userRole)}
                        canDelete={canDelete(userRole, user, userId)}
                        canUpdate={canUpdate(userRole, user)}
                        deleteItemFromCache={deleteItemFromCache}
                        deleteMutation={DELETE_USER_MUTATION}
                        editResourcePath={editUserPath}
                        errors={errors}
                        getResourceName={getResourceName}
                        handleActions={{
                          canEnableOrDisable: canDisable(
                            userRole,
                            user,
                            userId,
                          ),
                          handleChangePassword,
                          handleEnable: () => handleEnableDisable(true),
                          handleDisable: () => handleEnableDisable(false),
                        }}
                        id={id}
                        loading={loading}
                        newResourcePath={newUserPath}
                        pluralName={pluralName}
                        resourceComponent={User}
                        resources={{ item: user }}
                        resourcesPath={usersPath}
                        singleName={singleName}
                        waiting={enablingUser || changingPassword}
                      />
                    )
                  }}
                </Query>
              )
            }}
          </Mutation>
        )
      }}
    </Mutation>
  )
}
UserShowPage.propTypes = {
  auth: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  flash: PropTypes.shape({
    success: PropTypes.func,
  }),
  id: PropTypes.number,
}
export default compose(
  withAuthenticationContext,
  withResourceId,
  withFlash,
)(UserShowPage)
