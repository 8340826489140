import React from "react"
import PropTypes from "prop-types"

import { find, pick } from "../../utils"
import { HTMLSelect, Intent } from "@blueprintjs/core"
import Tag from "../Tag"

import "./Input.css"

function DropDownTagInput({
  disabled,
  editing,
  large,
  name,
  onBlur,
  onChange,
  options,
  round,
  style,
  tag: CustomTag,
  tagStyle,
  valid,
  value,
  width,
}) {
  if (editing) {
    return (
      <HTMLSelect
        disabled={disabled}
        intent={valid ? Intent.NONE : Intent.DANGER}
        large={large}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={options.map(option => pick(option, ["label", "value"]))}
        style={width ? { width, ...style } : style}
        value={value}
      />
    )
  } else {
    const { backColor, textColor, label } = find(options, { value }) || {}

    return (
      <CustomTag
        textColor={textColor}
        backColor={backColor}
        large={large}
        round={round}
        style={tagStyle}
      >
        {label || value}
      </CustomTag>
    )
  }
}
DropDownTagInput.defaultProps = {
  tag: Tag,
}
DropDownTagInput.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      backColor: PropTypes.string,
      label: PropTypes.string,
      textColor: PropTypes.string,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  round: PropTypes.bool,
  style: PropTypes.object,
  tag: PropTypes.func,
  tagStyle: PropTypes.object,
  valid: PropTypes.bool,
  value: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
export default DropDownTagInput
