import React from "react"
import PropTypes from "prop-types"

import { NonIdealState, Button, Intent } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

const Empty = ({ description, onCreateClick }) => (
  <NonIdealState
    icon={IconNames.DATABASE}
    title="Empty"
    description={onCreateClick ? description : undefined}
  >
    {onCreateClick && (
      <Button intent={Intent.PRIMARY} text="Create" onClick={onCreateClick} />
    )}
  </NonIdealState>
)

Empty.propTypes = {
  description: PropTypes.string,
  onCreateClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
}
export default Empty
