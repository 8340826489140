import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { ResourceEditPage, withResourceId } from "../../../resourcePage"

import {
  getUserItem,
  getResourceName,
  USER_ITEM_QUERY,
  UPDATE_USER_MUTATION,
} from "../dataSource"
import { canUpdate } from "../accessRights"
import { pluralName, singleName } from "../metaInfo"
import { userPath, usersPath } from "../paths"
import User from "./User"

const UserEditPage = ({ auth: { userRole }, id }) => (
  <Query query={USER_ITEM_QUERY} variables={{ id }}>
    {({ loading, errors, data }) => {
      const user = getUserItem(data)

      if (!loading && !canUpdate(userRole, user)) {
        errors = ["Access denied."]
      } else if (!loading && !errors && !user) {
        errors = ["Not found."]
      }

      return (
        <ResourceEditPage
          errors={errors}
          getResourceName={getResourceName}
          id={id}
          loading={loading}
          pluralName={pluralName}
          resourceComponent={User}
          resources={{ item: user }}
          resourcePath={userPath}
          resourcesPath={usersPath}
          singleName={singleName}
          updateMutation={UPDATE_USER_MUTATION}
        />
      )
    }}
  </Query>
)
UserEditPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
}
export default compose(
  withResourceId,
  withAuthenticationContext,
)(UserEditPage)
