import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { ResourceEditPage, withResourceId } from "../../../resourcePage"

import {
  getResourceName,
  getGoodsStateItem,
  GOODS_STATE_ITEM_QUERY,
  UPDATE_GOODS_STATE_MUTATION,
} from "../dataSource"
import { canUpdate } from "../accessRights"
import { pluralName, singleName } from "../metaInfo"
import { goodsStatePath, goodsStatesPath } from "../paths"
import GoodsState from "./GoodsState"

function GoodsStateEditPage({ auth: { userRole }, id }) {
  return (
    <Query
      query={GOODS_STATE_ITEM_QUERY}
      variables={{ id }}
      skip={!canUpdate(userRole)}
    >
      {({ loading, errors, data }) => {
        const goodsState = getGoodsStateItem(data)

        if (!canUpdate(userRole)) {
          errors = ["Access denied."]
        } else if (!loading && !errors && !goodsState) {
          errors = ["Not found."]
        }

        return (
          <ResourceEditPage
            errors={errors}
            getResourceName={getResourceName}
            id={id}
            loading={loading}
            pluralName={pluralName}
            resourceComponent={GoodsState}
            resources={{ item: goodsState }}
            resourcePath={goodsStatePath}
            resourcesPath={goodsStatesPath}
            singleName={singleName}
            updateMutation={UPDATE_GOODS_STATE_MUTATION}
          />
        )
      }}
    </Query>
  )
}
GoodsStateEditPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
}
export default compose(
  withResourceId,
  withAuthenticationContext,
)(GoodsStateEditPage)
