import React from "react"
import PropTypes from "prop-types"

import PageContent from "./PageContent"

const Page = ({ children }) => <React.Fragment>{children}</React.Fragment>
Page.propTypes = {
  children: PropTypes.node,
}
Page.Content = PageContent
export default Page
