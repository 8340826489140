import PropTypes from "prop-types"

import createEnumIcon from "../../../../ui/createEnumIcon"
import { IconNames } from "../../../../ui/Icon"
import Intent from "../../../../ui/Intent"

const NotForPublicSale = createEnumIcon({
  enumOptions: {
    false: { icon: IconNames.BLANK },
    true: { icon: IconNames.BAN_CIRCLE, intent: Intent.DANGER },
  },
})
NotForPublicSale.propTypes = {
  value: PropTypes.bool,
}
export default NotForPublicSale
