import {
  resourcePathFor,
  resourcesPathFor,
  newResourcePathFor,
  editResourcePathFor,
} from "../../resourcePage"

export const locationPath = resourcePathFor("locations")
export const locationsPath = resourcesPathFor("locations")
export const newLocationPath = newResourcePathFor("locations")
export const editLocationPath = editResourcePathFor("locations")
