import {
  resourcePathFor,
  resourcesPathFor,
  newResourcePathFor,
  editResourcePathFor,
} from "../../resourcePage"

export const productOwnerPath = resourcePathFor("product-owners")
export const productOwnersPath = resourcesPathFor("product-owners")
export const newProductOwnerPath = newResourcePathFor("product-owners")
export const editProductOwnerPath = editResourcePathFor("product-owners")
