import React from "react"
import PropTypes from "prop-types"

import { Classes } from "@blueprintjs/core"

import { classNames } from "../../utils"

function FormGroup({
  children,
  className,
  contentClassName,
  disabled,
  helperText,
  inline,
  intent,
  large,
  label,
  labelFor,
  labelInfo,
}) {
  const formGroupClass = [
    Classes.FORM_GROUP,
    Classes[`INTENT_${intent.toUpperCase()}`],
  ]
  if (className) formGroupClass.push(className)
  if (large) formGroupClass.push(Classes.LARGE)
  if (disabled) formGroupClass.push(Classes.DISABLED)
  if (inline) formGroupClass.push(Classes.INLINE)

  const contentClass = [Classes.FORM_CONTENT]
  if (contentClassName) contentClass.push(contentClassName)

  return (
    <div className={classNames(formGroupClass)}>
      {label && (
        <label className={Classes.LABEL} htmlFor={labelFor}>
          {label}
          {labelInfo && <span className={Classes.TEXT_MUTED}>{labelInfo}</span>}
        </label>
      )}
      <div className={classNames(contentClass)}>
        {children}
        {helperText && (
          <div className={Classes.FORM_HELPER_TEXT}>{helperText}</div>
        )}
      </div>
    </div>
  )
}
FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.node,
  inline: PropTypes.bool,
  intent: PropTypes.string,
  large: PropTypes.bool,
  label: PropTypes.node,
  labelFor: PropTypes.string,
  labelInfo: PropTypes.node,
}
export default FormGroup
