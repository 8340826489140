import React from "react"
import PropTypes from "prop-types"

import { find } from "../../../utils"

import { Button, InputGroup, Intent } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import Spinner from "../../Spinner"

import createItemRenderer from "./createItemRenderer"
import ItemListRenderer from "./ItemListRenderer"

import "../Input.css"
import "./SelectInput.css"

function SelectInput({
  disabled,
  editing,
  form: { values },
  itemKey,
  items,
  itemRenderer,
  itemValue,
  large,
  loading,
  keyName,
  onBlur,
  onChange,
  textValue,
  valid,
  width,
}) {
  function onItemSelect({ id }) {
    onChange({ target: { name: keyName, value: id } })
  }

  if (loading)
    return <Spinner size={large ? Spinner.SIZE_LARGE : Spinner.SIZE_STANDARD} />
  if (editing) {
    const item = find(items, { [itemKey]: values[keyName] }) || {}
    return (
      <Select
        disabled={disabled}
        itemListRenderer={ItemListRenderer}
        itemPredicate={itemRenderer.filter}
        itemRenderer={itemRenderer}
        items={items}
        large={large}
        onBlur={onBlur}
        onItemSelect={onItemSelect}
        popoverProps={{ minimal: true }}
      >
        <Button
          alignText="left"
          fill
          intent={valid ? Intent.NONE : Intent.DANGER}
          rightIcon="double-caret-vertical"
          style={{ minWidth: width }}
          text={item[itemValue] || " "}
        />
      </Select>
    )
  } else {
    return (
      <InputGroup
        readOnly={true}
        large={large}
        style={{ width }}
        value={textValue}
      />
    )
  }
}
SelectInput.defaultProps = {
  itemKey: "id",
  itemValue: "name",
}
SelectInput.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  itemKey: PropTypes.string,
  itemRenderer: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  itemValue: PropTypes.string,
  keyName: PropTypes.string,
  large: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  round: PropTypes.bool,
  tag: PropTypes.func,
  textValue: PropTypes.string,
  valid: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
SelectInput.createItemRenderer = createItemRenderer
export default SelectInput
