import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"

import { ResourceNewPage } from "../../../resourcePage"

import { withMetaData } from "../../../metaData"

import {
  CREATE_LOCATION_MUTATION,
  getCreatedLocationItem,
  getResourceName,
  insertItemIntoCache,
} from "../dataSource"
import { canCreate } from "../accessRights"
import { locationPath, locationsPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"
import Location from "./Location"

function LocationNewPage({ auth: { userRole }, metaData: { stocks } }) {
  return (
    <ResourceNewPage
      createMutation={CREATE_LOCATION_MUTATION}
      errors={canCreate(userRole) ? undefined : ["Access denied."]}
      getResourceFromCreateMutation={getCreatedLocationItem}
      getResourceName={getResourceName}
      insertItemIntoCache={insertItemIntoCache}
      pluralName={pluralName}
      resourceComponent={Location}
      resourcePath={locationPath}
      resources={{ stocks: Location.locationableStocks(stocks) }}
      resourcesPath={locationsPath}
      singleName={singleName}
    />
  )
}
LocationNewPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  metaData: PropTypes.shape({
    stocks: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
}
export default compose(
  withMetaData,
  withAuthenticationContext,
)(LocationNewPage)
