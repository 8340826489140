// import React from "react"
import PropTypes from "prop-types"

import { formatDateTime } from "../../../utils"

function DateTimeCell({ value }) {
  return value ? formatDateTime(new Date(value)) : null
}
DateTimeCell.propTypes = {
  value: PropTypes.string,
}
export default DateTimeCell
