import React from "react"
import PropTypes from "prop-types"

import { compose, find } from "../../../../utils"
import { Column, DataType } from "../../../../ui"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { withMetaData } from "../../../metaData"

import {
  CreatedAtColumn,
  CreatedByColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  IdColumn,
  ResourceIndexPage,
} from "../../../resourcePage"

import withPermalink from "../../../routing/withPermalink"

import { canCreate } from "../accessRights"
import { getOrderList, ORDER_LIST_QUERY } from "../dataSource"
import { orderPath, newOrderPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"

function OrderIndexPage({
  auth: { userRole },
  metaData: { orderTypes },
  permalink,
}) {
  const orderType = find(orderTypes, { permalink })
  const { id: orderTypeId, name, sourceContractor, targetContractor, reason } =
    orderType || {}

  return (
    <Query
      query={ORDER_LIST_QUERY}
      variables={{ orderTypeId }}
      skip={!orderType}
    >
      {({ loading, errors, data }) => {
        return (
          <ResourceIndexPage
            canCreate={canCreate(userRole)}
            errors={orderType ? errors : ["Not Found"]}
            loading={loading}
            newResourcePath={newOrderPath(permalink)}
            pluralName={pluralName(name)}
            resources={{ items: getOrderList(data) }}
            resourcePath={orderPath}
            singleName={singleName(name)}
            uniqueName={`orders-${permalink}`}
          >
            <Column field="name" title="No." width={150} />
            <Column field="externalName" title="Ex.No." width={100} />
            <Column field="date" width={90} type={DataType.DATE} />
            {sourceContractor && (
              <Column
                field="sourceContractorName"
                title={sourceContractor}
                width={300}
              />
            )}
            {targetContractor && (
              <Column
                field="targetContractorName"
                title={targetContractor}
                width={300}
              />
            )}
            {reason && <Column field="reason" width={500} />}
            <Column field="remark" width={500} />
            <UpdatedAtColumn />
            <UpdatedByColumn />
            <CreatedAtColumn />
            <CreatedByColumn />
            <IdColumn />
          </ResourceIndexPage>
        )
      }}
    </Query>
  )
}
OrderIndexPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string,
  }),
  metaData: PropTypes.shape({
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        reason: PropTypes.bool,
      }),
    ),
  }),
  permalink: PropTypes.string.isRequired,
}
export default compose(
  withAuthenticationContext,
  withPermalink,
  withMetaData,
)(OrderIndexPage)
