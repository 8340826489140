import React from "react"
import PropTypes from "prop-types"

import { find } from "../../../../utils"
import { object, string } from "../../../../utils/yup"

import {
  Card,
  CheckboxInput,
  DropDownInput,
  MultiSelectInput,
  TextInput,
} from "../../../../ui"

import { RemarkField } from "../../../resourcePage"

function Product({
  resources: {
    item: { languageName, productOwnersNames, productTypeName },
    productOwners,
    productTypes,
    languages,
  },
}) {
  const productTypeItems = (productTypes || [])
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }))
    .sort(DropDownInput.comparer)
  const langaugeItems = [{ label: "", value: null }].concat(
    (languages || [])
      .map(({ id, name }) => ({
        label: name,
        value: id,
      }))
      .sort(DropDownInput.comparer),
  )
  return (
    <Card>
      <Card.Field
        component={DropDownInput}
        name="productTypeId"
        label="Type:"
        options={productTypeItems}
        textValue={productTypeName}
        width={200}
      />
      <Card.Field
        component={TextInput}
        name="code"
        label="Number:"
        width={200}
      />
      <Card.Field component={TextInput} name="name" width={500} />
      <Card.Field component={CheckboxInput} name="notForPublicSale" />
      <Card.Field component={TextInput} name="isbn" label="ISBN:" width={200} />
      <Card.Field
        component={DropDownInput}
        label="Language:"
        name="languageId"
        options={langaugeItems}
        textValue={languageName || ""}
        width={200}
      />
      <Card.Field
        component={MultiSelectInput}
        items={productOwners}
        label="Product Owner(s):"
        name="productOwnersIds"
        textValue={productOwnersNames}
      />
      <RemarkField />
    </Card>
  )
}
Product.validationSchema = productTypes => ({ productTypeId }) =>
  object().shape({
    code: (find(productTypes || [], { id: productTypeId }) || {})
      .codeIsMandatory
      ? string().required("number is a required field")
      : string(),
    name: string().required(),
    isbn: string(),
    remark: string(),
  })
Product.initialValues = (
  {
    productTypeId,
    code,
    name,
    isbn,
    languageId,
    productOwnersIds,
    notForPublicSale,
    remark,
  },
  resources,
) => {
  const defaultProductTypeId =
    resources &&
    resources.productTypes &&
    resources.productTypes.length &&
    resources.productTypes[0].id
  return {
    productTypeId: productTypeId || defaultProductTypeId || "",
    code: code || "",
    name: name || "",
    isbn: isbn || "",
    languageId: languageId || "",
    productOwnersIds: productOwnersIds || [],
    notForPublicSale: notForPublicSale || false,
    remark: remark || "",
  }
}
Product.propTypes = {
  newPage: PropTypes.bool,
  editPage: PropTypes.bool,
  item: PropTypes.shape({
    languageName: PropTypes.string,
    productOwnersNames: PropTypes.string,
    productTypeName: PropTypes.string,
  }),
  resources: PropTypes.shape({
    languages: PropTypes.array,
    productOwners: PropTypes.array,
    productTypes: PropTypes.array,
  }),
}
export default Product
