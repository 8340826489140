import React from "react"
import PropTypes from "prop-types"

import { withAuthenticationContext } from "../../../../state_management/Authentication"

import { ResourceNewPage } from "../../../resourcePage"

import {
  CREATE_PRODUCT_OWNER_MUTATION,
  getCreatedProductOwnerItem,
  getResourceName,
  insertItemIntoCache,
} from "../dataSource"
import { canCreate } from "../accessRights"
import { productOwnerPath, productOwnersPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"
import ProductOwner from "./ProductOwner"

const ProductOwnerNewPage = ({ auth: { userRole } }) => (
  <ResourceNewPage
    createMutation={CREATE_PRODUCT_OWNER_MUTATION}
    errors={canCreate(userRole) ? undefined : ["Access denied."]}
    getResourceFromCreateMutation={getCreatedProductOwnerItem}
    getResourceName={getResourceName}
    insertItemIntoCache={insertItemIntoCache}
    pluralName={pluralName}
    resourceComponent={ProductOwner}
    resourcePath={productOwnerPath}
    resourcesPath={productOwnersPath}
    singleName={singleName}
  />
)
ProductOwnerNewPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
}
export default withAuthenticationContext(ProductOwnerNewPage)
