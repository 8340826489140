import { Intent } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

import createButton from "./createButton"

const BackButton = createButton({
  icon: IconNames.CHEVRON_LEFT,
  title: "Back",
  intent: Intent.NONE,
})
const CancelButton = createButton({
  icon: IconNames.CROSS,
  title: "Cancel",
  intent: Intent.DANGER,
})
const ChangePasswordButton = createButton({
  icon: IconNames.KEY,
  title: "Change Password",
  intent: Intent.WARNING,
})
const DeleteButton = createButton({
  icon: IconNames.TRASH,
  title: "Delete",
  intent: Intent.DANGER,
})
const DisableUserButton = createButton({
  icon: IconNames.BLOCKED_PERSON,
  title: "Disable User",
  intent: Intent.DANGER,
})
const EditButton = createButton({
  icon: IconNames.EDIT,
  title: "Edit",
  intent: Intent.PRIMARY,
})
const EditMovementsButton = createButton({
  icon: IconNames.MANUALLY_ENTERED_DATA,
  title: "Edit Movements",
  intent: Intent.PRIMARY,
})
const EnableUserButton = createButton({
  icon: IconNames.PERSON,
  title: "Enable User",
  intent: Intent.SUCCESS,
})
const ExportToCsv = createButton({
  icon: IconNames.EXPORT,
  title: "Export to CSV",
  intent: Intent.NONE,
})
const LockMovementsButton = createButton({
  icon: IconNames.LOCK,
  title: "Lock Movements",
  intent: Intent.SUCCESS,
})
const LoginButton = createButton({
  title: "Log in",
})
const NewButton = createButton({
  icon: IconNames.PLUS,
  title: "New",
  intent: Intent.PRIMARY,
})
const RefreshButton = createButton({
  icon: IconNames.REFRESH,
  title: "Refresh",
})
const SaveButton = createButton({
  icon: IconNames.TICK,
  title: "Save",
  intent: Intent.SUCCESS,
})

export {
  BackButton,
  CancelButton,
  ChangePasswordButton,
  DeleteButton,
  DisableUserButton,
  EditButton,
  EditMovementsButton,
  EnableUserButton,
  ExportToCsv,
  LockMovementsButton,
  LoginButton,
  NewButton,
  RefreshButton,
  SaveButton,
}
