import {
  resourcePathFor,
  resourcesPathFor,
  newResourcePathFor,
  editResourcePathFor,
} from "../../resourcePage"

export const goodsStatePath = resourcePathFor("goods-states")
export const goodsStatesPath = resourcesPathFor("goods-states")
export const newGoodsStatePath = newResourcePathFor("goods-states")
export const editGoodsStatePath = editResourcePathFor("goods-states")
