import React from "react"

import { withRouter } from "react-router-dom"

function withResourceId(WrappedComponent) {
  function WithResourceId({
    match: {
      params: { id },
    },
    children,
    ...rest
  }) {
    return (
      <WrappedComponent id={+id} {...rest}>
        {children}
      </WrappedComponent>
    )
  }
  WithResourceId.displayName = `WithResourceId(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`
  return withRouter(WithResourceId)
}
export default withResourceId
