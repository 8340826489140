import React from "react"
import PropTypes from "prop-types"

import { Menu, Toolbar } from "../../ui"

import { logoutPath } from "../routing/paths"
import { userPath } from "../domains/user/paths"

const ProfileMenu = ({ userId, userName }) =>
  // prettier-ignore
  <Toolbar.MenuItem title={userName} showCaretDown>
    <Menu.Item title="Profile" href={userPath(userId)} />
    <Menu.Item title="Logout" href={logoutPath()} />
  </Toolbar.MenuItem>
ProfileMenu.propTypes = {
  userId: PropTypes.string,
  userName: PropTypes.string,
}
export default ProfileMenu
