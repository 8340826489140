import React from "react"
import PropTypes from "prop-types"

import { compose, find } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Queries } from "../../../../state_management/DataProvider"

import { ResourceEditPage, withResourceId } from "../../../resourcePage"
import { withMetaData } from "../../../metaData"

import {
  CONTRACTOR_LIST_QUERY,
  getContractorList,
} from "../../contractor/dataSource"
import { MOVEMENT_LIST_QUERY, getMovementList } from "../movement/dataSource"

import {
  getResourceName,
  getOrderItem,
  ORDER_ITEM_QUERY,
  UPDATE_ORDER_MUTATION,
} from "../dataSource"
import { canUpdate } from "../accessRights"
import { pluralName, singleName } from "../metaInfo"
import { orderPath, ordersPath } from "../paths"
import Order from "./Order"

function OrderEditPage({ auth: { userRole }, id, metaData: { orderTypes } }) {
  // prettier-ignore
  const queries = [
    { resourceName: "item",         query: ORDER_ITEM_QUERY,      getResource: getOrderItem,    variables: { id } },
    { resourceName: "contractors",  query: CONTRACTOR_LIST_QUERY, getResource: getContractorList },
    { resourceName: "movements",    query: MOVEMENT_LIST_QUERY,   getResource: getMovementList, variables: { orderId: id } },
  ]

  return (
    <Queries queries={queries} skip={!canUpdate(userRole)}>
      {({ loading, errors, resources: { contractors, item, movements } }) => {
        const orderType = find(orderTypes, { id: item.orderTypeId })
        const { name, permalink, sourceContractor, targetContractor, reason } =
          orderType || {}

        if (!canUpdate(userRole)) {
          errors = ["Access denied."]
        } else if (!loading && !errors && !orderType) {
          errors = ["Not found."]
        }

        return (
          <ResourceEditPage
            errors={errors}
            getResourceName={getResourceName}
            id={id}
            initialValues={Order.initialValues({
              sourceContractor,
              targetContractor,
              reason,
            })}
            loading={loading}
            pluralName={pluralName(name)}
            resourceComponent={Order}
            resources={{ contractors, item, orderType, movements }}
            resourcePath={orderPath}
            resourcesPath={ordersPath(permalink)}
            singleName={singleName(name)}
            updateMutation={UPDATE_ORDER_MUTATION}
            validationSchema={Order.validationSchema({
              sourceContractor,
              targetContractor,
              reason,
            })}
          />
        )
      }}
    </Queries>
  )
}
OrderEditPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
  metaData: PropTypes.shape({
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        permalink: PropTypes.string,
        reason: PropTypes.bool,
        sourceContractor: PropTypes.string,
        targetContractor: PropTypes.string,
      }),
    ),
  }),
}
export default compose(
  withResourceId,
  withAuthenticationContext,
  withMetaData,
)(OrderEditPage)
