import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { ResourceEditPage, withResourceId } from "../../../resourcePage"

import {
  getProductTypeItem,
  getResourceName,
  PRODUCT_TYPE_ITEM_QUERY,
  UPDATE_PRODUCT_TYPE_MUTATION,
  updateItemInCache,
} from "../dataSource"
import { canUpdate } from "../accessRights"
import { pluralName, singleName } from "../metaInfo"
import { productTypePath, productTypesPath } from "../paths"
import ProductType from "./ProductType"

function ProductTypeEditPage({ auth: { userRole }, id }) {
  return (
    <Query
      query={PRODUCT_TYPE_ITEM_QUERY}
      variables={{ id }}
      skip={!canUpdate(userRole)}
    >
      {({ loading, errors, data }) => {
        const productType = getProductTypeItem(data)

        if (!canUpdate(userRole)) {
          errors = ["Access denied."]
        } else if (!loading && !errors && !productType) {
          errors = ["Not found."]
        }

        return (
          <ResourceEditPage
            errors={errors}
            getResourceName={getResourceName}
            id={id}
            loading={loading}
            pluralName={pluralName}
            resourceComponent={ProductType}
            resources={{ item: productType }}
            resourcePath={productTypePath}
            resourcesPath={productTypesPath}
            singleName={singleName}
            updateMutation={UPDATE_PRODUCT_TYPE_MUTATION}
            updateItemInCache={updateItemInCache({})}
          />
        )
      }}
    </Query>
  )
}
ProductTypeEditPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
}
export default compose(
  withResourceId,
  withAuthenticationContext,
)(ProductTypeEditPage)
