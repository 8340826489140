import React from "react"
import PropTypes from "prop-types"

import { HTMLSelect, InputGroup, Intent } from "@blueprintjs/core"
import Spinner from "../Spinner"
import "./Input.css"

function DropDownInput({
  disabled,
  editing,
  large,
  loading,
  name,
  onBlur,
  onChange,
  options,
  style,
  textValue,
  valid,
  value,
  width,
}) {
  if (loading)
    return <Spinner size={large ? Spinner.SIZE_LARGE : Spinner.SIZE_STANDARD} />
  if (editing) {
    return (
      <HTMLSelect
        disabled={disabled}
        intent={valid ? Intent.NONE : Intent.DANGER}
        large={large}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        style={width ? { width, ...style } : style}
        value={value}
      />
    )
  } else {
    return (
      <InputGroup
        readOnly={true}
        large={large}
        style={{ width }}
        value={textValue}
      />
    )
  }
}
DropDownInput.comparer = (a, b) => a.label.localeCompare(b.label)
DropDownInput.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
  ).isRequired,
  round: PropTypes.bool,
  style: PropTypes.object,
  textValue: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
export default DropDownInput
