const cacheUpdate = (query, updater) => variables => (cache, { data }) => {
  try {
    const queryCache = cache.readQuery({ query, variables })
    cache.writeQuery({
      query,
      variables,
      data: {
        ...queryCache,
        ...updater(queryCache, data, variables),
      },
    })
  } catch (err) {}
}
export default cacheUpdate
