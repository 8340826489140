import React from "react"
import PropTypes from "prop-types"

import { compose, find } from "../../../../utils"
import { Column, DataType } from "../../../../ui"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { withMetaData } from "../../../metaData"

import {
  CreatedAtColumn,
  CreatedByColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  IdColumn,
  ResourceIndexPage,
} from "../../../resourcePage"

import withPermalink from "../../../routing/withPermalink"

import { canCreate } from "../../order/accessRights"
import { GOODS_MOVEMENT_LIST_QUERY, getGoodsMovementList } from "../dataSource"
import { orderPath, newOrderPath } from "../../order/paths"
import { pluralName } from "../metaInfo"
import { singleName } from "../../order/metaInfo"

function GoodsMovementIndexPage({
  auth: { userRole },
  metaData: { orderTypes },
  permalink,
}) {
  const orderType = find(orderTypes, { permalink })
  const {
    id: orderTypeId,
    name,
    reason,
    sourceContractor,
    sourceLocation,
    sourceGoodsState,
    targetContractor,
    targetLocation,
    targetGoodsState,
  } = orderType || {}

  return (
    <Query
      query={GOODS_MOVEMENT_LIST_QUERY}
      variables={{ orderTypeId }}
      skip={!orderType}
      fetchPolicy="no-cache"
    >
      {({ loading, errors, data }) => {
        return (
          <ResourceIndexPage
            canCreate={canCreate(userRole)}
            errors={orderType ? errors : ["Not Found"]}
            loading={loading}
            newResourcePath={newOrderPath(permalink)}
            pluralName={pluralName(name)}
            resources={{ items: getGoodsMovementList(data) }}
            resourcePath={orderPath}
            resourceIdField="orderId"
            singleName={singleName(name)}
            uniqueName={`goods-movements-${permalink}`}
          >
            <Column field="orderName" title="Order No." width={150} />
            <Column field="date" width={90} type={DataType.DATE} />
            <Column
              field="productTypeName"
              title="Product Type"
              width={130}
              type={DataType.DROP_DOWN}
            />
            <Column field="productCode" title="Product Number" width={150} />
            <Column field="productName" title="Product" width={300} />
            <Column
              field="languageName"
              title="Language"
              width={150}
              type={DataType.DROP_DOWN}
            />
            {sourceLocation && (
              <Column
                field="sourceLocationName"
                title={`${
                  sourceLocation && targetLocation ? "Source " : ""
                }Location`}
                width={130}
                type={DataType.DROP_DOWN}
              />
            )}
            {sourceGoodsState && (
              <Column
                field="sourceGoodsStateName"
                title={`${
                  sourceGoodsState && targetGoodsState ? "Source " : ""
                }Goods State`}
                width={130}
                type={DataType.DROP_DOWN}
              />
            )}
            {sourceContractor && (
              <Column
                field="sourceContractorName"
                title={sourceContractor}
                width={300}
              />
            )}
            {targetLocation && (
              <Column
                field="targetLocationName"
                title={`${
                  sourceLocation && targetLocation ? "Target " : ""
                }Location`}
                width={130}
                type={DataType.DROP_DOWN}
              />
            )}
            {targetGoodsState && (
              <Column
                field="targetGoodsStateName"
                title={`${
                  sourceGoodsState && targetGoodsState ? "Target " : ""
                }Goods State`}
                width={130}
                type={DataType.DROP_DOWN}
              />
            )}
            {targetContractor && (
              <Column
                field="targetContractorName"
                title={targetContractor}
                width={300}
              />
            )}
            <Column field="quantity" width={120} type={DataType.INTEGER} />
            {reason && <Column field="reason" width={500} />}
            <Column field="remark" width={500} />
            <UpdatedAtColumn />
            <UpdatedByColumn />
            <CreatedAtColumn />
            <CreatedByColumn />
            <IdColumn />
          </ResourceIndexPage>
        )
      }}
    </Query>
  )
}
GoodsMovementIndexPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string,
  }),
  metaData: PropTypes.shape({
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        reason: PropTypes.bool,
      }),
    ),
  }),
  permalink: PropTypes.string.isRequired,
}
export default compose(
  withAuthenticationContext,
  withPermalink,
  withMetaData,
)(GoodsMovementIndexPage)
