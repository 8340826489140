import React from "react"
import PropTypes from "prop-types"

import { keys, setIn, mapValues } from "../../utils"

import { FormProvider, FormConsumer } from "./FormContext"
import FormField from "./FormField"
import FormGroup from "./FormGroup"
import FormGroupField from "./FormGroupField"

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.constructor.getInitialState(props.initialValues)
    this.fieldValueChanged = this.fieldValueChanged.bind(this)
    this.fieldBlur = this.fieldBlur.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.resetForm = this.resetForm.bind(this)
  }

  static getInitialState(initialValues) {
    return {
      values: initialValues,
      touched: mapValues(initialValues, () => false),
      isSubmitting: false,
    }
  }

  static getErrors(values, validationSchema) {
    try {
      if (validationSchema) {
        if (typeof validationSchema === "function") {
          validationSchema = validationSchema(values)
        }
        validationSchema.validateSync(values, {
          abortEarly: false,
        })
      }
      return {}
    } catch (yupErrors) {
      if (yupErrors.name === "ValidationError") {
        if (yupErrors.inner.length) {
          return yupErrors.inner.reduce(
            (errors, err) => setIn(errors, err.path, err.message),
            {},
          )
        } else {
          return setIn({}, yupErrors.path, yupErrors.message)
        }
      }
      throw yupErrors
    }
  }

  touchAllErrors(errors) {
    this.setState({
      touched: mapValues(errors, () => true),
    })
  }

  fieldValueChanged({ target: { name, value } }) {
    const { updateValues } = this.props
    this.setState(state => ({
      values: updateValues(state.values, { [name]: value }),
    }))
  }
  fieldBlur({ target: { name } }) {
    this.setState(state => ({
      touched: { ...state.touched, [name]: true },
    }))
  }
  async submitForm(event) {
    const { onSubmit, validationSchema } = this.props
    const { values } = this.state

    event && event.preventDefault && event.preventDefault()

    const errors = this.constructor.getErrors(values, validationSchema)

    if (keys(errors).length) {
      this.touchAllErrors(errors)
    } else if (onSubmit) {
      this.setState({ isSubmitting: true })
      try {
        await onSubmit(mapValues(values, v => (v === "" ? null : v)))
      } finally {
        this.setState({ isSubmitting: false })
      }
    }
  }
  resetForm() {
    this.setState(this.constructor.getInitialState(this.props.initialValues))
  }
  render() {
    const { children, editing, initialValues, renderFormTag } = this.props

    const context = {
      editing,
      values: this.state.values,
      touched: this.state.touched,
      errors: this.constructor.getErrors(
        this.state.values,
        this.props.validationSchema,
      ),
      initialValues,
      onBlur: this.fieldBlur,
      onChange: this.fieldValueChanged,
      submitForm: this.submitForm,
      resetForm: this.resetForm,
      isSubmitting: this.state.isSubmitting,
    }
    const renderChildren =
      typeof children === "function" ? children(context) : children

    return (
      <FormProvider value={context}>
        {renderFormTag ? (
          <form onSubmit={this.submitForm} className="item-form">
            {renderChildren}
          </form>
        ) : (
          renderChildren
        )}
      </FormProvider>
    )
  }
}
function defaultUpdateValues(values, updatedValues) {
  return {
    ...values,
    ...updatedValues,
  }
}
Form.defaultProps = {
  editing: true,
  renderFormTag: true,
  updateValues: defaultUpdateValues,
}
Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  editing: PropTypes.bool,
  initialValues: PropTypes.object,
  renderFormTag: PropTypes.bool,
  onSubmit: PropTypes.func,
  updateValues: PropTypes.func,
  validationSchema: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}
Form.Consumer = FormConsumer
Form.Field = FormField
Form.Group = FormGroup
Form.GroupField = FormGroupField

export default Form
