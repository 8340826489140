import React from "react"
import PropTypes from "prop-types"

import { Column } from "../../../../ui"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import {
  CreatedAtColumn,
  CreatedByColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  IdColumn,
  ResourceIndexPage,
} from "../../../resourcePage"

import { canCreate } from "../accessRights"
import { getProductOwnerList, PRODUCT_OWNER_LIST_QUERY } from "../dataSource"
import { productOwnerPath, newProductOwnerPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"

function ProductOwnerIndexPage({ auth: { userRole } }) {
  return (
    <Query query={PRODUCT_OWNER_LIST_QUERY}>
      {({ loading, errors, data }) => {
        return (
          <ResourceIndexPage
            canCreate={canCreate(userRole)}
            uniqueName="product-owner"
            errors={errors}
            loading={loading}
            newResourcePath={newProductOwnerPath}
            pluralName={pluralName}
            resources={{ items: getProductOwnerList(data) }}
            resourcePath={productOwnerPath}
            singleName={singleName}
          >
            <Column field="name" width={150} />
            <Column field="remark" width={500} />
            <UpdatedAtColumn />
            <UpdatedByColumn />
            <CreatedAtColumn />
            <CreatedByColumn />
            <IdColumn />
          </ResourceIndexPage>
        )
      }}
    </Query>
  )
}
ProductOwnerIndexPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string,
  }),
}
export default withAuthenticationContext(ProductOwnerIndexPage)
