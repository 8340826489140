import React from "react"
import PropTypes from "prop-types"

import { object, string } from "../../../../utils/yup"

import { Card, TextInput } from "../../../../ui"

import { RemarkField } from "../../../resourcePage"

const Language = () => (
  <Card>
    <Card.Field component={TextInput} name="code" width={150} />
    <Card.Field component={TextInput} name="name" width={300} />
    <RemarkField />
  </Card>
)

Language.VALIDATION_SCHEMA = object().shape({
  code: string().required(),
  name: string().required(),
  remark: string(),
})
Language.initialValues = ({ code, name, remark }) => ({
  code: code || "",
  name: name || "",
  remark: remark || "",
})
Language.propTypes = {
  newPage: PropTypes.bool,
  editPage: PropTypes.bool,
}
export default Language
