import React from "react"
import PropTypes from "prop-types"

import { withAuthenticationContext } from "../../../../state_management/Authentication"

import { ResourceNewPage } from "../../../resourcePage"

import {
  CREATE_CONTRACTOR_MUTATION,
  getCreatedContractorItem,
  getResourceName,
  insertItemIntoCache,
} from "../dataSource"
import { canCreate } from "../accessRights"
import { contractorPath, contractorsPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"
import Contractor from "./Contractor"

function ContractorNewPage({ auth: { userRole } }) {
  return (
    <ResourceNewPage
      createMutation={CREATE_CONTRACTOR_MUTATION}
      errors={canCreate(userRole) ? undefined : ["Access denied."]}
      getResourceFromCreateMutation={getCreatedContractorItem}
      getResourceName={getResourceName}
      insertItemIntoCache={insertItemIntoCache}
      pluralName={pluralName}
      resourceComponent={Contractor}
      resourcePath={contractorPath}
      resourcesPath={contractorsPath}
      singleName={singleName}
    />
  )
}
ContractorNewPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
}
export default withAuthenticationContext(ContractorNewPage)
