import { addMethod, boolean, date, mixed, object, ref, string } from "yup"

addMethod(mixed, "sameAs", function(ref, message) {
  return this.test("sameAs", message, function(value) {
    let other = this.resolve(ref)

    return value === other
  })
})

export { boolean, date, object, ref, string }
