import React from "react"
import PropTypes from "prop-types"

import { Breadcrumbs, Classes } from "@blueprintjs/core"
import { Link } from "../state_management/Router"

const Breadcrumb = ({ text, href }) => (
  <Link to={href} className={Classes.BREADCRUMB}>
    {text}
  </Link>
)
Breadcrumb.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string.isRequired,
}

const CurrentBreadcrumb = ({ text, icon }) => (
  <React.Fragment>
    {icon}
    {text && <span className={Classes.BREADCRUMB_CURRENT}>{text}</span>}
  </React.Fragment>
)
CurrentBreadcrumb.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.element,
}

const AppBreadcrumbs = ({ items }) => (
  <Breadcrumbs
    items={items}
    breadcrumbRenderer={Breadcrumb}
    currentBreadcrumbRenderer={CurrentBreadcrumb}
  />
)
AppBreadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),
}

export default AppBreadcrumbs
