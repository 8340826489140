import React from "react"
import PropTypes from "prop-types"

import { ApolloProvider } from "react-apollo"

import apolloClient from "./apolloClient"

const DataProvider = ({
  cacheRedirects,
  children,
  getAuthToken,
  deleteAuthToken,
}) => (
  <ApolloProvider
    client={apolloClient({ cacheRedirects, getAuthToken, deleteAuthToken })}
  >
    {children}
  </ApolloProvider>
)

DataProvider.propTypes = {
  cacheRedirects: PropTypes.object,
  children: PropTypes.node,
  getAuthToken: PropTypes.func,
  deleteAuthToken: PropTypes.func,
}

export default DataProvider
