import PropTypes from "prop-types"

import createEnumIcon from "./createEnumIcon"
import { IconNames } from "./Icon"
import Intent from "./Intent"

const Checked = createEnumIcon({
  enumOptions: {
    false: { icon: IconNames.SMALL_CROSS, intent: Intent.DANGER },
    true: { icon: IconNames.SMALL_TICK, intent: Intent.SUCCESS },
  },
})
Checked.propTypes = {
  value: PropTypes.bool,
}
export default Checked
