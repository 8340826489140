import React from "react"
import PropTypes from "prop-types"

import { object, string } from "../../../../utils/yup"

import { filter, find } from "../../../../utils"
import {
  ActionsColumn,
  Column,
  DataTable,
  DataType,
  IconNames,
  SelectInput,
} from "../../../../ui"

import {
  CreatedAtColumn,
  CreatedByColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  IdColumn,
} from "../../../resourcePage"

import LeftInStockCell from "./LeftInStockCell"
import "./Movements.css"
import NotForPublicSaleCell from "./NotForPublicSaleCell"

const ProductItemRenderer = SelectInput.createItemRenderer({
  name: "Product",
  mainField: "nameWithLanguage",
  additionalField: "code",
  isSelected: item => item.notForPublicSale,
  selectedIcon: IconNames.BAN_CIRCLE,
})

const VALIDATION_SCHEME = object().shape({
  productId: string().required("Product is a required field"),
})

const dictionary = ({ id, name }) => ({ value: id, label: name })

function Movements({
  date,
  deleteMovement,
  editable,
  editingId,
  goodsStates,
  locations,
  movements,
  newMovementId,
  orderType: {
    sourceGoodsState,
    sourceLocation,
    sourceStockId,
    sourceStockName,
    sourceStockPositive,
    targetGoodsState,
    targetLocation,
    targetStockId,
    targetStockName,
    targetStockPositive,
  },
  products,
  productTypes,
  saveMovement,
  saveNewMovement,
  sourceContractorId,
  startEditing,
  stopEditing,
  targetContractorId,
  waitingId,
  waitingNew,
}) {
  const goodsStatesOptions = (goodsStates || []).map(dictionary)
  const sourceLocationsOptions = filter(locations || [], {
    stockId: sourceStockId,
  }).map(dictionary)
  const targetLocationsOptions = filter(locations || [], {
    stockId: targetStockId,
  }).map(dictionary)
  const productTypesOptions = (productTypes || []).map(dictionary)

  function rowValuesUpdater(data, updates) {
    const newValues = { ...data, ...updates }

    if (newValues.productId) {
      const product = find(products, { id: newValues.productId })
      if (newValues.productTypeId !== product.productTypeId) {
        newValues.productId = ""
      }
    }
    return newValues
  }
  function productItems(values) {
    return products.filter(
      ({ productTypeId }) => productTypeId === values.productTypeId,
    )
  }
  const newRowInitialValues = {
    productTypeId:
      productTypes && productTypes.length ? productTypes[0].id : "",
    quantity: "1",
  }
  if (sourceLocation) {
    newRowInitialValues.sourceLocationId = sourceLocationsOptions.length
      ? sourceLocationsOptions[0].value
      : ""
  }
  if (targetLocation) {
    newRowInitialValues.targetLocationId = targetLocationsOptions.length
      ? targetLocationsOptions[0].value
      : ""
  }
  if (sourceGoodsState || targetGoodsState) {
    const defaultGoodsStateId = goodsStates.length ? goodsStates[0].id : ""
    if (sourceGoodsState) {
      newRowInitialValues.sourceGoodsStateId = defaultGoodsStateId
    }
    if (targetGoodsState) {
      newRowInitialValues.targetGoodsStateId = defaultGoodsStateId
    }
  }

  return (
    <div
      id="order-movements"
      style={{ overflowX: "auto", paddingBottom: "20px" }}
    >
      <DataTable
        cancelEditingRow={stopEditing}
        data={movements}
        deleteRow={deleteMovement}
        editable={editable}
        editingRowId={editingId}
        newRowId={newMovementId}
        newRowInitialValues={newRowInitialValues}
        rowName="productName"
        rowValuesUpdater={rowValuesUpdater}
        saveNewRow={saveNewMovement}
        saveRow={saveMovement}
        startEditingRow={startEditing}
        validationSchema={VALIDATION_SCHEME}
        waitingNewRow={waitingNew}
        waitingRowId={waitingId}
      >
        <ActionsColumn />
        <Column
          editable={true}
          field="productTypeId"
          nameField="productTypeName"
          options={productTypesOptions}
          title="Product Type"
          type={DataType.DROP_DOWN}
          width={130}
        />
        <Column
          editable={true}
          field="productCode"
          itemRenderer={ProductItemRenderer}
          items={productItems}
          itemValue="code"
          keyField="productId"
          title="Product Number"
          type={DataType.SELECT}
          width={300}
        />
        <Column
          component={NotForPublicSaleCell}
          field="productNotForPublicSale"
          title="Sale?"
        />
        <Column
          editable={true}
          field="productName"
          itemRenderer={ProductItemRenderer}
          items={productItems}
          itemValue="nameWithLanguage"
          keyField="productId"
          title="Product"
          type={DataType.SELECT}
          width={300}
        />
        {sourceLocation && (
          <Column
            editable={true}
            field="sourceLocationId"
            nameField="sourceLocationName"
            options={sourceLocationsOptions}
            title={`${
              sourceLocation && targetLocation ? "Source " : ""
            }Location`}
            type={DataType.DROP_DOWN}
            width={130}
          />
        )}
        {sourceGoodsState && (
          <Column
            editable={true}
            field="sourceGoodsStateId"
            nameField="sourceGoodsStateName"
            options={goodsStatesOptions}
            title={`${
              sourceGoodsState && targetGoodsState ? "Source " : ""
            }Goods State`}
            type={DataType.DROP_DOWN}
            width={130}
          />
        )}
        {sourceStockPositive && (
          <Column
            contractorId={sourceContractorId}
            component={LeftInStockCell}
            date={date}
            field="sourceStockRemainQuantity"
            stockId={sourceStockId}
            title={`Left in ${sourceStockName}`}
            width={150}
            type={DataType.INTEGER}
          />
        )}

        {targetLocation && (
          <Column
            editable={true}
            field="targetLocationId"
            nameField="targetLocationName"
            options={targetLocationsOptions}
            title={`${
              sourceLocation && targetLocation ? "Target " : ""
            }Location`}
            type={DataType.DROP_DOWN}
            width={130}
          />
        )}
        {targetGoodsState && (
          <Column
            editable={true}
            field="targetGoodsStateId"
            nameField="targetGoodsStateName"
            options={goodsStatesOptions}
            title={`${
              sourceGoodsState && targetGoodsState ? "Target " : ""
            }Goods State`}
            type={DataType.DROP_DOWN}
            width={130}
          />
        )}
        {targetStockPositive && (
          <Column
            contractorId={targetContractorId}
            component={LeftInStockCell}
            date={date}
            field="targetStockRemainQuantity"
            stockId={targetStockId}
            title={`Left in ${targetStockName}`}
            width={150}
            type={DataType.INTEGER}
          />
        )}
        <Column
          editable={true}
          field="quantity"
          minValue={1}
          title="Moving Quantity"
          type={DataType.INTEGER}
          width={150}
        />
        <UpdatedAtColumn />
        <UpdatedByColumn />
        <CreatedAtColumn />
        <CreatedByColumn />
        <IdColumn />
      </DataTable>
    </div>
  )
}
Movements.defaultProps = {
  goodsStates: [],
  locations: [],
  products: [],
}
Movements.propTypes = {
  date: PropTypes.object,
  deleteMovement: PropTypes.func,
  editable: PropTypes.bool,
  editingId: PropTypes.string,
  goodsStates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  movements: PropTypes.arrayOf(PropTypes.object),
  newMovementId: PropTypes.string,
  orderType: PropTypes.shape({
    sourceGoodsState: PropTypes.bool,
    sourceLocation: PropTypes.bool,
    sourceStockId: PropTypes.string,
    sourceStockName: PropTypes.string,
    sourceStockPositive: PropTypes.bool,
    targetGoodsState: PropTypes.bool,
    targetLocation: PropTypes.bool,
    targetStockId: PropTypes.string,
    targetStockName: PropTypes.string,
    targetStockPositive: PropTypes.bool,
  }),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      productTypeId: PropTypes.string,
    }),
  ),
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  saveMovement: PropTypes.func,
  saveNewMovement: PropTypes.func,
  sourceContractorId: PropTypes.string,
  startEditing: PropTypes.func,
  stopEditing: PropTypes.func,
  targetContractorId: PropTypes.string,
  waitingId: PropTypes.string,
  waitingNew: PropTypes.bool,
}
export default Movements
