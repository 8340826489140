import React from "react"
import PropTypes from "prop-types"

import { values } from "../../utils"

import { Button, Intent, Spinner } from "@blueprintjs/core"

const createButton = ({ icon, title, intent: defaultIntent }) => {
  const CreatedButton = ({
    children,
    intent,
    waiting,
    disabled,
    onlyIcon,
    ...props
  }) => (
    <Button
      disabled={disabled || waiting}
      icon={waiting ? <Spinner size={16} /> : icon}
      intent={intent || defaultIntent || Intent.NONE}
      {...props}
    >
      {!onlyIcon && (children || title)}
    </Button>
  )
  CreatedButton.displayName = `${title}Button`
  CreatedButton.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    intent: PropTypes.oneOf(values(Intent)),
    onlyIcon: PropTypes.bool,
    waiting: PropTypes.bool,
    rest: PropTypes.object,
  }
  return CreatedButton
}
createButton.PropTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  intent: PropTypes.string,
}
export default createButton
