import React from "react"
import PropTypes from "prop-types"

import { Mutation } from "../../state_management/DataProvider"
import { withRedirect } from "../../state_management/Router"

import { ResourceItemPage } from "./item"

const ResourceEditPage = ({
  errors,
  getResourceName,
  id,
  initialValues,
  loading,
  pluralName,
  resourceComponent: ResourceComponent,
  resources,
  resourcePath,
  resourcesPath,
  router: { redirect },
  singleName,
  updateItemInCache,
  updateMutation,
  validationSchema,
}) => {
  function handleMutationCompleted(data) {
    redirect({
      backTo: resourcePath(id),
      flash: {
        success: { message: `${singleName} has been successfully updated.` },
      },
    })
  }

  return (
    <Mutation
      mutation={updateMutation}
      onCompleted={handleMutationCompleted}
      update={updateItemInCache}
    >
      {(mutate, { loading: mutating }) => {
        function handleSave(values) {
          mutate({ variables: { id: resources.item.id, ...values } })
        }

        function handleCancel() {
          redirect({ backTo: resourcePath(id) })
        }
        return (
          <ResourceItemPage
            editing
            errors={errors}
            initialValues={(initialValues || ResourceComponent.initialValues)(
              resources.item || {},
              resources,
            )}
            handleCancel={handleCancel}
            handleSave={handleSave}
            loading={loading}
            pluralName={pluralName}
            resource={resources.item}
            getResourceName={getResourceName}
            resourcesPath={resourcesPath}
            singleName={singleName}
            validationSchema={
              validationSchema || ResourceComponent.VALIDATION_SCHEMA
            }
            waiting={mutating}
          >
            <ResourceComponent editPage resources={resources} />
          </ResourceItemPage>
        )
      }}
    </Mutation>
  )
}

ResourceEditPage.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  getResourceName: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  initialValues: PropTypes.func,
  loading: PropTypes.bool,
  pluralName: PropTypes.string.isRequired,
  resourceComponent: PropTypes.func.isRequired,
  resources: PropTypes.shape({
    item: PropTypes.object,
  }),
  resourcePath: PropTypes.func.isRequired,
  resourcesPath: PropTypes.func.isRequired,
  router: PropTypes.shape({
    redirect: PropTypes.func.isRequired,
  }).isRequired,
  singleName: PropTypes.string.isRequired,
  updateItemInCache: PropTypes.func,
  updateMutation: PropTypes.object.isRequired,
  validationSchema: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}
export default withRedirect(ResourceEditPage)
