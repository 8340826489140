import React from "react"
import PropTypes from "prop-types"

import { object, string } from "../../../../utils/yup"

import { Card, TextInput } from "../../../../ui"

import { RemarkField } from "../../../resourcePage"

function GoodsState() {
  return (
    <Card>
      <Card.Field component={TextInput} name="name" width={300} />
      <RemarkField />
    </Card>
  )
}
GoodsState.VALIDATION_SCHEMA = object().shape({
  name: string().required(),
  remark: string(),
})
GoodsState.initialValues = ({ name, remark }) => ({
  name: name || "",
  remark: remark || "",
})
GoodsState.propTypes = {
  newPage: PropTypes.bool,
  editPage: PropTypes.bool,
}
export default GoodsState
