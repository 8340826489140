import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../utils"

import { Alignment, Toolbar } from "../../ui"

import { withAuthenticationContext } from "../../state_management/Authentication"
import { withMetaData } from "../metaData"

import {
  DictionariesMenu,
  GoodsMovementsMenu,
  OrdersMenu,
  ProfileMenu,
  StockMenu,
  StockMovementsMenu,
} from "../menu"

import logo from "./logo.png"

function Header({
  auth: { userRole, userName, userId },
  metaDataErrors,
  metaDataLoading,
}) {
  return (
    <Toolbar dark logo={logo} title="Wisdom Treasury Stock">
      {userRole &&
        !metaDataLoading &&
        !metaDataErrors && (
          <Toolbar.Group align={Alignment.LEFT}>
            <Toolbar.Divider />
            <Toolbar.Menu>
              <StockMenu />
              <StockMovementsMenu />
              <GoodsMovementsMenu />
              <OrdersMenu />
              <DictionariesMenu />
            </Toolbar.Menu>
          </Toolbar.Group>
        )}
      {userRole && (
        <Toolbar.Group align={Alignment.RIGHT}>
          <Toolbar.Menu>
            <ProfileMenu userName={userName} userId={userId} />
          </Toolbar.Menu>
        </Toolbar.Group>
      )}
    </Toolbar>
  )
}
Header.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string,
    userName: PropTypes.string,
    userId: PropTypes.string,
  }),
  metaDataLoading: PropTypes.bool,
  metaDataErrors: PropTypes.arrayOf(PropTypes.string),
}
export default compose(
  withAuthenticationContext,
  withMetaData,
)(Header)
