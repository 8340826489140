import React from "react"
import PropTypes from "prop-types"

import { Provider } from "./Context"
import { Toaster, Intent } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

const toaster = Toaster.create({ className: "recipe-toaster" })
const showFlashMessage = ({ intent, icon }) => ({
  message,
  onDismiss,
  action,
  timeout,
}) =>
  toaster.show({
    message,
    onDismiss,
    action,
    intent,
    icon,
    timeout,
  })

const flash = {
  success: showFlashMessage({
    intent: Intent.SUCCESS,
    icon: IconNames.THUMBS_UP,
  }),
  alert: showFlashMessage({
    intent: Intent.DANGER,
    icon: IconNames.ERROR,
  }),
  warning: showFlashMessage({
    intent: Intent.WARNING,
    icon: IconNames.WARNING_SIGN,
  }),
  clear: toaster.clear.bind(toaster),
}

const FlashProvider = ({ children }) => (
  <Provider value={{ flash }}>{children}</Provider>
)
FlashProvider.propTypes = {
  children: PropTypes.node,
}
export default FlashProvider
