import React from "react"
import PropTypes from "prop-types"

import { Mutation } from "../../state_management/DataProvider"
import { withRedirect } from "../../state_management/Router"

import { ResourceItemPage } from "./item"

function ResourceNewPage({
  createMutation,
  errors,
  getResourceFromCreateMutation,
  getResourceName,
  indexPageVariables,
  initialValues,
  insertItemIntoCache,
  loading,
  pluralName,
  redirectToCreatedResource,
  resourceComponent: ResourceComponent,
  resourcePath,
  resources,
  resourcesPath,
  router: { redirect, state: referrerState },
  singleName,
  validationSchema,
}) {
  function handleMutationCompleted(data) {
    const id = getResourceFromCreateMutation(data).id

    const flash = {
      success: {
        message: `New ${singleName} has been successfully created.`,
      },
    }

    if (referrerState && referrerState.item) {
      redirect({
        to: resourcePath(id),
        state:
          (referrerState.referrer && referrerState.referrer.state) || undefined,
        flash,
      })
    } else if (redirectToCreatedResource) {
      redirect({
        to: resourcePath(id),
        state:
          referrerState && referrerState.referrer
            ? { referrer: referrerState.referrer }
            : undefined,
        flash,
      })
    } else {
      redirect({ backTo: resourcePath(id), flash })
    }
  }

  return (
    <Mutation mutation={createMutation} onCompleted={handleMutationCompleted}>
      {(mutate, { loading: mutating }) => {
        function handleSave(values) {
          mutate({
            variables: values,
            update: insertItemIntoCache(indexPageVariables),
          })
        }

        function handleCancel() {
          redirect({ backTo: resourcesPath() })
        }

        return (
          <ResourceItemPage
            editing
            errors={errors}
            getResourceName={getResourceName}
            handleCancel={handleCancel}
            handleSave={handleSave}
            initialValues={(initialValues || ResourceComponent.initialValues)(
              (resources || {}).item || {},
              resources,
            )}
            loading={loading}
            pluralName={pluralName}
            resourcesPath={resourcesPath}
            singleName={singleName}
            validationSchema={
              validationSchema || ResourceComponent.VALIDATION_SCHEMA
            }
            waiting={mutating}
          >
            <ResourceComponent newPage resources={{ item: {}, ...resources }} />
          </ResourceItemPage>
        )
      }}
    </Mutation>
  )
}

ResourceNewPage.propTypes = {
  createMutation: PropTypes.object.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  getResourceFromCreateMutation: PropTypes.func.isRequired,
  getResourceName: PropTypes.func.isRequired,
  indexPageVariables: PropTypes.object,
  initialValues: PropTypes.func,
  insertItemIntoCache: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  pluralName: PropTypes.string.isRequired,
  redirectToCreatedResource: PropTypes.bool,
  resourceComponent: PropTypes.func.isRequired,
  resourcePath: PropTypes.func.isRequired,
  resources: PropTypes.shape({
    item: PropTypes.object,
  }),
  resourcesPath: PropTypes.func.isRequired,
  router: PropTypes.shape({
    redirect: PropTypes.func.isRequired,
    state: PropTypes.object,
  }).isRequired,
  singleName: PropTypes.string.isRequired,
  validationSchema: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}
export default withRedirect(ResourceNewPage)
