import React from "react"

import { FormConsumer } from "./FormContext"

function withForm(WrappedComponent) {
  function WithForm({ children, ...props }) {
    return (
      <FormConsumer>
        {form => (
          <WrappedComponent {...props} form={form}>
            {children}
          </WrappedComponent>
        )}
      </FormConsumer>
    )
  }
  WithForm.displayName = `WithForm(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`
  return WithForm
}
export default withForm
