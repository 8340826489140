import { gql } from "../../../state_management/DataProvider"

const fields = `
  id,
  sourceStockId,
  targetStockId,
  sourceGoodsStateId,
  sourceLocationId,
  targetGoodsStateId,
  targetLocationId,
  orderId,
  orderName,
  date,
  productTypeName,
  productCode,
  productName,
  languageName,
  quantity,
  reason,
  remark,
  createdAt,
  createdBy,
  updatedAt,
  updatedBy
`
const inFields = `
  goodsStateId: targetGoodsStateId,
  goodsStateName: targetGoodsStateName,
  locationId: targetLocationId,
  locationName: targetLocationName,
  contractorName: targetContractorName,
  action: targetAction,
  remainQuantity: targetStockRemainQuantity,
`
const outFields = `
  goodsStateId: sourceGoodsStateId,
  goodsStateName: sourceGoodsStateName,
  locationId: sourceLocationId,
  locationName: sourceLocationName,
  contractorName: sourceContractorName,
  action: sourceAction,
  remainQuantity: sourceStockRemainQuantity,
`

export const STOCK_MOVEMENT_LIST_QUERY = gql`
  query StockMovements($stockId: BigInt!) {
    movementsIn: allMovementsList(condition: {targetStockId: $stockId}) { ${fields} ${inFields} }
    movementsOut: allMovementsList(condition: {sourceStockId: $stockId}) { ${fields} ${outFields} }
  }
`
export const getStockMovementList = data =>
  ((data || {}).movementsIn || []).concat((data || {}).movementsOut || [])
