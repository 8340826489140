import React from "react"
import PropTypes from "prop-types"

import {
  Icon,
  IconNames,
  IntegerCell,
  Intent,
  Spinner,
  Tooltip,
} from "../../../../ui"

import { Query } from "../../../../state_management/DataProvider"
import { STOCK_QUANTITY_ITEM_QUERY, getStockQuantityItem } from "./dataSource"

function LeftInStockCell({
  data: {
    contractorId,
    productId,
    sourceGoodsStateId,
    sourceLocationId,
    targetGoodsStateId,
    targetLocationId,
    sourceStockRemainQuantity,
    targetStockRemainQuantity,
    quantity,
  },
  date,
  field,
  initialValues,
  stockId,
  value,
  values: {
    productId: newProductId,
    sourceGoodsStateId: newSourceGoodsStateId,
    sourceLocationId: newSourceLocationId,
    targetGoodsStateId: newTargetGoodsStateId,
    targetLocationId: newTargetLocationId,
    quantity: newQuantity,
  },
  ...rest
}) {
  // prettier-ignore
  const [sign, goodsStateId, locationId, stockRemainQuantity, newGoodsStateId, newLocationId] =
    field === "sourceStockRemainQuantity"
      ? [1, sourceGoodsStateId, sourceLocationId, sourceStockRemainQuantity, newSourceGoodsStateId, newSourceLocationId]
      : [-1, targetGoodsStateId, targetLocationId, targetStockRemainQuantity, newTargetGoodsStateId, newTargetLocationId]

  const calculable = newProductId && newGoodsStateId
  const requestNeeded =
    productId !== newProductId ||
    goodsStateId !== newGoodsStateId ||
    locationId !== newLocationId

  return (
    <Query
      query={STOCK_QUANTITY_ITEM_QUERY}
      variables={{
        date: new Date(date),
        stockId,
        productId: newProductId,
        goodsStateId: newGoodsStateId,
        locationId: newLocationId,
        contractorId,
      }}
      skip={!calculable || !requestNeeded}
      fetchPolicy="no-cache"
    >
      {({ loading, errors, data }) => {
        let newValue = null

        if (calculable) {
          let stockQuantity = +stockRemainQuantity

          if (requestNeeded) {
            if (loading) {
              return <Spinner size={Spinner.SIZE_SMALL} />
            }
            if (errors) {
              return (
                <Tooltip content={errors.join(" ")}>
                  <Icon
                    icon={IconNames.WARNING_SIGN}
                    size={Icon.SIZE_LARGE}
                    intent={Intent.DANGER}
                  />
                </Tooltip>
              )
            }
            stockQuantity = +getStockQuantityItem(data)
          }

          newValue = stockQuantity + sign * ((+quantity || 0) - +newQuantity)
        }

        return (
          <IntegerCell
            intent={newValue < 0 ? Intent.DANGER : Intent.NONE}
            value={newValue || value}
            {...rest}
          />
        )
      }}
    </Query>
  )
}
LeftInStockCell.propTypes = {
  data: PropTypes.shape({
    contractorId: PropTypes.string,
    productId: PropTypes.string,
    sourceGoodsStateId: PropTypes.string,
    sourceLocationId: PropTypes.string,
    targetGoodsStateId: PropTypes.string,
    targetLocationId: PropTypes.string,
    sourceStockRemainQuantity: PropTypes.string,
    targetStockRemainQuantity: PropTypes.string,
    quantity: PropTypes.string,
  }),
  date: PropTypes.object,
  field: PropTypes.string,
  initialValues: PropTypes.object,
  stockId: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.shape({
    productId: PropTypes.string,
    sourceGoodsStateId: PropTypes.string,
    sourceLocationId: PropTypes.string,
    targetGoodsStateId: PropTypes.string,
    targetLocationId: PropTypes.string,
    quantity: PropTypes.string,
  }),
  rest: PropTypes.object,
}
export default LeftInStockCell
