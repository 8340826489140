import React from "react"
import PropTypes from "prop-types"

import { IconNames } from "../../ui"

import { withMetaData } from "../metaData"

import { goodsMovementsPath } from "../domains/goodsMovement/paths"
import MenuItems from "./MenuItems"

function GoodsMovementsMenu({
  metaDataLoading,
  metaDataErrors,
  metaData: { orderTypes },
}) {
  return (
    <MenuItems
      icon={metaDataErrors && IconNames.WARNING_SIGN}
      loading={metaDataLoading}
      items={orderTypes}
      path={goodsMovementsPath}
      title="Goods Movements"
    />
  )
}
GoodsMovementsMenu.propTypes = {
  metaDataLoading: PropTypes.bool,
  metaDataErrors: PropTypes.arrayOf(PropTypes.string),
  metaData: PropTypes.shape({
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        permalink: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
}
export default withMetaData(GoodsMovementsMenu)
