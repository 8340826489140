import React from "react"
import PropTypes from "prop-types"

import { reject } from "../../../../utils"

import { object, string } from "../../../../utils/yup"

import { Card, DropDownInput, TextInput } from "../../../../ui"

import { RemarkField } from "../../../resourcePage"

function Location({
  resources: {
    item: { stockName },
    stocks,
  },
}) {
  const stockOptions = (stocks || []).map(({ id, name }) => ({
    label: name,
    value: id,
  }))
  return (
    <Card>
      <Card.Field
        component={DropDownInput}
        name="stockId"
        label="Stock:"
        options={stockOptions}
        textValue={stockName}
        width={200}
      />
      <Card.Field
        component={TextInput}
        name="name"
        label="Location"
        width={500}
      />
      <RemarkField />
    </Card>
  )
}
Location.VALIDATION_SCHEMA = object().shape({
  name: string().required(),
  remark: string(),
})
Location.locationableStocks = stocks =>
  reject(stocks, ({ location }) => !location)
Location.initialValues = ({ stockId, name, remark }, resources) => {
  const defaultStockId =
    resources &&
    resources.stocks &&
    resources.stocks.length &&
    resources.stocks[0].id
  return {
    stockId: stockId || defaultStockId || "",
    name: name || "",
    remark: remark || "",
  }
}
Location.propTypes = {
  newPage: PropTypes.bool,
  editPage: PropTypes.bool,
  resources: PropTypes.shape({
    item: PropTypes.shape({
      stockName: PropTypes.string,
    }),
    stocks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
}
export default Location
