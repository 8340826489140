import { cacheUpdate, gql } from "../../../state_management/DataProvider"

import { singleName } from "./metaInfo"

import { reject } from "../../../utils"

export const fields =
  "id, name, remark, createdAt, createdBy, updatedAt, updatedBy"

const inputParams = "$name: String, $remark: String"
const inputFields = "name: $name, remark: $remark"

export const GOODS_STATE_LIST_QUERY = gql`
  query GoodsStates {
    allGoodsStatesList { ${fields} }
  }
`
export const GOODS_STATE_ITEM_QUERY = gql`
  query GoodsState($id: BigInt!) {
    goodsStateById(id: $id) { ${fields} }
  }
`

export const CREATE_GOODS_STATE_MUTATION = gql`
  mutation CreateGoodsState(${inputParams}) {
    createGoodsState(input: { ${inputFields} }) {
      goodsState { ${fields} }
    }
  }
`
export const UPDATE_GOODS_STATE_MUTATION = gql`
  mutation UpdateGoodsState($id: BigInt!, ${inputParams}) {
    updateGoodsState(input: { id: $id, ${inputFields} }) {
      goodsState { ${fields} }
    }
  }
`
export const DELETE_GOODS_STATE_MUTATION = gql`
  mutation DeleteGoodsState($id: BigInt!) {
    deleteGoodsState(input: { id: $id }) {
      goodsState {
        id
        name
      }
    }
  }
`
export const getGoodsStateList = data => (data || {}).allGoodsStatesList || []
export const getGoodsStateItem = data => (data || {}).goodsStateById

export const getCreatedGoodsStateItem = data => data.createGoodsState.goodsState
export const getDeletedGoodsStateId = data =>
  data.deleteGoodsState.goodsState.id

export const insertItemIntoCache = cacheUpdate(
  GOODS_STATE_LIST_QUERY,
  (cache, data) => ({
    allGoodsStatesList: getGoodsStateList(cache).concat([
      getCreatedGoodsStateItem(data),
    ]),
  }),
)
export const deleteItemFromCache = cacheUpdate(
  GOODS_STATE_LIST_QUERY,
  (cache, data) => {
    return {
      allGoodsStatesList: reject(getGoodsStateList(cache), {
        id: getDeletedGoodsStateId(data),
      }),
    }
  },
)

export const getResourceName = resource =>
  (resource && resource.name) || `New ${singleName}`

export const cacheRedirects = {
  goodsStateById: (_, args, { getCacheKey }) =>
    getCacheKey({ __typename: "GoodsState", id: args.id }),
}
