import React from "react"
import PropTypes from "prop-types"

import { classNames, getIn } from "../../utils"

import DeleteConfirmation from "../DeleteConfirmation"
import { FormField, withForm } from "../Form"

import { DefaultActionsCell } from "./cells"

function Row({
  columns,
  data,
  editable,
  editing,
  form: { submitForm, values, initialValues, touched, errors },
  onCancel,
  onDelete,
  onEdit,
  rowName,
  waiting,
}) {
  function handleEdit() {
    onEdit(data)
  }
  function handleDelete() {
    onDelete(data)
  }

  const itemName = typeof rowName === "function" ? rowName(data) : data[rowName]

  return (
    <tr className={classNames([editing && "editing"])}>
      {columns.map(
        ({
          field,
          cell: Cell,
          editable: editableColumn,
          itemKey,
          itemRenderer,
          items,
          itemValue,
          keyField,
          minValue,
          nameField,
          options,
          type,
          ...rest
        }) => {
          const fieldItems = typeof items === "function" ? items(values) : items
          const invalid =
            editing &&
            editableColumn &&
            getIn(touched, field) &&
            getIn(errors, field)

          return (
            <td
              key={field}
              className={classNames([
                type,
                field,
                editing && editableColumn && "editing",
                invalid && "error",
              ])}
            >
              {Cell === DefaultActionsCell ? (
                <DeleteConfirmation
                  handleDelete={handleDelete}
                  item={itemName || ""}
                >
                  {({ handleDelete }) => (
                    <Cell
                      disabled={!editable}
                      editing={editing}
                      onCancel={onCancel}
                      onDelete={handleDelete}
                      onEdit={handleEdit}
                      onSave={submitForm}
                      waiting={waiting}
                    />
                  )}
                </DeleteConfirmation>
              ) : editing && editableColumn ? (
                <FormField
                  component={Cell}
                  fullWidthComponent
                  itemKey={itemKey}
                  itemRenderer={itemRenderer}
                  items={fieldItems}
                  itemValue={itemValue}
                  keyName={keyField}
                  minValue={minValue}
                  name={field}
                  editing={true}
                  options={options}
                  textValue={data[nameField]}
                  waiting={waiting}
                />
              ) : (
                <Cell
                  data={data}
                  field={field}
                  initialValues={initialValues}
                  textValue={data[nameField || field]}
                  value={data[field]}
                  values={values}
                  {...rest}
                />
              )}
            </td>
          )
        },
      )}
    </tr>
  )
}
Row.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cell: PropTypes.func,
      field: PropTypes.string,
      editable: PropTypes.bool,
      itemRenderer: PropTypes.func,
      items: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
        ),
      ]),
      nameField: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string.isRequired,
        }),
      ),
      type: PropTypes.string,
    }),
  ),
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
  editable: PropTypes.bool,
  editing: PropTypes.bool,
  form: PropTypes.shape({
    submitForm: PropTypes.func,
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
  }),
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  rowName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  waiting: PropTypes.bool,
}
export default withForm(Row)
