import { ApolloLink } from "apollo-link"
import { onError } from "apollo-link-error"
import { HttpLink } from "apollo-link-http"

import isUnathorized from "./isUnathorized"

const httpLink = ({ deleteAuthToken }) =>
  ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        )
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        if (isUnathorized({ graphQLErrors, networkError })) deleteAuthToken()
      }
    }),
    new HttpLink({
      credentials: "same-origin",
      uri: "/graphql",
    }),
  ])

export default httpLink
