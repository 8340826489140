const extractErrors = apolloError => {
  if (!apolloError) return undefined

  const errors =
    (apolloError.graphQLErrors &&
      apolloError.graphQLErrors.map(({ message }) => message)) ||
    []
  apolloError.networkError &&
    apolloError.networkError.message &&
    errors.push(apolloError.networkError.message)
  return errors
}
export default extractErrors
