import { Column, DataType } from "../../../ui"

class CreatedAtColumn extends Column {}
CreatedAtColumn.defaultProps = {
  field: "createdAt",
  type: DataType.DATE_TIME,
  width: 200,
}

class CreatedByColumn extends Column {}
CreatedByColumn.defaultProps = {
  field: "createdBy",
  width: 200,
  type: DataType.DROP_DOWN,
}

class UpdatedAtColumn extends Column {}
UpdatedAtColumn.defaultProps = {
  field: "updatedAt",
  type: DataType.DATE_TIME,
  width: 200,
}

class UpdatedByColumn extends Column {}
UpdatedByColumn.defaultProps = {
  field: "updatedBy",
  width: 200,
  type: DataType.DROP_DOWN,
}

class IdColumn extends Column {}
IdColumn.defaultProps = {
  field: "id",
  title: "ID",
  type: DataType.ID,
  width: 100,
}

export {
  CreatedAtColumn,
  CreatedByColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  IdColumn,
}
