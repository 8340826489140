import { cacheUpdate, gql } from "../../../state_management/DataProvider"

import { singleName } from "./metaInfo"

import { reject } from "../../../utils"

import { ORDER_LIST_QUERY } from "../order/dataSource"

export const fields =
  "id, name, phone, email, remark, createdAt, createdBy, updatedAt, updatedBy"

const inputParams =
  "$name: String, $phone: String, $email: String, $remark: String"
const inputFields = "name: $name, phone: $phone, email: $email, remark: $remark"

export const CONTRACTOR_LIST_QUERY = gql`
  query Contractor {
    allContractorsList { ${fields} }
  }
`
export const CONTRACTOR_ITEM_QUERY = gql`
  query Contractor($id: BigInt!) {
    contractorById(id: $id) { ${fields} }
  }
`
export const CREATE_CONTRACTOR_MUTATION = gql`
  mutation CreateContractor(${inputParams}) {
    createContractor(input: { ${inputFields} }) {
      contractor { ${fields} }
    }
  }
`
export const UPDATE_CONTRACTOR_MUTATION = gql`
  mutation UpdateContractor($id: BigInt!, ${inputParams}) {
    updateContractor(input: { id: $id, ${inputFields} }) {
      contractor { ${fields} }
    }
  }
`
export const DELETE_CONTRACTOR_MUTATION = gql`
  mutation DeleteContractor($id: BigInt!) {
    deleteContractor(input: { id: $id }) {
      contractor {
        id
        name
      }
    }
  }
`
export const getContractorList = data => (data || {}).allContractorsList || []
export const getContractorItem = data => (data || {}).contractorById

export const getCreatedContractorItem = data => data.createContractor.contractor
export const getDeletedContractorId = data =>
  data.deleteContractor.contractor.id

export const insertItemIntoCache = cacheUpdate(
  CONTRACTOR_LIST_QUERY,
  (cache, data) => ({
    allContractorsList: getContractorList(cache).concat([
      getCreatedContractorItem(data),
    ]),
  }),
)
export const updateItemInCache = cacheUpdate(
  ORDER_LIST_QUERY,
  (cache, { updateContractor: { contractor } }) => ({
    allOrdersList: cache.allOrdersList.map(
      order =>
        order.sourceContractorId === contractor.id ||
        order.targetContractorId === contractor.id
          ? {
              ...order,
              sourceContractorName:
                order.sourceContractorId === contractor.id
                  ? contractor.name
                  : order.sourceContractorName,
              targetContractorName:
                order.targetContractorId === contractor.id
                  ? contractor.name
                  : order.targetContractorName,
            }
          : order,
    ),
  }),
)
export const deleteItemFromCache = cacheUpdate(
  CONTRACTOR_LIST_QUERY,
  (cache, data) => {
    return {
      allContractorsList: reject(getContractorList(cache), {
        id: getDeletedContractorId(data),
      }),
    }
  },
)

export const getResourceName = resource =>
  (resource && resource.name) || `New ${singleName}`

export const cacheRedirects = {
  goodsStateById: (_, args, { getCacheKey }) =>
    getCacheKey({ __typename: "Contractor", id: args.id }),
}
