import React from "react"
import PropTypes from "prop-types"

import { startCase } from "../../../../utils"

import { Tag } from "../../../../ui"

import roleTagColors from "./roleTagColors"

const RoleTag = ({ active, role }) => (
  <Tag round {...roleTagColors({ active, role })}>
    {startCase(role)}
  </Tag>
)
RoleTag.propTypes = {
  active: PropTypes.bool,
  role: PropTypes.string,
}
export default RoleTag
