import React from "react"
import PropTypes from "prop-types"

import { Intent, TextArea } from "@blueprintjs/core"

import "./Input.css"
import "./TextAreaInput.css"

function TextAreaInput({
  disabled,
  editing,
  large,
  name,
  onBlur,
  onChange,
  style,
  valid,
  value,
  width,
}) {
  const newStyle = {
    resize: editing ? "vertical" : "none",
    ...(width ? { width } : {}),
    ...style,
  }
  return (
    <TextArea
      disabled={disabled}
      intent={valid ? Intent.NONE : Intent.DANGER}
      large={large}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      readOnly={!editing}
      style={newStyle}
      value={value}
    />
  )
}
TextAreaInput.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.object,
  valid: PropTypes.bool,
  value: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default TextAreaInput
