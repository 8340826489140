import React from "react"
import PropTypes from "prop-types"

import "./CardTable.css"

function CardTable({ children }) {
  return <div className="card-table">{children}</div>
}
CardTable.propTypes = {
  children: PropTypes.node,
}
export default CardTable
