import PropTypes from "prop-types"

import "./IntegerCell.css"

function IdCell({ value }) {
  return value || null
}
IdCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export default IdCell
