import React from "react"

import { Query } from "react-apollo"

import ErrorHandler from "./ErrorHandler"
import extractErrors from "./extractErrors"

const QueryWithErrors = ({ children, ...props }) => (
  <Query {...props}>
    {({ error, ...rest }) =>
      children({ errors: extractErrors(error), error, ...rest })
    }
  </Query>
)
export default ErrorHandler(QueryWithErrors)
