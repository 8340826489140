import React from "react"
import PropTypes from "prop-types"

import { Provider } from "./Context"
import { withAuthenticationContext } from "../../state_management/Authentication"
import { Query, gql } from "../../state_management/DataProvider"

const META_DATA_QUERY = gql`
  query MetaData {
    allOrderTypesList(orderBy: ORDER_NO_ASC) {
      id
      name
      reason
      permalink
      orderNo
      followedBySeparator
      sourceContractor
      sourceLocation
      sourceGoodsState
      sourceStockId
      sourceStockName
      sourceStockPositive
      targetContractor
      targetLocation
      targetGoodsState
      targetStockId
      targetStockName
      targetStockPositive
    }
    allStocksList(orderBy: ORDER_NO_ASC) {
      id
      name
      goodsState
      location
      contractor
      positive
      permalink
      orderNo
      followedBySeparator
    }
  }
`

function MetaDataProvider({ auth: { userId: authenticated }, children }) {
  return (
    <Query query={META_DATA_QUERY} skip={!authenticated}>
      {({ loading, errors, data }) => {
        return (
          <Provider
            value={{
              status: { errors, loading },
              metaData: {
                orderTypes: (data && data.allOrderTypesList) || [],
                stocks: (data && data.allStocksList) || [],
              },
            }}
          >
            {children}
          </Provider>
        )
      }}
    </Query>
  )
}
MetaDataProvider.propTypes = {
  auth: PropTypes.shape({
    userId: PropTypes.string,
  }),
  children: PropTypes.node,
}
export default withAuthenticationContext(MetaDataProvider)
