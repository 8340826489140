import React from "react"
import PropTypes from "prop-types"

import { NonIdealState } from "@blueprintjs/core"

const Error = ({ errors, title }) => {
  if (!errors) return null

  const errorsDescription = errors.join(" ")
  return (
    <NonIdealState
      icon="error"
      title={title}
      description={
        errorsDescription.length ? errorsDescription : "Unknown Error"
      }
    />
  )
}

Error.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}
Error.defaultProps = {
  title: "Error",
}
export default Error
