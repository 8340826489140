import React from "react"

import { Consumer } from "./Context"

function withMetaData(WrappedComponent) {
  function WithMetaData({ children, ...rest }) {
    return (
      <Consumer>
        {({ status: { loading, errors }, metaData }) => (
          <WrappedComponent
            {...rest}
            metaData={metaData}
            metaDataLoading={loading}
            metaDataErrors={errors}
          >
            {children}
          </WrappedComponent>
        )}
      </Consumer>
    )
  }
  WithMetaData.displayName = `WithMetaData(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`
  return WithMetaData
}
export default withMetaData
