import React from "react"
import PropTypes from "prop-types"

import { object, string } from "../../../../utils/yup"

import { Card, CheckboxInput, TextInput } from "../../../../ui"

import { RemarkField } from "../../../resourcePage"

const ProductType = () => (
  <Card>
    <Card.Field component={TextInput} name="name" width={300} />
    <Card.Field
      component={CheckboxInput}
      name="codeIsMandatory"
      label="Number required"
    />
    <RemarkField />
  </Card>
)

ProductType.VALIDATION_SCHEMA = object().shape({
  name: string().required(),
  remark: string(),
})
ProductType.initialValues = ({ name, codeIsMandatory, remark }) => ({
  name: name || "",
  codeIsMandatory: codeIsMandatory || false,
  remark: remark || "",
})
ProductType.propTypes = {
  newPage: PropTypes.bool,
  editPage: PropTypes.bool,
}
export default ProductType
