import React from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { compose } from "../../utils"
import { withFlash } from "../../ui/Flash" // Unclear why it doesn't work just for "../../ui"

class Redirector extends React.Component {
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => this.props.flash.clear())
  }

  componentWillUnmount() {
    this.unlisten && this.unlisten()
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}
Redirector.propTypes = {
  children: PropTypes.node.isRequired,
  flash: PropTypes.shape({
    clear: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
  }).isRequired,
}
export default compose(
  withFlash,
  withRouter,
)(Redirector)
