import React from "react"
import PropTypes from "prop-types"

import { Mutation } from "../../state_management/DataProvider"
import { withRedirect } from "../../state_management/Router"

import { ResourceItemPage } from "./item"

function ResourceShowPage({
  actionToolbar,
  additionalEditButton,
  canCreate,
  canDelete,
  canUpdate,
  deleteItemFromCache,
  deleteMutation,
  editResourcePath,
  errors,
  getResourceName,
  handleActions,
  handleAdditionalEdit,
  id,
  indexPageVariables,
  initialValues,
  loading,
  newResourcePath,
  pluralName,
  resourceComponent: ResourceComponent,
  resources,
  resourcesPath,
  singleName,
  router: { redirect, state },
  validationSchema,
  waiting,
}) {
  function handleMutationCompleted() {
    redirect({
      backTo: resourcesPath(),
      flash: {
        success: { message: `${singleName} has been successfully deleted.` },
      },
    })
  }
  function handleEdit() {
    redirect({ to: editResourcePath(id), withReferrer: true })
  }
  function handleNew() {
    redirect({
      to: newResourcePath(),
      withReferrer: true,
      state: { item: true },
    })
  }
  function handleBack() {
    redirect({ backTo: true })
  }

  return (
    <Mutation
      mutation={deleteMutation}
      onCompleted={handleMutationCompleted}
      update={deleteItemFromCache(indexPageVariables)}
    >
      {(mutate, { loading: mutating }) => {
        function handleDelete() {
          mutate({ variables: { id } })
        }

        return (
          <ResourceItemPage
            actionToolbar={actionToolbar}
            additionalEditButton={additionalEditButton}
            canCreate={canCreate}
            canDelete={canDelete}
            canUpdate={canUpdate}
            errors={errors}
            getResourceName={getResourceName}
            handleActions={handleActions}
            handleAdditionalEdit={handleAdditionalEdit}
            handleBack={state && state.referrer && handleBack}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleNew={handleNew}
            initialValues={(initialValues || ResourceComponent.initialValues)(
              resources.item || {},
              resources,
            )}
            loading={loading}
            pluralName={pluralName}
            resource={resources.item}
            resourcesPath={resourcesPath}
            singleName={singleName}
            validationSchema={
              validationSchema || ResourceComponent.VALIDATION_SCHEMA
            }
            waiting={waiting || mutating}
          >
            <ResourceComponent showPage resources={resources} />
          </ResourceItemPage>
        )
      }}
    </Mutation>
  )
}

ResourceShowPage.propTypes = {
  actionToolbar: PropTypes.oneOfType([
    PropTypes.oneOf([false]),
    PropTypes.func,
  ]),
  additionalEditButton: PropTypes.func,
  canCreate: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canUpdate: PropTypes.bool.isRequired,
  deleteItemFromCache: PropTypes.func.isRequired,
  deleteMutation: PropTypes.object.isRequired,
  editResourcePath: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  getResourceName: PropTypes.func.isRequired,
  handleActions: PropTypes.object,
  handleAdditionalEdit: PropTypes.func,
  id: PropTypes.number.isRequired,
  indexPageVariables: PropTypes.object,
  initialValues: PropTypes.func,
  loading: PropTypes.bool,
  newResourcePath: PropTypes.func.isRequired,
  pluralName: PropTypes.string.isRequired,
  resourceComponent: PropTypes.func.isRequired,
  resourcesPath: PropTypes.func.isRequired,
  resources: PropTypes.shape({
    item: PropTypes.object,
  }),
  router: PropTypes.shape({
    redirect: PropTypes.func.isRequired,
  }).isRequired,
  singleName: PropTypes.string.isRequired,
  validationSchema: PropTypes.object,
  waiting: PropTypes.bool,
}
export default withRedirect(ResourceShowPage)
