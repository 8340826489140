import React from "react"
import PropTypes from "prop-types"

import { Menu, Toolbar } from "../../ui"

function MenuItems({
  icon,
  intent,
  loading,
  items,
  path,
  showCaretDown,
  title,
}) {
  return (
    <Toolbar.MenuItem
      icon={icon}
      intent={intent}
      loading={loading}
      title={title}
      showCaretDown={showCaretDown}
    >
      {items.map(({ id, name, permalink, followedBySeparator }) => {
        return (
          <React.Fragment key={id}>
            <Menu.Item title={name} href={path(permalink)()} />
            {followedBySeparator && <Menu.Divider />}
          </React.Fragment>
        )
      })}
    </Toolbar.MenuItem>
  )
}
MenuItems.propTypes = {
  icon: PropTypes.string,
  intent: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      permalink: PropTypes.string.isRequired,
      followedBySeparator: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  loading: PropTypes.bool,
  path: PropTypes.func.isRequired,
  showCaretDown: PropTypes.bool,
  title: PropTypes.string.isRequired,
}
export default MenuItems
