import React from "react"

import FormGroupField from "../Form/FormGroupField"

import "./CardField.css"

function CardField({ fullWidth, ...rest }) {
  return <FormGroupField {...rest} inline={!fullWidth} fullWidthComponent />
}
export default CardField
