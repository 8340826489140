import React from "react"
import PropTypes from "prop-types"

import { values } from "../../utils"
import Alignment from "../Alignment"
import { DEFAULT_ACTIONS } from "../DataType"

class ActionsColumn extends React.Component {}
ActionsColumn.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  align: PropTypes.oneOf(values(Alignment)),
}
ActionsColumn.defaultProps = {
  type: DEFAULT_ACTIONS,
}
export default ActionsColumn
