import React from "react"
import PropTypes from "prop-types"

import { Alignment, ButtonGroup, Classes, Navbar } from "@blueprintjs/core"
import MenuItem from "./MenuItem"
import Buttons from "./Buttons"

import Breadcrumbs from "../Breadcrumbs"

const Toolbar = ({ dark, logo, navigation, title, children }) => (
  <Navbar className={dark ? Classes.DARK : null}>
    <Navbar.Group align={Alignment.LEFT}>
      {logo && (
        <Navbar.Heading>
          <img src={logo} alt="logo" />
        </Navbar.Heading>
      )}
      {title && <Navbar.Heading>{title}</Navbar.Heading>}
      {(logo || title) && navigation && <Navbar.Divider />}
      {navigation && <Breadcrumbs items={navigation} />}
    </Navbar.Group>
    {children}
  </Navbar>
)
Toolbar.Group = Navbar.Group
Toolbar.Divider = Navbar.Divider
Toolbar.Buttons = Buttons
Toolbar.Menu = ButtonGroup
Toolbar.MenuItem = MenuItem

Toolbar.defaultProps = {
  dark: false,
}
Toolbar.propTypes = {
  dark: PropTypes.bool,
  logo: PropTypes.string,
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),
  title: PropTypes.string,
  children: PropTypes.node,
}
export default Toolbar
