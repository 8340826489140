import React from "react"
import PropTypes from "prop-types"

import { BackButton, NewButton, Toolbar } from "../../../ui"

const IndexToolbarButtons = ({ canCreate, handleBack, handleNew, itemName }) =>
  ((handleBack || canCreate) && (
    <Toolbar.Buttons leftDivider>
      {handleBack && <BackButton onclick={handleBack} />}
      {canCreate && (
        <NewButton onClick={handleNew}>Create New {itemName}</NewButton>
      )}
    </Toolbar.Buttons>
  )) ||
  null
IndexToolbarButtons.propTypes = {
  canCreate: PropTypes.bool,
  handleBack: PropTypes.func,
  handleNew: PropTypes.func.isRequired,
  itemName: PropTypes.string,
}
export default IndexToolbarButtons
