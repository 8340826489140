import { cacheUpdate, gql } from "../../../state_management/DataProvider"

import { singleName } from "./metaInfo"

import { reject } from "../../../utils"

import { PRODUCT_LIST_QUERY } from "../product/dataSource"

export const fields =
  "id, code, name, remark, createdAt, createdBy, updatedAt, updatedBy"

const inputParams = "$code: String, $name: String, $remark: String"
const inputFields = "code: $code, name: $name, remark: $remark"

export const LANGUAGE_LIST_QUERY = gql`
  query Languages {
    allLanguagesList { ${fields} }
  }
`
export const LANGUAGE_ITEM_QUERY = gql`
  query Language($id: BigInt!) {
    languageById(id: $id) { ${fields} }
  }
`
export const CREATE_LANGUAGE_MUTATION = gql`
  mutation CreateLanguage(${inputParams}) {
    createLanguage(input: { ${inputFields} }) {
      language { ${fields} }
    }
  }
`
export const UPDATE_LANGUAGE_MUTATION = gql`
  mutation UpdateLanguage($id: BigInt!, ${inputParams}) {
    updateLanguage(input: { id: $id, ${inputFields} }) {
      language { ${fields} }
    }
  }
`
export const DELETE_LANGUAGE_MUTATION = gql`
  mutation DeleteLanguage($id: BigInt!) {
    deleteLanguage(input: { id: $id }) {
      language {
        id
        name
      }
    }
  }
`
export const getLanguageList = data => (data || {}).allLanguagesList || []
export const getLanguageItem = data => (data || {}).languageById

export const getCreatedLanguageItem = data => data.createLanguage.language
export const getDeletedLanguageId = data => data.deleteLanguage.language.id

export const insertItemIntoCache = cacheUpdate(
  LANGUAGE_LIST_QUERY,
  (cache, data) => ({
    allLanguagesList: getLanguageList(cache).concat([
      getCreatedLanguageItem(data),
    ]),
  }),
)
export const updateItemInCache = cacheUpdate(
  PRODUCT_LIST_QUERY,
  (cache, { updateLanguage: { language } }) => ({
    allProductsList: cache.allProductsList.map(
      product =>
        product.languageId === language.id
          ? { ...product, languageName: language.name }
          : product,
    ),
  }),
)
export const deleteItemFromCache = cacheUpdate(
  LANGUAGE_LIST_QUERY,
  (cache, data) => ({
    allLanguagesList: reject(getLanguageList(cache), {
      id: getDeletedLanguageId(data),
    }),
  }),
)

export const getResourceName = resource =>
  (resource && resource.name) || `New ${singleName}`

export const cacheRedirects = {
  languageById: (_, args, { getCacheKey }) =>
    getCacheKey({ __typename: "Language", id: args.id }),
}
