import React from "react"
import PropTypes from "prop-types"

import { Column } from "../../../../ui"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import {
  CreatedAtColumn,
  CreatedByColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  IdColumn,
  ResourceIndexPage,
} from "../../../resourcePage"

import { canCreate } from "../accessRights"
import { getContractorList, CONTRACTOR_LIST_QUERY } from "../dataSource"
import { contractorPath, newContractorPath } from "../paths"
import { pluralName, singleName } from "../metaInfo"

function ContractorIndexPage({ auth: { userRole } }) {
  return (
    <Query query={CONTRACTOR_LIST_QUERY}>
      {({ loading, errors, data }) => {
        return (
          <ResourceIndexPage
            canCreate={canCreate(userRole)}
            uniqueName="contractor"
            errors={errors}
            loading={loading}
            newResourcePath={newContractorPath}
            pluralName={pluralName}
            resources={{ items: getContractorList(data) }}
            resourcePath={contractorPath}
            singleName={singleName}
          >
            <Column field="name" width={300} />
            <Column field="phone" width={200} />
            <Column field="email" width={300} />
            <Column field="remark" width={500} />
            <UpdatedAtColumn />
            <UpdatedByColumn />
            <CreatedAtColumn />
            <CreatedByColumn />
            <IdColumn />
          </ResourceIndexPage>
        )
      }}
    </Query>
  )
}
ContractorIndexPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string,
  }),
}
export default withAuthenticationContext(ContractorIndexPage)
