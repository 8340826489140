import React from "react"
import PropTypes from "prop-types"

const { Provider, Consumer: ResourceConsumer } = React.createContext({
  resource: undefined,
})

function ResourceProvider({ children, resource }) {
  return <Provider value={{ resource }}>{children}</Provider>
}
ResourceProvider.propTypes = {
  children: PropTypes.node,
  resource: PropTypes.object,
}
export { ResourceProvider, ResourceConsumer }
