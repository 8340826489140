import { cacheRedirects as contractorCacheRedirects } from "./contractor/dataSource"
import { cacheRedirects as goodsStateCacheRedirects } from "./goodsState/dataSource"
import { cacheRedirects as languageCacheRedirects } from "./language/dataSource"
import { cacheRedirects as orderCacheRedirects } from "./order/dataSource"
import { cacheRedirects as productCacheRedirects } from "./product/dataSource"
import { cacheRedirects as productOwnerCacheRedirects } from "./productOwner/dataSource"
import { cacheRedirects as productTypeCacheRedirects } from "./productType/dataSource"
import { cacheRedirects as userCacheRedirects } from "./user/dataSource"

export default {
  ...contractorCacheRedirects,
  ...goodsStateCacheRedirects,
  ...languageCacheRedirects,
  ...orderCacheRedirects,
  ...productCacheRedirects,
  ...productOwnerCacheRedirects,
  ...productTypeCacheRedirects,
  ...userCacheRedirects,
}
