import {
  resourcePathFor,
  resourcesPathFor,
  newResourcePathFor,
  editResourcePathFor,
} from "../../resourcePage"

export const userPath = resourcePathFor("users")
export const usersPath = resourcesPathFor("users")
export const newUserPath = newResourcePathFor("users")
export const editUserPath = editResourcePathFor("users")
