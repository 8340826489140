import React from "react"

import { Consumer } from "../Authentication/Context"
import isUnauthorized from "./isUnathorized"

const ErrorHandler = Component => ({ children, onError, ...rest }) => (
  <Consumer>
    {({ refresh }) => {
      function errorHandler(error) {
        if (isUnauthorized(error) && refresh) {
          refresh()
        }
        onError && onError(error)
      }

      return (
        <Component onError={errorHandler} {...rest}>
          {children}
        </Component>
      )
    }}
  </Consumer>
)

export default ErrorHandler
