function parseDate(str) {
  if (str === "") return null

  const d = str.match(/^(\d+).(\d+).(\d+)$/)
  if (d) return new Date(+d[3], +d[2] + 1, +d[1])
  return false
}

function parseDateISO(str) {
  if (!str) return null

  const d = str.match(/^(\d+)-(\d+)-(\d+)$/)
  if (d) return new Date(+d[1], +d[2] + 1, +d[3])
  return false
}

export { parseDate, parseDateISO }
