import React from "react"
import PropTypes from "prop-types"

import DeleteConfirmationDialog from "./DeleteConfirmationDialog"

class DeleteConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showDeleteConfirmation: false }

    this.deleteClicked = this.deleteClicked.bind(this)
    this.cancelConfirmationClicked = this.cancelConfirmationClicked.bind(this)
    this.confirmDeleteClicked = this.confirmDeleteClicked.bind(this)
  }

  showDeleteConfirmation(value) {
    this.setState({ showDeleteConfirmation: value })
  }

  deleteClicked() {
    this.showDeleteConfirmation(true)
  }
  cancelConfirmationClicked() {
    this.showDeleteConfirmation(false)
  }
  confirmDeleteClicked() {
    this.showDeleteConfirmation(false)
    this.props.handleDelete()
  }

  render() {
    const { children, item } = this.props
    return (
      <React.Fragment>
        {children({ handleDelete: this.deleteClicked })}
        <DeleteConfirmationDialog
          item={item}
          show={this.state.showDeleteConfirmation}
          onCancel={this.cancelConfirmationClicked}
          onConfirm={this.confirmDeleteClicked}
        />
      </React.Fragment>
    )
  }
}
DeleteConfirmation.propTypes = {
  children: PropTypes.func.isRequired,
  item: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
}
export default DeleteConfirmation
