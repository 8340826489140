// import React from "react"
import PropTypes from "prop-types"

function TextCell({ value }) {
  return value || null
}
TextCell.propTypes = {
  value: PropTypes.string,
}
export default TextCell
