import React from "react"

import { MenuItem } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import highlightText from "./highlightText"

function createItemRenderer({
  name,
  keyField = "id",
  mainField = "name",
  additionalField,
  isSelected,
  selectedIcon,
}) {
  function ItemRenderer(
    item,
    { handleClick, modifiers: { active, matchesPredicate }, query },
  ) {
    if (!matchesPredicate) return null
    return (
      <MenuItem
        active={active}
        icon={
          isSelected &&
          (isSelected(item) ? selectedIcon || IconNames.TICK : IconNames.BLANK)
        }
        key={item[keyField]}
        label={item[additionalField]}
        onClick={handleClick}
        text={highlightText(item[mainField], query)}
      />
    )
  }
  ItemRenderer.displayName = `${name}ItemRenderer`
  ItemRenderer.filter = (query, item) => {
    const lowerQuery = query.toLowerCase()

    return (
      ~item[mainField].toLowerCase().indexOf(lowerQuery) ||
      (additionalField &&
        ~item[additionalField].toLowerCase().indexOf(lowerQuery))
    )
  }
  return ItemRenderer
}

export default createItemRenderer
