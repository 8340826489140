import React from "react"
import PropTypes from "prop-types"

import { compose } from "../../../../utils"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Queries } from "../../../../state_management/DataProvider"

import { ResourceEditPage, withResourceId } from "../../../resourcePage"

import {
  PRODUCT_OWNER_LIST_QUERY,
  getProductOwnerList,
} from "../../productOwner/dataSource"
import {
  PRODUCT_TYPE_LIST_QUERY,
  getProductTypeList,
} from "../../productType/dataSource"
import { LANGUAGE_LIST_QUERY, getLanguageList } from "../../language/dataSource"
import {
  getProductItem,
  getResourceName,
  PRODUCT_ITEM_QUERY,
  UPDATE_PRODUCT_MUTATION,
} from "../dataSource"
import { canUpdate } from "../accessRights"
import { pluralName, singleName } from "../metaInfo"
import { productPath, productsPath } from "../paths"
import Product from "./Product"

function ProductEditPage({ auth: { userRole }, id }) {
  // prettier-ignore
  const queries = [
    { resourceName: "item", query: PRODUCT_ITEM_QUERY, getResource: getProductItem, variables: { id } },
    { resourceName: "productOwners", query: PRODUCT_OWNER_LIST_QUERY, getResource: getProductOwnerList },
    { resourceName: "productTypes", query: PRODUCT_TYPE_LIST_QUERY, getResource: getProductTypeList },
    { resourceName: "languages", query: LANGUAGE_LIST_QUERY, getResource: getLanguageList },
  ]

  return (
    <Queries queries={queries} skip={!canUpdate(userRole)}>
      {({
        loading,
        errors,
        resources: { item, languages, productOwners, productTypes },
      }) => {
        if (!canUpdate(userRole)) {
          errors = ["Access denied."]
        } else if (!loading && !errors && !item) {
          errors = ["Not found."]
        }

        return (
          <ResourceEditPage
            errors={errors}
            getResourceName={getResourceName}
            id={id}
            loading={loading}
            pluralName={pluralName}
            resourceComponent={Product}
            resources={{ item, languages, productOwners, productTypes }}
            resourcePath={productPath}
            resourcesPath={productsPath}
            singleName={singleName}
            updateMutation={UPDATE_PRODUCT_MUTATION}
            validationSchema={Product.validationSchema(productTypes)}
          />
        )
      }}
    </Queries>
  )
}
ProductEditPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
}
export default compose(
  withResourceId,
  withAuthenticationContext,
)(ProductEditPage)
