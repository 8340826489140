import React from "react"
import PropTypes from "prop-types"

import { SelectInput } from "../../inputs"

import "./SelectCell.css"

function SelectCell({
  editing,
  form,
  itemKey,
  itemRenderer,
  items,
  itemValue,
  keyName,
  name,
  onBlur,
  onChange,
  textValue,
  value,
  valid,
  waiting,
}) {
  if (editing) {
    return (
      <SelectInput
        disabled={waiting}
        editing={true}
        form={form}
        itemKey={itemKey}
        itemRenderer={itemRenderer}
        items={items}
        itemValue={itemValue}
        keyName={keyName}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        valid={valid}
        width={100}
      />
    )
  } else {
    return textValue || null
  }
}
SelectCell.propTypes = {
  itemRenderer: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
  ),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  waiting: PropTypes.bool,
}
export default SelectCell
