import React from "react"
import PropTypes from "prop-types"

import { compose, find, formatNumber } from "../../../../utils"
import { Column, DataType, IconNames, Intent, Toolbar } from "../../../../ui"

import { withAuthenticationContext } from "../../../../state_management/Authentication"
import { Query } from "../../../../state_management/DataProvider"

import { withMetaData } from "../../../metaData"

import {
  CreatedAtColumn,
  CreatedByColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  IdColumn,
  ResourceIndexPage,
} from "../../../resourcePage"

import withPermalink from "../../../routing/withPermalink"

import { canCreate } from "../../order/accessRights"

import { STOCK_MOVEMENT_LIST_QUERY, getStockMovementList } from "../dataSource"
import { newOrderPath, orderPath } from "../../order/paths"
import { pluralName } from "../metaInfo"

import { MenuItems } from "../../../menu"
import stockOrderMenuItems from "../../stock/stockOrderMenuItems"

import "./StockMovementIndexPage.css"

function movingQuantityCellClass({ colDef, value }) {
  return value < 0
    ? "ag-numeric-cell negative-quantity"
    : value > 0
    ? "ag-numeric-cell positive-quantity"
    : null
}

function movingQuantityFormatter({ value }) {
  return `${value > 0 ? "+" : ""}${formatNumber(value)}`
}

function StockMovementIndexPage({
  auth: { userRole },
  metaData: { orderTypes, stocks },
  permalink,
}) {
  const stock = find(stocks, { permalink })
  const { id: stockId, goodsState, location, contractor, name } = stock || {}

  return (
    <Query
      query={STOCK_MOVEMENT_LIST_QUERY}
      variables={{ stockId }}
      skip={!stock}
      fetchPolicy="no-cache"
    >
      {({ loading, errors, data }) => {
        function movingQuantityGetter({
          data: {
            sourceStockId,
            targetStockId,
            goodsStateId,
            locationId,
            sourceGoodsStateId,
            sourceLocationId,
            quantity,
          },
        }) {
          return sourceStockId === stockId &&
            (targetStockId !== stockId ||
              (sourceGoodsStateId === goodsStateId &&
                sourceLocationId === locationId))
            ? -+quantity
            : +quantity
        }

        return (
          <ResourceIndexPage
            canCreate={false}
            errors={stock ? errors : ["Not Found"]}
            loading={loading}
            pluralName={pluralName(name)}
            resources={{ items: getStockMovementList(data) }}
            resourcePath={orderPath}
            resourceIdField="orderId"
            toolbar={
              (canCreate(userRole) && (
                <Toolbar.Buttons leftDivider>
                  <MenuItems
                    icon={IconNames.PLUS}
                    intent={Intent.PRIMARY}
                    items={stockOrderMenuItems({ stockId, orderTypes })}
                    path={newOrderPath}
                    showCaretDown
                    title="Create New Order"
                  />
                </Toolbar.Buttons>
              )) ||
              undefined
            }
            uniqueName={`stock-movements-${permalink}`}
          >
            <Column field="date" width={90} type={DataType.DATE} />
            <Column
              field="productTypeName"
              title="Product Type"
              width={130}
              type={DataType.DROP_DOWN}
            />
            <Column field="productCode" title="Product Number" width={150} />
            <Column field="productName" title="Product" width={300} />
            <Column
              field="languageName"
              title="Language"
              width={150}
              type={DataType.DROP_DOWN}
            />
            {location && (
              <Column
                field="locationName"
                title="Location"
                width={130}
                type={DataType.DROP_DOWN}
              />
            )}
            {goodsState && (
              <Column
                field="goodsStateName"
                title="Goods State"
                width={130}
                type={DataType.DROP_DOWN}
              />
            )}
            {contractor && (
              <Column
                field="contractorName"
                title={contractor}
                width={300}
                type={DataType.DROP_DOWN}
              />
            )}
            <Column
              field="quantity"
              width={120}
              type={DataType.INTEGER}
              cellClass={movingQuantityCellClass}
              valueGetter={movingQuantityGetter}
              valueFormatter={movingQuantityFormatter}
            />
            <Column
              field="remainQuantity"
              title="Stock"
              width={120}
              type={DataType.INTEGER}
            />
            <Column field="action" width={300} />
            <Column field="reason" width={500} />
            <Column field="orderName" title="Order No." width={150} />
            <Column field="remark" width={500} />
            <UpdatedAtColumn />
            <UpdatedByColumn />
            <CreatedAtColumn />
            <CreatedByColumn />
            <IdColumn />
          </ResourceIndexPage>
        )
      }}
    </Query>
  )
}
StockMovementIndexPage.propTypes = {
  auth: PropTypes.shape({
    userRole: PropTypes.string,
  }),
  metaData: PropTypes.shape({
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        reason: PropTypes.bool,
      }),
    ),
    stocks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
  permalink: PropTypes.string.isRequired,
}
export default compose(
  withAuthenticationContext,
  withPermalink,
  withMetaData,
)(StockMovementIndexPage)
