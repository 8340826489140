import React from "react"
import PropTypes from "prop-types"

import {
  CancelButton,
  DeleteButton,
  EditButton,
  SaveButton,
} from "../../Buttons"

import "./DefaultActionsCell.css"

function DefaultActionsCell({
  disabled,
  editing,
  onCancel,
  onDelete,
  onEdit,
  onSave,
  waiting,
}) {
  return (
    <React.Fragment>
      {editing && (
        <SaveButton
          disabled={disabled}
          loading={waiting}
          minimal
          onlyIcon
          onClick={onSave}
        />
      )}
      {editing &&
        !waiting && (
          <CancelButton
            disabled={disabled}
            minimal
            onlyIcon
            onClick={onCancel}
          />
        )}
      {!editing && (
        <EditButton disabled={disabled} minimal onlyIcon onClick={onEdit} />
      )}
      {!editing && (
        <DeleteButton disabled={disabled} minimal onlyIcon onClick={onDelete} />
      )}
    </React.Fragment>
  )
}
DefaultActionsCell.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  waiting: PropTypes.bool,
}
export default DefaultActionsCell
