import React from "react"
import PropTypes from "prop-types"

import { formatNumber } from "../../../utils"
import { NumericInput, Intent } from "@blueprintjs/core"

import "./IntegerCell.css"

function IntegerCell({
  editing,
  intent,
  minValue,
  name,
  onBlur,
  onChange,
  value,
  waiting,
}) {
  function onValueChange(_valueAsNumber, valueAsString) {
    onChange({ target: { name, value: valueAsString } })
  }

  if (editing) {
    return (
      <NumericInput
        clampValueOnBlur
        disabled={waiting}
        fill
        intent={intent}
        min={minValue}
        name={name}
        onBlur={onBlur}
        onValueChange={onValueChange}
        value={value}
      />
    )
  } else {
    const style = intent === Intent.DANGER ? { color: "red" } : undefined
    return <span style={style}>{formatNumber(value)}</span> || null
  }
}
IntegerCell.propTypes = {
  editing: PropTypes.bool,
  minValue: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  waiting: PropTypes.bool,
}
export default IntegerCell
