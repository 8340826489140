import React from "react"

import { Consumer } from "./Context"

function withFlash(WrappedComponent) {
  function WithFlash({ children, flash, ...rest }) {
    return (
      <Consumer>
        {({ flash }) => (
          <WrappedComponent flash={flash} {...rest}>
            {children}
          </WrappedComponent>
        )}
      </Consumer>
    )
  }
  WithFlash.displayName = `WithFlash(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`
  return WithFlash
}
export default withFlash
